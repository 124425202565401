import PropTypes from 'prop-types';
import TitleCard from './TitleCard';
import React from 'react';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _get from 'lodash/get';

/**
 * 全国のヘッドライン__各社TOP（各社UI）
 */
class CompanyHomeAttentionContent extends React.Component {
  static contextTypes = {
    getNtvHeadlineNews: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      dispose: null,
      loading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
  }

  render() {
    if (_isEmpty(this.props.getNtvHeadlineNews)) return null;
    const items = this.props.getNtvHeadlineNews;
    const cards = _compact(_map(items, (itemData, index) => {
      if (!itemData || !itemData.source_systems) return;
      return (
        <TitleCard
          key={`ntv_headline_card_${index}`}
          itemData={itemData}
          default={true}
          isNtvHeadline={true}
        />
      )
    }));

    return (
      <div className="featureArticles">
        <div className="featureArticles-title">全国のヘッドライン</div>
        <div className="featureArticles-content">
          <div className="news-list-items grid">{cards}</div>
        </div>
      </div>
    );
  }
}
const root = withLayoutContext(CompanyHomeAttentionContent);
export default root;
