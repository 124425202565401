import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import HeadlineContent from './HeadlineContent';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import { getCardResizeImage } from '../../../../common/preloadImages';
import TaboolaHomeContent from './TaboolaHomeContent';

const PERPAGE = 10;

class HomeContent extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    companyLinks: PropTypes.array,
    topText: PropTypes.string,
  };

  static getRootPath = function (models, options, props) {
    const companyCode = _.get(props, 'companyCode', 'ntv');
    const providerList = _.get(models, 'config.data.providerList', []);
    const isListJsonPage = _.includes(providerList, companyCode);
    return isListJsonPage ? ['article', 'local', companyCode] : ['article', 'headline', companyCode, 'trenta'];
  };

  static getPaths = function (models, options, props) {
    let paths = [];

    const indexes = function (models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum
            : options && options.numTitles
            ? options.numTitles + (props.fromNum || 0)
            : PERPAGE - 1,
      };
    };

    const rootPath = this.getRootPath(models, options, props);

    paths = paths.concat([
      [
        indexes(models, options, props),
        ['id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
      ],
    ]);

    return _.map(paths, (path) => rootPath.concat(path));
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  constructor(props, context) {
    super(props, context);

    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.items = this.model.getSync(rootPath);
    this.state = {
      dispose: null,
      fetchDataError: null,
    };
  }

  componentDidMount() {
    if (this.context.routeHandler.eachTrenta) {
      this.companyLink = _.get(this.context, 'companyLinks.0');
    }
    this._isMounted = true;
    if (!this.items) {
      this.fetchData(this.model, this.props);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
  }

  fetchData(model, props) {
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      this.state.dispose();
    }
    this.state[JSON.stringify(paths)] = paths;
    const evaluator = model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then((res) => {
        const responseItems = _.get(res, ['json'].concat(rootPath));
        if (!_.isArray(responseItems)) {
          this.items = _.compact(
            _.map(responseItems, (item, i) => {
              return item;
            }),
          );
        } else {
          this.item = responseItems;
        }

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch((e) => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    const isListJsonPage = _.get(this.context.routeHandler, 'listJson', false);
    let items = this.items;
    if (items) {
      if (items && _.size(items)) {
        const displayCnt = this.context.spMode ? PERPAGE - 2 : PERPAGE;
        items = _.slice(_.values(items), 0, displayCnt);

        // preload imageの設定
        const artcileImages = _.compact(
          _.map(
            _.filter(items, e => e && e.source_systems),
            (itemData, i) => getCardResizeImage({ itemData, maincard: i === 0 }, this.context),
          ),
        );
        if (!_.isEmpty(artcileImages)) this.props.setPreloadImages(artcileImages);
      }
    }
    const bloadcaster = getBroadcasterData(_.get(this.context.routeHandler.params, 'companyCode', 'n24'));
    const img_src = bloadcaster.img_src.e_m;
    const domain = _.get(this.context, 'models.hosts.data.domain_url');
    const pageUrl = _.get(this.context, 'models.hosts.data.domain_url') + _.get(this.props, 'routeHandler.url');

    const canonical = `/`;

    let title = `${bloadcaster.label}の新着記事一覧へ`;

    const companyCode = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const topText = _.get(this.context, 'topText');
    const titleTag = companyCode === 'n24'
      ? topText
      : `${bloadcaster.label}のニュース・${bloadcaster.area}のニュース｜日テレNEWS NNN`;
    const description = (companyCode === 'n24' || isListJsonPage)
      ? '日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。'
      : `日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」の${bloadcaster.label}のページです。「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。`;

    return (
      <React.Fragment key="home-content">
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(titleTag) });
            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });
            metas.push({ property: 'og:type', content: 'website' });

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={titleTag} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        <div className="headlineModule">
          {this.context.routeHandler.eachTrenta && (
            <div className="headlineModule-header">
              <MainViewLink href={_.get(this.companyLink, 'link_url', '')} params={{ companyCode: bloadcaster.title }}>
                <span className="headlineModule-header-left">
                  <span className="headlineModule-header-img">
                    <img src={img_src} className="card-logo" alt={bloadcaster.label} />
                  </span>
                  <h2 className="headlineModule-header-title">{bloadcaster.label}</h2>
                </span>
              </MainViewLink>
            </div>
          )}
          <HeadlineContent items={items} />
          {/* Taboola */}
          <TaboolaHomeContent
            isSpMode={this.context.spMode}
            uiView={_.get(this.context, 'routeHandler.uiView')}
          />
          {this.context.routeHandler.eachTrenta && !_.get(this.props.routeHandler, 'listJson') && (
            <div className="headlineModule-readmore">
              <MainViewLink to={routes.newEachTrenta} params={{ companyCode: bloadcaster.title, categoryCode: 'all' }}>
                {title}
                <i className="fa-article-title-arrow" />
              </MainViewLink>
            </div>
          )}
        </div>
        <Analytics pageTitle={'トップページ'} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(HomeContent);
root.getPaths = HomeContent.getPaths;
root.getRootPath = HomeContent.getRootPath;
root.getPrefetchPaths = HomeContent.getPrefetchPaths;
export default root;
