import classnames from 'classnames';
import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BackgroundImage from '../../../common/components/BackgroundImage';
import MainViewLink from '../../../common/components/MainViewLink';
import { withModalContext } from '../../../common/context/ModalContext';
import routes from '../../../common/routes';
import lineClamp from '../../../../common/lineClamp';
import nl2br from '../../../../common/react-nl2br';
import { getGenres2Code, getArticle2ProviderCode } from '../../../../common/getGenreCategories';
import getImage from '../../../../common/getImage';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import { GetArticleLinkId } from '../../../../common/getSourceProjectId';
import getDate from '../../../../common/getDate';

class TitleCard extends Component {
  static propTypes = {
    size: PropTypes.oneOf(['1x1', '2x1', '1x2', '2x2', '3x2']),
    // sliderItemId: PropTypes.string,
    getRowHasPopOpen: PropTypes.func,
    isDoubleWide: PropTypes.bool,
    isTallPanel: PropTypes.bool,
    itemTabbable: PropTypes.bool,
    model: PropTypes.object,
    rowModel: PropTypes.object,
    rankNum: PropTypes.number,
    rowNum: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    popType: PropTypes.string,
    popClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    showScore: PropTypes.bool,
    titleDisplaySetting: PropTypes.oneOf(['default', 'under_card', 'asset_only', 'on_card', 'none']),
    showPopCardMylistButton: PropTypes.bool,
    noOnCard: PropTypes.bool,
    playListName: PropTypes.string,
    numb: PropTypes.number,
    playListNum: PropTypes.number,
    playListNumb: PropTypes.number,
    maincard: PropTypes.bool,
    introduction: PropTypes.bool,
    indexNumber: PropTypes.number,
    category: PropTypes.bool,
    ranking: PropTypes.bool,
    special: PropTypes.bool,
    local: PropTypes.bool,
    normal: PropTypes.bool,
    rankingPage: PropTypes.bool,
    key: PropTypes.string,
    contentType: PropTypes.string,
    rankingList: PropTypes.bool,
    sideContent: PropTypes.bool,
    company: PropTypes.bool,
    suggestArticles: PropTypes.bool,
    companyRanking: PropTypes.bool,
    companySpecial: PropTypes.bool,
    companyHomeEachCompany: PropTypes.bool,
    companyHomePc: PropTypes.bool,
    stv: PropTypes.bool,
    search: PropTypes.bool,
    newContent: PropTypes.bool,
    specialDetail: PropTypes.bool,
    homeAttention: PropTypes.bool,
    featureArticles: PropTypes.bool,
    isNtvHeadline: PropTypes.bool,
  };

  static defaultProps = {
    size: '1x1',
    ellipsized: true,
    rankNum: 0,
    rowNum: 0,
    popType: 'none',
    showScore: false,
    titleDisplaySetting: 'default',
    showPopCardMylistButton: true,
    noOnCard: false,
    sliderRefocus: true,
    suggestArticles: false,
    homeAttention: false,
  };

  static contextTypes = {
    columnsInRow: PropTypes.number,
    titleType: PropTypes.string,
    artKind: PropTypes.string,
    currentPage: PropTypes.string,
    getModelData: PropTypes.func,
    history: PropTypes.object,
    isInitialRender: PropTypes.bool,
    isOverlayPage: PropTypes.bool,
    isRtl: PropTypes.bool,
    isTallRow: PropTypes.bool,
    titleRowEpisodeTrackId: PropTypes.number,
    titleRowTrackId: PropTypes.number,
    titleRowTrailerTrackId: PropTypes.number,
    listContext: PropTypes.string,
    listType: PropTypes.string,
    loggingApp: PropTypes.object,
    profileManager: PropTypes.string,
    requestId: PropTypes.string,
    routeHandler: PropTypes.object,
    rowNum: PropTypes.number,
    trackId: PropTypes.number,
    keyPrefix: PropTypes.string, // from GalleryContent
    service: PropTypes.string,
    playList: PropTypes.object,
    cookies: PropTypes.object,
    isStore: PropTypes.bool,
    gtmApp: PropTypes.object,
    models: PropTypes.object,
    spMode: PropTypes.bool,
  };

  static childContextTypes = {
    backgroundImageSize: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      // generation: props.model.getVersion(rootPath),
      displayTitle: true,
      deleteMode: context.deleteApp ? context.deleteApp.isDeleteMode() : false,
      isDelete: false,
    };
    this.titleCardRef = React.createRef();
    this.popCardRef = React.createRef();
    this._isMounted = false;
    this.catchLayoutRef = React.createRef();
    this.getBeforeDiffTime = this.getBeforeDiffTime.bind(this);
    this.getCanonicalArticleId = this.getCanonicalArticleId.bind(this);
    this.getListJsonArticleId = this.getListJsonArticleId.bind(this);
    this.getPublishDate = this.getPublishDate.bind(this);
    this.providerPrefix = _.get(context, 'models.config.data.providerPrefix', {});
  }

  getChildContext() {
    let size;
    if (this.props.maincard || this.props.specialMain) {
      size = _.get(this.context, 'spMode') ? '400x200' : '700x400';
    } else {
      size = _.get(this.context, 'spMode') ? '200x100' : '350x150';
    }
    // 誘導モジュール：PC/SP共に200指定
    if (_.get(this.props, 'introduction')) size = '200x200';

    return {
      backgroundImageSize: size,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getBeforeDiffTime(date) {
    date = date && date.replace(/\./g, '-');
    const now = dayjs(new Date(), 'YYYY-MM-DD');
    const year = now.get('year');
    const today = dayjs(now).format('YYYY-MM-DD');
    const yesterday = dayjs(now).subtract(1, 'day').format('YYYY-MM-DD');
    const dateYear = dayjs(date).get('year');
    const dateDate = dayjs(date).format('YYYY-MM-DD');
    const yearsRemaining = now.diff(dayjs(date, 'YYYY-MM-DD'), 'year');

    if (dateDate === today) {
      return dayjs(date).format('H:mm');
    } else if (dateDate === yesterday) {
      return dayjs(date).format('M月D日 H:mm');
    } else if (yearsRemaining >= 1 || year !== dateYear) {
      return dayjs(date).format('YYYY年M月D日');
    }
    return dayjs(date).format('M月D日');
  }

  /**
   * リンクparamsに記事IDをカノニカルに対応させるIDを返す
   */
  getCanonicalArticleId(itemData = {}, isSuggestArticles = false) {
    const companyCode = _.get(this.context, 'routeHandler.params.companyCode');
    let to, params;
    const isEachCompany = _.get(this.context, 'routeHandler.isEachCompany', false);
    const providerCode = getArticle2ProviderCode(itemData);
    const categoryTags = getGenres2Code(
      _.get(itemData, 'genres'),
      _.get(this.context, 'models.config.data'),
      providerCode,
    );
    const id = GetArticleLinkId(itemData, this.providerPrefix);

    // ジャンル（カテゴリ）なし時
    // 記事のTitleCardであることを判定するためにsource_systems.idを加える
    if (!categoryTags && _.get(itemData, 'source_systems.id', '')) {
      // カテゴリーがない記事はサポートしない. 表示から除外する
      return {};
    }

    // 「一緒に見られているニュース」のルーティング処理
    if (isSuggestArticles) {
      // 一律で記事詳細（TRENTA UI）に遷移
      return {
        to: routes.articleDetail,
        params: { id, categoryCode: categoryTags },
      };
    }

    // 通常記事のルーティング処理
    if (isEachCompany) {
      to = routes.articleDetailEachCompany;
      params = { companyCode, id, categoryCode: categoryTags };
    } else {
      to = routes.articleDetail;
      params = { id, categoryCode: categoryTags };
    }

    return {
      to,
      params,
    };
  }

  /**
   * list.json版リンクparamsに記事IDを返す
   */
  getListJsonArticleId(itemData = {}) {
    let id,
      to,
      params = {};
    id = _.get(itemData, 'id');
    to = routes.articleDetailJson;
    params = { id };
    return {
      to,
      params,
    };
  }

  getPublishDate(itemData) {
    let $date = _.get(itemData, 'first_publish_date') || _.get(itemData, 'created_at');
    if (
      !_.isEmpty(_.get(itemData, 'original_first_publish_date')) &&
      new Date(itemData.original_first_publish_date).getTime() !== 0
    ) {
      $date = itemData.original_first_publish_date;
    }
    if (!_.isEmpty(_.get(itemData, 'publish_start_date')) && new Date(itemData.publish_start_date).getTime() !== 0) {
      $date = itemData.publish_start_date;
    }
    if (_.isEmpty($date) && !_.isEmpty(_.get(itemData, 'pubDate'))) {
      $date = itemData.pubDate;
    }

    const date = !_.isEmpty($date) ? this.getBeforeDiffTime($date) : null;
    return date;
  }

  // wip:見づらいので整理する。。。
  render() {
    // 記事の場合カテゴリーがない記事は表示対象から除外させる
    if (_.get(this.props, 'source_systems.id', '') && _.isEmpty(_.get(this.props, 'itemData.genres', []))) {
      console.error("カテゴリーが存在しません。");
      return null;
    }

    const browserInfo = this.context.getModelData('browserInfo');
    const alt = _.get(this.props, 'itemData.title');

    let viewPlayerBtn = false;
    if (_.get(this.props, 'itemData.media') && _.get(this.props, 'itemData.media.media_id')) {
      const publishStartAt = _.get(this.props, 'itemData.media.publish_start_at', '1970-01-01T00:00:00+00:00');
      const publishEndAt = _.get(this.props, 'itemData.media.publish_end_at', '2999-12-31T23:59:59+00:00');
      if (publishStartAt <= now && publishEndAt >= now) {
        viewPlayerBtn = true;
      }
    }
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault('Asia/Tokyo');

    const now = dayjs().tz().format();
    let card = null;
    let to, params, url;
    let logo_url;
    if (_.get(this.props.itemData, 'source_systems.project')) {
      logo_url = getBroadcasterData(_.get(this.props.itemData.source_systems, 'project')).img_src.l;
    } else if (_.get(this.props.itemData, 'provider_id')) {
      logo_url = getBroadcasterData(_.get(this.props.itemData, 'provider_id')).img_src.l;
    } else {
      logo_url = getBroadcasterData(_.get(this.props.itemData, 'source_systems.0.project')).img_src.l;
    }
    if (
      this.props.maincard ||
      this.props.category ||
      this.props.ranking ||
      this.props.lineLayout ||
      this.props.default ||
      this.props.scroll
    ) {
      if (_.get(this.props, 'itemData.title') === '地方') {
        to = routes.localDetail;
        params = { id: _.get(this.props, 'itemData.article.id') };
      } else {
        const id =
          _.get(this.props, 'itemData.source_systems.id') || _.get(this.props, 'itemData.article.source_systems.id');
        const genres = _.get(this.props, 'itemData.genres') || _.get(this.props, 'itemData.article.genres');
        const categoryCode = getGenres2Code(genres, _.get(this.context, 'models.config.data'));

        if (categoryCode) {
          to = routes.articleDetail;
          params = { categoryCode, id };
        } else {
          to = routes.articles;
          params = { id };
        }
      }

      const homeSideRanking = this.props.rankingList && this.props.sideContent;

      // カテゴリートップで使用されている(ヘッドラインモジュールに置き換えられそう。。。)
      if (this.props.maincard) {
        const duration = _.get(this.props, 'itemData.media.duration', false);
        let viewPlayerBtn = false;
        if (_.get(this.props, 'itemData.media') && _.get(this.props, 'itemData.media.media_id')) {
          const publishStartAt = _.get(this.props, 'itemData.media.publish_start_at', '1970-01-01T00:00:00+00:00');
          const publishEndAt = _.get(this.props, 'itemData.media.publish_end_at', '2999-12-31T23:59:59+00:00');
          if (publishStartAt <= now && publishEndAt >= now) {
            viewPlayerBtn = true;
          }
        }
        card = (
          <NewsListItem
            to={to}
            params={params}
            url={getImage(this.props, this.context)}
            alt={alt}
            viewPlayerBtn={viewPlayerBtn}
            browserInfo={browserInfo}
            title={_.get(this.props, 'itemData.title')}
            duration={duration}
            logoUrl={logo_url}
            publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
            cardType={'feature'}
            sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
          />
        );
      }

      // ランキング要素
      if (this.props.ranking) {
        const imageUrl = getImage(this.props, this.context);

        // canonicalArticleId
        const itemData = _.get(this.props, 'itemData');
        const linkParams = !_.get(this.props, 'local')
          ? this.getCanonicalArticleId(itemData)
          : this.getListJsonArticleId(itemData);

        if (_.isEmpty(linkParams)) {
          return null;
        }
        to = linkParams.to;
        params = linkParams.params;

        let viewPlayerBtn = false;
        if (_.get(this.props, 'itemData.media') && _.get(this.props, 'itemData.media.media_id')) {
          const publishStartAt = _.get(this.props, 'itemData.media.publish_start_at', '1970-01-01T00:00:00+00:00');
          const publishEndAt = _.get(this.props, 'itemData.media.publish_end_at', '2999-12-31T23:59:59+00:00');
          if (publishStartAt <= now && publishEndAt >= now) {
            viewPlayerBtn = true;
          }
        }
        const duration = _.get(this.props, 'itemData.media.duration', false);
        card = (
          <React.Fragment>
            {this.props.rankingPage && (
              <React.Fragment>
                <RankingNews
                  to={to}
                  params={params}
                  url={getImage(this.props, this.context)}
                  alt={alt}
                  viewPlayerBtn={viewPlayerBtn}
                  browserInfo={browserInfo}
                  title={_.get(this.props, 'itemData.title')}
                  duration={duration}
                  logoUrl={logo_url}
                  publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
                  rankingNumber={_.get(this.props, 'rankingNumber')}
                  cardType={'main'}
                  isEachCompany={_.get(this.context.routeHandler, 'isEachCompany')}
                />
              </React.Fragment>
            )}
            {homeSideRanking && (
              <React.Fragment>
                <RankingNews
                  to={to}
                  params={params}
                  url={getImage(this.props, this.context)}
                  alt={alt}
                  viewPlayerBtn={viewPlayerBtn}
                  browserInfo={browserInfo}
                  title={_.get(this.props, 'itemData.title')}
                  duration={duration}
                  logoUrl={logo_url}
                  publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
                  rankingNumber={_.get(this.props, 'rankingNumber')}
                  cardType={'sidebar'}
                  isEachCompany={_.get(this.context.routeHandler, 'isEachCompany')}
                />
              </React.Fragment>
            )}
            {/* SP版記事詳細画面ランキング */}
            {!homeSideRanking && !this.props.rankingPage && (
              <React.Fragment>
                <RankingNews
                  to={to}
                  params={params}
                  url={getImage(this.props, this.context)}
                  alt={alt}
                  viewPlayerBtn={viewPlayerBtn}
                  browserInfo={browserInfo}
                  title={_.get(this.props, 'itemData.title')}
                  logoUrl={logo_url}
                  duration={duration}
                  publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
                  rankingNumber={_.get(this.props, 'rankingNumber')}
                  cardType={'vertical'}
                  isEachCompany={_.get(this.context.routeHandler, 'isEachCompany')}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      }

      // 最新記事等横長になるTitleCard。一覧系
      if (this.props.lineLayout) {
        const imageUrl = getImage(this.props, this.context);

        // canonicalArticleId
        const linkParams = this.getCanonicalArticleId(_.get(this.props, 'itemData'));
        if (_.isEmpty(linkParams)) {
          return null;
        }
        to = linkParams.to;
        params = linkParams.params;

        let viewPlayerBtn = false;
        if (_.get(this.props, 'itemData.media') && _.get(this.props, 'itemData.media.media_id')) {
          const publishStartAt = _.get(this.props, 'itemData.media.publish_start_at', '1970-01-01T00:00:00+00:00');
          const publishEndAt = _.get(this.props, 'itemData.media.publish_end_at', '2999-12-31T23:59:59+00:00');
          if (publishStartAt <= now && publishEndAt >= now) {
            viewPlayerBtn = true;
          }
        }
        const duration = _.get(this.props, 'itemData.media.duration', false);
        card = (
          <React.Fragment>
            <NewsListItem
              to={to}
              params={params}
              url={getImage(this.props, this.context)}
              alt={alt}
              viewPlayerBtn={viewPlayerBtn}
              browserInfo={browserInfo}
              title={_.get(this.props, 'itemData.title')}
              duration={duration}
              logoUrl={logo_url}
              cardType="large"
              publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
              isEachCompany={this.context.routeHandler.isEachCompany}
              sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
            />
          </React.Fragment>
        );
      }

      /**
       * どれにも属さないデフォルト
       *  - 注目の記事
       *  - 特集記事一覧
       *  - 全国のヘッドライン
       */
      if (this.props.default) {
        const imageUrl = getImage(this.props, this.context);

        // canonicalArticleId
        const itemData = _.get(this.props, 'itemData');
        const linkParams = !_.get(this.props, 'local')
          ? this.getCanonicalArticleId(itemData)
          : this.getListJsonArticleId(itemData);

        if (_.isEmpty(linkParams)) {
          return null;
        }
        to = this.props.isNtvHeadline ? routes.articleDetail : linkParams.to;
        params = linkParams.params;

        const date = getDate(this.props.itemData);

        const seconds = _.get(this.props, 'itemData.media.duration', false);

        let displayDuration;
        if (seconds) {
          dayjs.extend(utc);
          dayjs.extend(duration);

          const $displayDuration = () => {
            const sub = dayjs().subtract(seconds, 'seconds');
            const duration = dayjs.duration(dayjs().diff(sub));
            return dayjs.utc(duration.asMilliseconds()).format('m:ss');
          };
          displayDuration = $displayDuration();
        }

        let viewPlayerBtn = false;
        if (_.get(this.props, 'itemData.media') && _.get(this.props, 'itemData.media.media_id')) {
          const publishStartAt = _.get(this.props, 'itemData.media.publish_start_at', '1970-01-01T00:00:00+00:00');
          const publishEndAt = _.get(this.props, 'itemData.media.publish_end_at', '2999-12-31T23:59:59+00:00');
          if (publishStartAt <= now && publishEndAt >= now) {
            viewPlayerBtn = true;
          }
        }
        if (this.props.company) {
          card = (
            <MainViewLink to={to} params={params} className="title-card a">
              <div className="title-card-img">
                <BackgroundImage className="artwork" url={getImage(this.props, this.context)} altText={alt} />
                {!_.isEmpty(displayDuration) && (
                  <div>
                    <img src={`/images/play_icon.svg`} className="title-card-playicon" alt="" />
                    <p>{displayDuration}</p>
                  </div>
                )}
              </div>
              <div className="title-card-block">
                <p className="title">
                  {browserInfo.isIE
                    ? lineClamp(_.get(this.props, 'itemData.title'), 42)
                    : _.get(this.props, 'itemData.title')}
                </p>
                <div className="main-card-broadcaster">
                  {!this.context.routeHandler.isEachCompany && (
                    <img src={logo_url} className="card-logo" alt="日テレNEWS NNN" />
                  )}
                  <p className="time">
                    {_.get(this.props, 'itemData.publish_start_date') && this.getBeforeDiffTime(date)}
                  </p>
                </div>
              </div>
            </MainViewLink>
          );
        } else if (this.props.searchContent) {
          card = (
            <MainViewLink to={to} params={params} className="title-card">
              <div className="title-card-img">
                <BackgroundImage className="artwork" url={getImage(this.props, this.context)} altText={alt} />
                {!_.isEmpty(displayDuration) && (
                  <div>
                    <img src={`/images/play_icon.svg`} className="title-card-playicon" alt="" />
                    <p>{displayDuration}</p>
                  </div>
                )}
              </div>
              <div className="title-card-block">
                <p className="title">
                  {browserInfo.isIE
                    ? lineClamp(_.get(this.props, 'itemData.title'), 42)
                    : _.get(this.props, 'itemData.title')}
                </p>
                <div className="main-card-broadcaster">
                  {!this.context.routeHandler.eachCompany && (
                    <img src={logo_url} className="card-logo" alt="日テレNEWS NNN" />
                  )}
                  <p className="time">
                    {_.get(this.props, 'itemData.publish_start_date') && this.getBeforeDiffTime(date)}
                  </p>
                </div>
              </div>
            </MainViewLink>
          );
        } else {
          card = (
            <React.Fragment>
              <NewsListItem
                to={to}
                params={params}
                url={getImage(this.props, this.context)}
                alt={alt}
                viewPlayerBtn={viewPlayerBtn}
                browserInfo={browserInfo}
                title={_.get(this.props, 'itemData.title')}
                duration={seconds}
                logoUrl={logo_url}
                publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
                isEachCompany={this.props.isNtvHeadline ? false : this.context.routeHandler.isEachCompany}
                sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
              />
            </React.Fragment>
          );
        }
      }

      // SP版等でTitleCardをスクロールできる箇所の場合
      if (this.props.scroll) {
        const seconds = _.get(this.props, 'itemData.media.duration', false);

        let displayDuration;
        if (seconds) {
          dayjs.extend(utc);
          dayjs.extend(duration);

          const $displayDuration = () => {
            const sub = dayjs().subtract(seconds, 'seconds');
            const duration = dayjs.duration(dayjs().diff(sub));
            return dayjs.utc(duration.asMilliseconds()).format('m:ss');
          };
          displayDuration = $displayDuration();
        }
        let viewPlayerBtn = false;
        if (_.get(this.props, 'itemData.media') && _.get(this.props, 'itemData.media.media_id')) {
          const publishStartAt = _.get(this.props, 'itemData.media.publish_start_at', '1970-01-01T00:00:00+00:00');
          const publishEndAt = _.get(this.props, 'itemData.media.publish_end_at', '2999-12-31T23:59:59+00:00');
          if (publishStartAt <= now && publishEndAt >= now) {
            viewPlayerBtn = true;
          }
        }

        card = (
          <MainViewLink to={to} params={params} className="scroll-list-card">
            <div className="category-card-img">
              <BackgroundImage
                className="artwork"
                url={getImage(this.props, this.context)}
                altText={_.get(this.props, 'itemData.title')}
              />
              {!_.isEmpty(displayDuration) && (
                <div>
                  <img src={`/images/play_icon.svg`} className="title-card-playicon" alt="" />
                  <p>{displayDuration}</p>
                </div>
              )}
            </div>
            <div className="category-block">
              <p className="title">{_.get(this.props, 'itemData.title')}</p>
            </div>
          </MainViewLink>
        );
      }
    }

    // 誘導モジュール向け
    if (this.props.introduction) {
      const index = _.get(this.props, 'indexNumber', 0) + 1;
      url = _.get(this.props, 'itemData.link_url');
      card = (
        <a id={`introduction-banner-${index}`} className="introduction" href={url} target="_blank">
          <div className="introduction-img">
            <BackgroundImage
              className="artwork introduction-artwork"
              url={getImage(this.props, this.context)}
              altText={alt}
            />
          </div>
          <div className="introduction-block">
            <p className="title">{_.get(this.props, 'itemData.title')}</p>
            <p className="text">{nl2br(_.get(this.props, 'itemData.content', ''))}</p>
          </div>
        </a>
      );
    }

    // お知らせ一覧
    if (this.props.information) {
      if (_.get(this.props, 'itemData.link_url')) {
        url = this.props.itemData.link_url;
      } else {
        if (_.get(this.context.routeHandler, 'params.companyCode')) {
          to = routes.infoDetailEachCompany;
          params = {
            id: _.get(this.props, 'itemData.id'),
            companyCode: _.get(this.context.routeHandler, 'params.companyCode'),
          };
        } else {
          to = routes.infoDetail;
          params = { id: _.get(this.props, 'itemData.id') };
        }
      }

      if (url) {
        card = (
          <a className="news-list-item large" key={this.props.key} href={url} rel="noopener noreferrer" target="_blank">
            <div className="news-list-item-thumb">
              <BackgroundImage className="artwork" url={getImage(this.props, this.context)} altText={alt} />
            </div>
            <div className="news-list-item-details">
              <div className="news-list-item-title">
                <h2 className="news-list-item-title-text">{this.props.itemData.title}</h2>
              </div>
              <div className="news-list-item-broadcaster">
                <p className="news-list-item-broadcaster-time">
                  {this.getBeforeDiffTime(this.props.itemData.publish_start_date)}
                </p>
              </div>
            </div>
          </a>
        );
      } else {
        const duration = _.get(this.props, 'itemData.media.duration', false);
        let viewPlayerBtn = false;
        if (_.get(this.props, 'itemData.media') && _.get(this.props, 'itemData.media.media_id')) {
          const publishStartAt = _.get(this.props, 'itemData.media.publish_start_at', '1970-01-01T00:00:00+00:00');
          const publishEndAt = _.get(this.props, 'itemData.media.publish_end_at', '2999-12-31T23:59:59+00:00');
          if (publishStartAt <= now && publishEndAt >= now) {
            viewPlayerBtn = true;
          }
        }

        card = (
          <React.Fragment>
            <NewsListItem
              to={to}
              params={params}
              url={getImage(this.props, this.context)}
              alt={alt}
              viewPlayerBtn={viewPlayerBtn}
              browserInfo={browserInfo}
              key={this.props.key}
              title={_.get(this.props, 'itemData.title')}
              duration={duration}
              // logoUrl={logo_url}
              cardType="large"
              publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
              sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
            />
          </React.Fragment>
        );
      }
    }

    // 一緒に見られているニュース記事
    if (this.props?.suggestArticles) {
      const linkParams = this.getCanonicalArticleId(_.get(this.props, 'itemData'), this.props.suggestArticles);
      if (_.isEmpty(linkParams)) {
        return null;
      }
      to = linkParams.to;
      params = linkParams.params;
      const duration = _.get(this.props, 'itemData.media.duration', false);

      card = (
        <React.Fragment>
          <NewsListItem
            to={to}
            params={params}
            url={getImage(this.props, this.context)}
            alt={alt}
            viewPlayerBtn={viewPlayerBtn}
            browserInfo={browserInfo}
            title={_.get(this.props, 'itemData.title')}
            duration={duration}
            logoUrl={logo_url}
            publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
            cardType={'small'}
            isEachCompany={false}
            sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
          />
        </React.Fragment>
      );
    }

    // 注目の記事(TRENTA トップ)
    // TODO: publishStartDateが取れているか確認
    if (this.props.homeAttention) {
      const duration = _.get(this.props, 'itemData.media.duration', false);
      card = (
        <NewsListItem
          to={to}
          params={params}
          url={getImage(this.props, this.context)}
          alt={alt}
          viewPlayerBtn={viewPlayerBtn}
          browserInfo={browserInfo}
          title={_.get(this.props, 'itemData.title')}
          duration={duration}
          logoUrl={logo_url}
          publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
          sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
        />
      );
    }

    // TOP, 記事詳細 SP版ランキング
    if (this.props.companyRanking) {
      const companyCode = _.get(this.context, 'routeHandler.params.companyCode', 'ntv');
      const itemData = _.get(this.props, 'itemData');
      const linkParams = !_.get(this.props, 'local')
        ? this.getCanonicalArticleId(itemData)
        : this.getListJsonArticleId(itemData);

      if (_.isEmpty(linkParams)) {
        return null;
      }
      to = linkParams.to;
      params = linkParams.params;
      let viewPlayerBtn = false;
      if (_.get(this.props, 'itemData.media') && _.get(this.props, 'itemData.media.media_id')) {
        const publishStartAt = _.get(this.props, 'itemData.media.publish_start_at', '1970-01-01T00:00:00+00:00');
        const publishEndAt = _.get(this.props, 'itemData.media.publish_end_at', '2999-12-31T23:59:59+00:00');
        if (publishStartAt <= now && publishEndAt >= now) {
          viewPlayerBtn = true;
        }
      }
      const duration = _.get(this.props, 'itemData.media.duration', false);
      card = (
        <React.Fragment>
          <RankingNews
            to={to}
            params={params}
            url={getImage(this.props, this.context)}
            alt={alt}
            viewPlayerBtn={viewPlayerBtn}
            browserInfo={browserInfo}
            title={_.get(this.props, 'itemData.title')}
            duration={duration}
            logoUrl={logo_url}
            publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
            rankingNumber={_.get(this.props, 'rankingNumber')}
            cardType={'vertical'}
            isEachCompany={_.get(this.context.routeHandler, 'isEachCompany')}
          />
        </React.Fragment>
      );
      if (this.props.itemEmpty) {
        card = (
          <React.Fragment>
            <RankingNews
              to={to}
              params={params}
              url={getImage(this.props, this.context)}
              alt={alt}
              viewPlayerBtn={viewPlayerBtn}
              browserInfo={browserInfo}
              title={_.get(this.props, 'itemData.title')}
              duration={duration}
              logoUrl={logo_url}
              publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
              rankingNumber={_.get(this.props, 'rankingNumberEmpty')}
              cardType={'sidebar'}
              isEachCompany={_.get(this.context.routeHandler, 'isEachCompany')}
            />
          </React.Fragment>
        );
      }
    }

    // 特集
    if (this.props.companySpecial || this.props.specialDetail) {
      const duration = _.get(this.props, 'itemData.media.duration', false);
      card = (
        <React.Fragment>
          <NewsListItem
            to={to}
            params={params}
            url={getImage(this.props, this.context)}
            alt={alt}
            viewPlayerBtn={viewPlayerBtn}
            browserInfo={browserInfo}
            title={_.get(this.props, 'itemData.title')}
            duration={duration}
            logoUrl={logo_url}
            publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
            isEachCompany={this.context.routeHandler.isEachCompany}
            sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
          />
        </React.Fragment>
      );
    }

    // ??
    if (this.props.stv) {
      const duration = _.get(this.props, 'itemData.media.duration', false);
      card = (
        <NewsListItem
          to={to}
          params={params}
          url={getImage(this.props, this.context)}
          alt={alt}
          viewPlayerBtn={viewPlayerBtn}
          browserInfo={browserInfo}
          title={_.get(this.props, 'itemData.title')}
          duration={duration}
          logoUrl={logo_url}
          publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
          sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
        />
      );
    }

    // トップページ、各社トップのヘッドライン記事リンク等
    if (this.props.topPage) {
      const imageUrl = getImage(this.props, this.context);

      // canonicalArticleId
      const itemData = _.get(this.props, 'itemData');
      const linkParams = !_.get(this.props, 'local')
        ? this.getCanonicalArticleId(itemData)
        : this.getListJsonArticleId(itemData);

      if (_.isEmpty(linkParams)) {
        return null;
      }
      to = linkParams.to;
      params = linkParams.params;
      const duration = _.get(this.props, 'itemData.media.duration', false);

      card = (
        <NewsListItem
          to={to}
          params={params}
          url={imageUrl}
          alt={alt}
          viewPlayerBtn={viewPlayerBtn}
          browserInfo={browserInfo}
          title={_.get(this.props, 'itemData.title')}
          duration={duration}
          logoUrl={logo_url}
          publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
          cardType={'feature'}
          description={_.get(this.props, 'itemData.paragraphs.0.value', '')}
          isEachCompany={this.context.routeHandler.isEachCompany}
          sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
        />
      );

      // ヘッドライン下部記事リンク
      if (this.props.topPageDefault) {
        card = (
          <NewsListItem
            to={to}
            params={params}
            url={getImage(this.props, this.context)}
            alt={alt}
            viewPlayerBtn={viewPlayerBtn}
            browserInfo={browserInfo}
            title={_.get(this.props, 'itemData.title')}
            duration={duration}
            logoUrl={logo_url}
            publishStartDate={this.getPublishDate(_.get(this.props, 'itemData'))}
            cardType={'small'}
            isEachCompany={this.context.routeHandler.isEachCompany}
            sponsor={_.get(this.props, 'itemData.custom_data.sponsor')}
          />
        );
      }
    }

    // 地方局一覧局リンク(日本地図のページ)
    if (this.props.localBroadcast) {
      const item = _.get(this.props, 'itemData');
      if (_.isEmpty(item)) return null;

      const providerId = _.get(item, 'provider_id');
      if (!providerId) return null;

      // 各局: 各局TOP(TRENTA UI) / ntvのみ: 最新の各社のニュース一覧(TRENTA UI) 「カテゴリすべて」
      let localRoutePath = routes.homeEachTrenta;
      let localRouteParam = { companyCode: providerId };
      if (providerId === 'ntv') {
        localRoutePath = routes.newEachTrenta;
        localRouteParam = { companyCode: providerId, categoryCode: 'all' };
      }
      card = (
        <MainViewLink
          to={localRoutePath}
          params={localRouteParam}
          className="localContent-list"
          key={`localContent-list-${item.label}`}
        >
          <div className="localContent-list-left">
            <img src={item.logo} className="localContent-list-img" alt={item.title} />
          </div>
          <div className="localContent-list-right">
            <span className="localContent-list-right-text">{item.title}</span>
            <i className="fa-article-title-arrow" />
          </div>
        </MainViewLink>
      );
    }

    return card;
  }

  renderTitleDetail() {
    if (this.props.size !== '3x2' && this.props.size !== '3x1') return null;
    const playList = _.get(this.context, 'playList') || {};
    const contents = (
      <div className="title-detail-card">
        <div className="title-detail-card-info">
          <div className="title">{this.context.playList.header}</div>
          <div className="synopsis">{this.context.playList.description}</div>
        </div>
      </div>
    );
    if (playList.linkProps) {
      return <MainViewLink {...this.context.playList.linkProps}>{contents}</MainViewLink>;
    }
    return contents;
  }
}

function NewsListItem(props) {
  const seconds = _.get(props, 'duration', false);
  let displayDuration;
  // durationを秒数から"H:mm:ss"に変換
  if (seconds) {
    dayjs.extend(utc);
    dayjs.extend(duration);

    const $displayDuration = () => {
      const sub = dayjs().subtract(seconds, 'seconds');
      const duration = dayjs.duration(dayjs().diff(sub));
      return dayjs.utc(duration.asMilliseconds()).format('m:ss');
    };
    displayDuration = $displayDuration();
  }

  // idがない場合。
  if (!_.get(props, 'params.id')) return;

  return (
    <MainViewLink
      to={props.to}
      params={props.params}
      className={classnames('news-list-item', props.cardType, props.className)}
    >
      <div className="news-list-item-thumb">
        <BackgroundImage className="news-list-item-artwork" url={props.url} altText={props.alt} />
        {!_.isEmpty(displayDuration) && (
          <div className="news-list-item-duration">
            <i className="fa-play news-list-item-duration-icon" />
            {!props.isListJson && <p className="news-list-item-duration-num">{displayDuration}</p>}
          </div>
        )}
      </div>
      <div className="news-list-item-details">
        <div className="news-list-item-title">
          <h2 className="news-list-item-title-text">
            {props.browserInfo.isIE ? lineClamp(props.title, 42) : props.title}
          </h2>
        </div>
        {props.description && <p className="news-list-item-description">{props.description}</p>}
        <div className="news-list-item-broadcaster">
          {props.params.categoryCode === 'sponsored' ? (
            <div className="article-ad">AD</div>
          ) : (
            props.logoUrl &&
            !props.isEachCompany && (
              <div className="news-list-item-broadcaster-icon">
                <img src={props.logoUrl} className="news-list-item-broadcaster-icon-img" alt="日テレNEWS NNN" />
              </div>
            )
          )}
          <p className="news-list-item-broadcaster-time">
            {
              // 記事広告だったら(カスタムデータにsponsorがあれば)日付の部分をスポンサー名に変更する。
              props.sponsor ? props.sponsor : props.publishStartDate ? props.publishStartDate : '1970年1月1日'
            }
          </p>
        </div>
      </div>
    </MainViewLink>
  );
}

function RankingNews(props) {
  const seconds = _.get(props, 'duration', false);
  let displayDuration;
  // durationを秒数から"m:ss"に変換
  if (seconds) {
    dayjs.extend(utc);
    dayjs.extend(duration);
    const $displayDuration = () => {
      const sub = dayjs().subtract(seconds, 'seconds');
      const duration = dayjs.duration(dayjs().diff(sub));
      return dayjs.utc(duration.asMilliseconds()).format('m:ss');
    };
    displayDuration = $displayDuration();
  }
  return (
    <MainViewLink
      to={props.to}
      params={props.params}
      className={classnames('rankingNews', props.cardType, props.className)}
    >
      {props.cardType === 'main' && (
        <div className={classnames('rankingNews-number-outer rank-' + props.rankingNumber)}>{props.rankingNumber}</div>
      )}
      <div className="rankingNews-thumb">
        <BackgroundImage className="rankingNews-artwork" url={props.url} altText={props.alt} />
        <div className={classnames('rankingNews-number rank-' + props.rankingNumber)}>{props.rankingNumber}</div>
        {!_.isEmpty(displayDuration) && (
          <div className="rankingNews-duration">
            <i className="fa-play rankingNews-duration-icon" />
            {!props.isListJson && <p className="rankingNews-duration-num">{displayDuration}</p>}
          </div>
        )}
      </div>
      <div className="rankingNews-details">
        <div className="rankingNews-title">
          {props.cardType === 'sidebar' ? (
            <p className="rankingNews-title-text">
              {props.browserInfo.isIE ? lineClamp(props.title, 42) : props.title}
            </p>
          ) : (
            <h2 className="rankingNews-title-text">
              {props.browserInfo.isIE ? lineClamp(props.title, 42) : props.title}
            </h2>
          )}
        </div>
        {props.description && <p className="rankingNews-description">{props.description}</p>}
        <div className="rankingNews-broadcaster">
          {!props.isEachCompany && (
            <div className="rankingNews-broadcaster-icon">
              <img src={props.logoUrl} className="rankingNews-broadcaster-icon-img" alt="日テレNEWS NNN" />
            </div>
          )}
          <p className="rankingNews-broadcaster-time">{props.publishStartDate}</p>
        </div>
      </div>
    </MainViewLink>
  );
}

export default withModalContext(TitleCard);
