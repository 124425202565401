import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ClipboardJS from 'clipboard';
import { withModalContext } from '../../../common/context/ModalContext';

class Share extends Component {
  static propTypes = {
    item: PropTypes.object,
    routeHandler: PropTypes.object,
  };

  static contextTypes = {
    models: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.clipboardOut = this.clipboardOut.bind(this);
    this.url = _.get(this.props, 'routeHandler.url');
    let host = _.get(context.models, 'hosts.data.ssr_host');
    host = host && host.replace('127.0.0.1', 'localhost');
    this.url = `${host}${this.url}`;
    this.title = _.get(this.props, 'title');

    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
    this.clipboard = new ClipboardJS('.share-list-link');
    this.clipboard.on('success', function(e) {
      if (e && e.trigger && e.trigger.classList) {
        e.trigger.classList.add('tooltipped');
        e.trigger.classList.add('tooltipped-s');
        e.trigger.classList.add('tooltipped-no-delay');
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  clipboardOut(e) {
    if (e && e.target && e.target.classList) {
      e.target.classList.remove('tooltipped');
      e.target.classList.remove('tooltipped-s');
      e.target.classList.remove('tooltipped-no-delay');
    }
  }

  render() {
    const twitterText = `${this.title.replace(/#/g, '＃').replace(/@/g, '＠')}｜日テレNEWS NNN`;
    return (
      <div className="share-contents">
        {_.get(this.props, 'article') ? (
          <p className="share-title">この記事を共有</p>
        ) : ( _.get(this.props,"articleImage") ? (
                <p className="share-title">この画像を共有</p>
            ) : (
                <p className="share-title">このページを共有</p>
            )
        )}
        <ul className="share-block">
          <li className="share-list">
            <a
              className="share-list-link"
              href={`https://twitter.com/intent/tweet?text=${encodeURI(twitterText)}&url=${this.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/x.png" alt="twitter" width="30" height="30" />
            </a>
          </li>
          <li className="share-list">
            <a
              className="share-list-link"
              href={`https://timeline.line.me/social-plugin/share?url=${this.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/svg/line.svg" alt="line" width="30" height="30" />
            </a>
          </li>
          <li className="share-list">
            <a
              className="share-list-link"
              href={`https://www.facebook.com/sharer/sharer.php?u=${this.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="30" height="30" />
            </a>
          </li>
          <li className="share-list">
            <a
              className="share-list-link"
              href={`https://b.hatena.ne.jp/entry/panel/?url=${this.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/svg/hatena.svg" alt="hatena" width="30" height="30" />
            </a>
          </li>
          <li className="share-list">
            <span
              className="share-list-link link"
              onBlur={this.clipboardOut}
              onMouseLeave={this.clipboardOut}
              data-clipboard-text={this.url}
              aria-label="URLをコピーしました"
            >
              <i className="fa-link" />
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

export default withModalContext(Share);
