import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withModalContext } from '../../../common/context/ModalContext';

class BannerList extends Component {
  static propTypes = {
    wrapperPublicity : PropTypes.bool,
    links: PropTypes.array,
  };

  static contextTypes = {
    models: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let links;
    links = _.compact(
      _.map(this.props.links || [], (linkData, index) => {
        if (!linkData || !linkData.title) return;
        if(!linkData.image_url){
          return (
            <div key={`banner-link-${index}`} className="button-view no-images">
              <a href={linkData.link_url} rel="noopener noreferrer" target="_blank" className="btn btn-line gray-light-color btn-large"><i className="fa-target-blank"/><span>{linkData.title}</span></a>
            </div>
          );
        }
        let url = linkData.image_url;
        return (
          <div key={`banner-link-${index}`} className="button-view">
            <a href={linkData.link_url} rel="noopener noreferrer" target="_blank">
              <img src={url} alt={linkData.title} />
            </a>
          </div>
        );
      }),
    );

    return (
      <React.Fragment key="banner-list">
        <div className="thumbnail-contents">
          {links}
        </div>
      </React.Fragment>
    );
  }
}

export default withModalContext(BannerList);
