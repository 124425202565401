import _ from 'lodash';
import PlayerApp from '../../common/PlayerApp';
import StaticJsonApp from '../../common/StaticJsonApp';
import App from '../../sketch-platform/app/App.js';
import routes from '../common/routes';
import createApp from './createApp';
import routeHandlers from './routeHandlers';

let copyrights = [];
const discoveryApp = createApp({}, {}, {});
const postPlayApp = createApp();

export default new App(
  {
    routeHandlers,
    routes,
  },
  {
    handleCreateContext: function (context) {
      const playerApp = new PlayerApp({
        config: _.get(context, 'model.models.config.data', {}),
        browserInfo: _.get(context, 'model.models.browserInfo.data', {}),
        googlecast: _.get(context, 'model.models.googlecast.data', {}),
      });
      const staticJsonApp = new StaticJsonApp(context.model.models);

      // const gtmApp = new GtmApp({
      //   models: _.get(context, 'model.models', {}),
      //   config: _.get(context, 'model.models.config.data', {}),
      //   browserInfo: _.get(context, 'model.models.browserInfo.data', {}),
      // });

      // const watchPartyApp = new WatchPartyApp(
      //   authApp,
      //   _.get(context, 'model.models', {}),
      //   {},
      //   _.get(context, ['model', 'node', 'req']),
      // );


      return Object.assign({}, context, {
        discoveryApp,
        playerApp,
        staticJsonApp,
        postPlayApp,
        discoveryStore: discoveryApp.getStore(),
      });
    },
  },
);
