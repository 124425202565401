import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class Congestion extends Component {
  static bundleName = 'errors/Congestion';

  static contextTypes = {
    getModelData: PropTypes.func,
  };

  render() {
    return (
      <div className="contentsWrapper error-page 503-error">
        <div className="error-block">
          <div className="error-title">ただ今、大変混み合っております</div>
          <p className="error-text">現在アクセスが集中している為、繋がりにくくなっています。<br />しばらくたってから再度アクセスをお願いします。</p>
          <a href="/" className="error-link">日テレNEWS NNNトップへ</a>
        </div>
      </div>
    );
  }
}
