import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import routes from "../../../common/routes";
import MainViewLink from "../../../common/components/MainViewLink";
import categoryCode2Name from '../../../../common/categoryCode2Name';
import * as ERROR from "../../../../constants/error";
import removeTag from "../../../../common/react-removeTag";
import convertAdTagUiViewName from '../../../common/utils/convertAdTagUiViewName';
import TaboolaWidget from '../../../common/components/TaboolaWidget';

const PERPAGE = 10;

/**
 * 各カテゴリートップ(TRENTAでは初期リリースでは含まない)
 */
class CategoryContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    getModelData: PropTypes.func,
    topText: PropTypes.string,
  };

  static getRootPath = function (models, options, props) {
    return ['article', 'headline', _.get(props, 'categoryCode', '')];
  };

  static getPaths = function (models, options, props) {
    let paths = [];

    const indexes = function (models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum
            : options && options.numTitles
            ? options.numTitles + (props.fromNum || 0)
            : PERPAGE - 1,
      };
    };

    const rootPath = this.getRootPath(models, options, props);

    paths = paths.concat([
      ['link', 'category_menu'],
      ['article', 'headline', _.get(props, 'categoryCode', ''), indexes(models, options, props), [
        'id',
        'title',
        'thumbnail',
        'publish_start_date',
        'first_publish_date',
        'created_at'
      ]],
    ]);

    return paths;
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function(models, options, props) {
    const self = this;
    return (prefetchResult) => {
      const rootPath = self.getRootPath(models, null, props);
      let list = _.get(prefetchResult, ['json'].concat(rootPath));
      list = _.compact(
        _.map(_.values(_.omit(list), ['$__path', 'length']), (item) => {
          if (_.isEmpty(_.get(item, 'source_systems.id'))) {
            return;
          }
          return item;
        }),
      );
      const categoryCodes = _.keys(_.get(models, 'config.data.categoryTags.code2name', {}));
      const categoryCode = _.get(props, 'categoryCode');
      if ((!list || !list.length) && !_.includes(categoryCodes, categoryCode)) return { error: ERROR.NOTFOUND };
      return null;
    };
  };

  constructor(props, context) {
    super(props, context);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.items = this.model.getSync(rootPath);
    const categories = this.model.getSync(['link', 'header_menu']);

    const categoryName = categoryCode2Name(_.get(this.props, 'categoryCode', ''), _.get(context, 'models.config.data'));

    this.category = _.find(categories, { title: categoryName });

    this.state = {
      categoryQuery: categoryName,
      categoryCode: _.get(this.props, 'categoryCode', ''),
      dispose: null,
      fetchDataError: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.items) {
      this.fetchData(this.model, this.props);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
  }

  fetchData(model, props) {
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      this.state.dispose();
    }
    this.state[JSON.stringify(paths)] = paths;
    const evaluator = model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then((res) => {
        this.items = _.get(res, ['json'].concat(rootPath));

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch((e) => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    let mainCard;
    let cards = [];
    if (this.items) {
      let items = this.items;
      if (items && _.size(items)) {
        const displayCnt = this.context.spMode ? PERPAGE - 2 : PERPAGE
        items = _.slice(_.values(items), 0, displayCnt);
      }

      cards = _.compact(
        _.map(items, (itemData, index) => {
          if (!itemData || !itemData.source_systems) return;
          if (index == '0') {
            mainCard = (
              <TitleCard
                key={`category_card_${index}_${itemData.source_systems.id}`}
                itemData={itemData}
                maincard={true}
              />
            );
            return;
          }
          return (
            <TitleCard
              key={`category_card_${index}_${itemData.source_systems.id}`}
              itemData={itemData}
              default={true}
            />
          );
        }),
      );
    }

    const canonical = `/category/${_.get(this.props, 'categoryCode')}`;

    const browserInfo = this.context.getModelData('browserInfo');
    const adSpace = browserInfo.isMobile && !_.get(this.props, 'invisibleAd') ? (
      <div className="publicity-contents">
        <div id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_small_ad`} />
      </div>
    ) : null;

    const topText = _.get(this.context, 'topText');

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(`${this.state.categoryQuery}ニュース｜${topText}`) });
            metas.push({ property: 'og:type', content: 'article' });
            if (_.get(this.category, 'content')) {
              metas.push({ name: 'description', content: removeTag(_.get(this.category, 'content')) });
              metas.push({ property: 'og:description', content: removeTag(_.get(this.category, 'content')) });
            }

            return <Helmet
              title={`${this.state.categoryQuery}ニュース｜${topText}`}
              meta={metas}
            />;
          }}
        </HtmlContext.Consumer>
        <React.Fragment>
          {mainCard}
          {_.isEmpty(cards) && _.isEmpty(mainCard) ?
            <div>該当コンテンツが存在しません。</div>
          : <div className="title-card-wrapper">
              {adSpace}
              {cards}
              <div key={`category_taboola_${Date.now()}`}>
                <TaboolaWidget target="below1" />
              </div>
            </div>
          }
          
          <div className="btn-block">
            <MainViewLink className="btn btn-fill base-color btn-large" to={routes.new} params={{categoryCode: this.state.categoryCode}}>{this.state.categoryQuery}ニュースをもっと見る</MainViewLink>
          </div>
        </React.Fragment>
        <Analytics pageTitle={`${this.state.categoryQuery}カテゴリートップ`} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(CategoryContent);
root.getPaths = CategoryContent.getPaths;
root.getRootPath = CategoryContent.getRootPath;
root.getPrefetchPaths = CategoryContent.getPrefetchPaths;
root.afterPrefetch = CategoryContent.afterPrefetch;
export default root;
