import _get from 'lodash/get';
import _keys from 'lodash/keys';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import routes from '../../../common/routes';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import { Link as Scroll } from 'react-scroll';
import { localPrefectures, localBroadcaster, localContentMapArray } from '../../config/localConfig';

class LocalContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    history: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    topText: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.handleChangeArea = this.handleChangeArea.bind(this);

    if (_keys(_get(props, 'routeHandler.params', [])).length) {
      this.href = routes.localAreaPrefecture.makePath({
        area: _get(props, 'routeHandler.params.area'),
        prefecture: _get(props, 'routeHandler.params.prefecture'),
      });
      this.to = routes.localAreaPrefecture;
      this.params = {
        area: _get(props, 'routeHandler.params.area'),
        prefecture: _get(props, 'routeHandler.params.prefecture'),
      };
    } else {
      this.href = routes.local.makePath();
      this.to = routes.local;
    }

    this.state = {
      currentArea: _get(props, 'area', 'all'),
      currentPrefecture: _get(props, 'prefecture', ''),
      dispose: null,
      fetchDataError: null,
      loading: false,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    if (_get(props, 'routeHandler.query.p') != state.currentPage) {
      return {
        currentPage: _get(props, 'routeHandler.query.p', 1),
      };
    }
    return null;
  }


  handleChangeArea(area) {
    if (area && this.state.currentArea !== area) {
      const prefecture = _get(localPrefectures, [area, 'prefectures', 0]);
      let to, url;
      if (area === 'all') {
        to = routes.local;
        url = to.makePath();
        this.context.history.push(url);
      } else if (area === 'hokkaido') {
        to = routes.localAreaPrefecture;
        url = to.makePath({ area, prefecture: area });
        this.context.history.push(url);
      } else {
        this.context.history.push(_get(prefecture, 'link_url'));
      }
    }
  }

  render() {
    let Block;
    let broadcasterCards = [];
    let broadcasterImages = [];
    broadcasterCards = _compact(
      _map(localBroadcaster, (localList, index) => {
        let localCards = [];
        let regionId = _get(localList, 'label');
        localCards = _compact(
          _map(_get(localList, 'broadcaster'), (list, index) => {
            broadcasterImages.push(list.logo)
            return (
              <TitleCard
                key={`local_card_${index}`}
                itemData={list}
                localBroadcast={true}
                lineLayout={!this.context.spMode}
              />
            );
          }),
        );
        return (
          <div className="localContent-region" id={regionId} key={`localContent-region-${regionId}`}>
            <div className="localContent-region-header">{_get(localList, 'label')}</div>
            <div className="localContent-region-body">{localCards}</div>
          </div>
        );
      }),
    );
    if (!_isEmpty(broadcasterImages)) this.props.setPreloadImages(broadcasterImages);

    const mapBtn = (
      _map(localContentMapArray, (area, index) => {
        return <Scroll className={`button bold radius secondary ${area.className}`} to={area.name} key={`area_${index}`}>{area.name}</Scroll>
      })
    )
    Block = (
      <div className="localContent">
        <div className="localContent-map">
          <img src="/images/japan_map.png" className="localContent-map-img" />
          {mapBtn}
        </div>
        <div className="localContent-lists">{broadcasterCards}</div>
      </div>
    );

    const area = _get(localPrefectures, `${this.state.currentArea}`);
    const prefecture = _find(_get(area, 'prefectures'), { label: this.state.currentPrefecture });

    let subTitle;
    if (area && _get(area, 'label') !== '全国') {
      subTitle = _get(prefecture, 'title');
      if (!subTitle) subTitle = _get(area, 'label');
    }

    const topText = _get(this.context, 'topText');
    let title = `地域・都道府県の一覧｜${topText}`;

    let canonical = `/nnn${this.currentPage > 1 ? `?p=${this.currentPage}` : ''}`;
    const areaParam = _get(this.props, 'routeHandler.params.area');
    const prefectureParam = _get(this.props, 'routeHandler.params.prefecture');
    if (areaParam && prefectureParam) {
      canonical = `/nnn/${areaParam}/${prefectureParam}${this.currentPage > 1 ? `?p=${this.currentPage}` : ''}`;
    }
    const description = '日本テレビ系NNN30局の各局トップページへのリンク一覧です。';

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(title) });
            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });
            metas.push({ property: 'og:type', content: 'article' });
            metas.push({ property: 'author', content: '日本テレビ放送網株式会社' });

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={title} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        {Block}
        <Analytics pageTitle={title} path={canonical} env={_get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(LocalContent);
export default root;
