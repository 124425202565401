import PropTypes from 'prop-types';
import TitleCard from './TitleCard';
import React from 'react';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import _ from 'lodash';
import categoryCode2Name from '../../../../common/categoryCode2Name';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import TaboolaWidget from '../../../common/components/TaboolaWidget';

class RankingList extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    sideContent: PropTypes.bool,
    company: PropTypes.bool,
    home: PropTypes.bool,
    articleDetail: PropTypes.bool,
    recommend: PropTypes.bool,
  };

  static getRootPath = function(models, options, props) {
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv')
    return ['article', 'ranking', companyCode];
  };

  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    return [rootPath];
  };

  constructor(props, context) {
    super(props, context);

    this.articlesPagesList = ['articles', 'ArticlesContentImages', 'articleDetail', 'ArticleDetailContentImages', 'ArticleDetailContentImagesEachCompany'];

    this.state = {
      categoryQuery: categoryCode2Name(
        _.get(this.context, 'routeHandler.params.categoryCode'),
        _.get(context, 'models.config.data'),
      ),
      categoryCode: _.get(this.context, 'routeHandler.params.categoryCode', 'all'),
      dispose: null,
      fetchDataError: null,
      loading: false,
      rankingList: true,
      ctx: this.context,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.items) {
      this.fetchData();
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
  }

  static getDerivedStateFromProps(props, state) {
    const categoryCode = _.get(props, 'categoryCode');
    if (categoryCode) {
      return {
        categoryQuery: categoryCode2Name(_.get(props, 'categoryCode'), _.get(state.ctx, 'models.config.data')),
        categoryCode: _.get(props, 'categoryCode', 'all')
      };
    }
      return null;
  }

  fetchData(props = this.props, context = this.context) {
    const paths = this.constructor.getPaths(context.models, {}, props);

    if (this.state.dispose) {
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;
    const evaluator = props.model && props.model.fetch(paths);
    if (!evaluator) {
      return;
    }
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    this.setState({ loading: true });

    let rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (rootPath && rootPath.length) rootPath = rootPath.join('.');

    evaluator
      .then(res => {
        this.items = _.get(res, `json.${rootPath}`, {});
        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          loading: false,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  getRecommendParam() {
    const isEachCompany = _.get(this.context.routeHandler, 'isEachCompany');
    // 記事詳細・画像の場合
    if (this.isArticlePage()) {
      return isEachCompany ? 'AR_22' : 'AR_19';
    }
    // その他共通
    return isEachCompany ? 'AR_14' : 'AR_13';
  }

  isArticlePage() {
    const uiView = _.get(this, 'context.routeHandler.uiView');
    return _.includes(this.articlesPagesList, uiView);
  }

  render() {
    if (!_.get(this.props, 'articles')) return null;
    let path, parm, _popInId;
    if (_.get(this.context.routeHandler, 'params.companyCode') && _.get(this.context.routeHandler, 'isEachCompany')) {
      path = routes.rankingEachCompany;
      parm = {companyCode: _.get(this.context.routeHandler, 'params.companyCode') ,categoryCode: 'all'};
      _popInId = this.isArticlePage() ? '_popIn_trenta_sp_ranking_NNN' : '_popIn_trenta_sp_ranking_top_NNN';
    } else {
      path = routes.rankingTrenta;
      parm = {categoryCode: _.get(this.context, 'routeHandler.params.categoryCode', 'all')};
      if(_.get(this.context, 'routeHandler.params.categoryCode') === "sponsored"){
        parm = {categoryCode: "all"}
      }
      _popInId = this.isArticlePage() ? '_popIn_trenta_sp_ranking_NTV' : '_popIn_trenta_sp_ranking_top_NTV';
    }

    const _recommendParam = this.getRecommendParam();
    const MORE_BUTTON_TEXT = 'ランキング一覧';

    let cards = [];
    let spBlock;
    const url = _.get(this.context, 'models.hosts.data.ssr_host') + _.get(this.context, 'routeHandler.path');

    if (_.get(this.props, 'articles')) {
      let items = _.get(this.props, 'articles');

      if (items && _.size(items)) {
        items = _.slice(_.values(items), 0, 5);
      }

      cards = _.compact(
        _.map(items || [], (itemData, index) => {
          if (!itemData || !itemData.source_systems) return;
          return (
            <TitleCard
              key={`ranking_card_${index}_${itemData.source_systems.id}`}
              itemData={itemData}
              ranking={true}
              rankingNumber={parseInt(index) + 1}
              rankingList={true}
              sideContent={this.props.sideContent}
            />
          );
        }),
      );
      spBlock = (
        <div className="contentsWrapper" key="ranking-list">
          <div className="category-main-title-block">
            <div className="category-main-title e">
              ランキング
              {_.get(this.state, 'categoryQuery') && !_.get(this.context.routeHandler, 'isEachCompany') &&
                <span>{_.get(this.state, 'categoryQuery')}</span>
              }
            </div>
            {_.get(this.state, 'categoryCode') && (
              <MainViewLink
                className="btn btn-fill base-color btn-small btn-category"
                to={path}
                params={parm}
              >
                {MORE_BUTTON_TEXT}
              </MainViewLink>
            )}
          </div>
          <div className="scroll-wrapper">
            <div className="scroll-list">
              {cards}
              <TaboolaWidget
                target="ranking"
                invisibleAd={_.get(this.props, 'invisibleAd')}
                className="scroll-list-card"
              />
            </div>
          </div>
        </div>
      );
    } else {
      cards = _.compact(
        _.map([1, 2, 3, 4, 5], index => {
          return (
            <TitleCard
              key={`ranking_card_${index}`}
              companyRanking={true}
              itemEmpty={true}
              rankingEmpty={true}
              rankingNumberEmpty={parseInt(index)}
            />
          );
        }),
      );
    }

    let companyCards = [];
    companyCards = _.compact(
      _.map(this.props.articles || [], (itemData, index)  => {
        if (!itemData || !itemData.source_systems || index > 4) return;
        return <TitleCard
                  key={`ranking_card_${index}`}
                  itemData={itemData}

                  rankingNumber={parseInt(index)+1}
                  companyRanking={true}
                />;
      }),
    );

    const domain = _.get(this.context, 'models.hosts.data.domain_url');
    const pageUrl = _.get(this.context, 'models.hosts.data.domain_url') + _.get(this.context, 'routeHandler.url');

    // TOPページSP版ランキング
    if (this.props.company) {
      spBlock = (
        <div className="ranking-list mobile a" key="ranking-list">
          <div className="ranking-list-header">
            <div className="category-main-title e">
              ランキング
              {_.get(this.state, 'categoryQuery') && !_.get(this.context.routeHandler, 'isEachCompany') && _.get(this.state, 'categoryCode') !== "sponsored" &&  
                <span>{_.get(this.state, 'categoryQuery')}</span>
              }
            </div>
            {_.get(this.state, 'categoryCode') && (
              <MainViewLink
                className="button secondary medium radius bold"
                to={path}
                params={parm}
              >
                {MORE_BUTTON_TEXT}
              </MainViewLink>
            )}
          </div>
          <div className="ranking-list-contents scroll-wrapper">
            <div className="scroll-list">
              {companyCards}
              <TaboolaWidget
                target="ranking"
                invisibleAd={_.get(this.props, 'invisibleAd')}
                className="_taboola_recommend scroll-list-card"
              />
            </div>
          </div>
        </div>
      );
    }

    const pcBlock = (
      <React.Fragment key="ranking-list">
        <div className="ranking-list desktop sidebar">
          <div className="ranking-list-title">
            ランキング
            {_.get(this.state, 'categoryQuery') && !_.get(this.context.routeHandler, 'isEachCompany') && _.get(this.state, 'categoryCode') !== "sponsored" &&
              <span>{_.get(this.state, 'categoryQuery')}</span>
            }
          </div>
          <div className="ranking-list-contents">
            {cards}
            {!_.get(this.props, 'invisibleAd') ? (
              <div key={`${_recommendParam}${Date.now()}`} className="rankingNews sidebar rankingListAd">
                <TaboolaWidget target="ranking" />
              </div>
            ) : null}
          </div>
          {_.get(this.state, 'categoryCode') && (
            <div className="ranking-list-btns">
              <MainViewLink
                className="button secondary medium radius bold"
                to={path}
                params={parm}
              >
                {MORE_BUTTON_TEXT}
              </MainViewLink>
            </div>
          )}
        </div>
      </React.Fragment>
    );

    return this.context.spMode ? spBlock : pcBlock;
  }
}
const root = withLayoutContext(RankingList);
root.getPaths = RankingList.getPaths;
root.getRootPath = RankingList.getRootPath;
export default root;
