import {EventEmitter} from "fbemitter";
import generateUuid from './generateUuid';
import CookieDough from 'cookie-dough';
import history from '../apps/history';
import Axios from './Axios';
import url from 'url';
import _ from 'lodash';
import promiseRetry from '../utils/promiseRetry';

const BEACON_SPAN = 30;

class PlayerApp {
  constructor(options = {}) {
    this._options = options;
    this._playerId = generateUuid();
    this._emitter = new EventEmitter();
    this._cookies = CookieDough();

    this._localPlayer = null;
    this._listeners = [];
    this._metaId = null;
    this._overSeeking = false;

    this.loaded(false);
    this.started(false);
    this.canplayed(false);
    this.__autoplay = false;
    this.__currentPlayerTime = 0;
    this.__paused = true;
    this.__seeking = false;
    this.__pt = 0;
    this.__pFrom = undefined;
    this.__beaconIntervalId = undefined;
    this.__milestone = 0;

    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    if (typeof window !== 'undefined') {
      history.listen(this.handleHistoryChange);
    }

    this.onLocalPlayerLoadstart = this.onLocalPlayerLoadstart.bind(this);
    this.onLocalPlayerProgress = this.onLocalPlayerProgress.bind(this);
    this.onLocalPlayerPause = this.onLocalPlayerPause.bind(this);
    this.onLocalPlayerPlay = this.onLocalPlayerPlay.bind(this);
    this.onLocalPlayerPlaying = this.onLocalPlayerPlaying.bind(this);
    this.onLocalPlayerLoadedmetadata = this.onLocalPlayerLoadedmetadata.bind(this);
    this.onLocalPlayerEnded = this.onLocalPlayerEnded.bind(this);
    this.onLocalPlayerError = this.onLocalPlayerError.bind(this);
    this.onLocalPlayerDurationchange = this.onLocalPlayerDurationchange.bind(this);
    this.onLocalPlayerRatechange = this.onLocalPlayerRatechange.bind(this);
    this.onLocalPlayerVolumeChange = this.onLocalPlayerVolumeChange.bind(this);
    this.onLocalPlayerStalled = this.onLocalPlayerStalled.bind(this);
    this.onLocalPlayerEmptied = this.onLocalPlayerEmptied.bind(this);
    this.onLocalPlayerSuspend = this.onLocalPlayerSuspend.bind(this);
    this.onLocalPlayerWaiting = this.onLocalPlayerWaiting.bind(this);
    this.onLocalPlayerBuffering = this.onLocalPlayerBuffering.bind(this);
    this.onLocalPlayerBuffered = this.onLocalPlayerBuffered.bind(this);
    this.onLocalPlayerCanplay = this.onLocalPlayerCanplay.bind(this);
    this.onLocalPlayerSeeked = this.onLocalPlayerSeeked.bind(this);
    this.onLocalPlayerSeeking = this.onLocalPlayerSeeking.bind(this);
    this.onLocalPlayerCanplaythrough = this.onLocalPlayerCanplaythrough.bind(this);
    this.onLocalPlayerFirstplay = this.onLocalPlayerFirstplay.bind(this);
    this.onLocalPlayerLoadeddata = this.onLocalPlayerLoadeddata.bind(this);
    this.onLocalPlayerTimeupdate = this.onLocalPlayerTimeupdate.bind(this);
    this.onLocalPlayerSrcFallback = this.onLocalPlayerSrcFallback.bind(this);
    this.onLocalPlayerPlayTerminated = this.onLocalPlayerPlayTerminated.bind(this);

    this.on = this.on.bind(this);
    this.off = this.off.bind(this);

    this._axios = new Axios;
  }

  handleHistoryChange(location, action) {
    return;
  }

  setMetaId(metaId) {
    this._metaId = metaId;
  }

  setLocalPlayer(localPlayer, options={}) {

    this._localPlayer = localPlayer;

    if (this._localPlayer) {

      this.loaded(false);
      this.started(false);
      this.canplayed(false);
      this.__autoplay = false;
      this.__autoplayError = false;
      this.__currentPlayerTime = 0;
      this.__pt = 0;
      this.__pFrom = undefined;
      this.__beaconIntervalId = undefined;
      this.__milestone = 0;

      if (options.paused !== undefined) {
        this.__paused = options.paused;
        this.__autoplay = !this.__paused;
      }
      this.__seeking = false;
      this._overSeeking = false;

      // スリープタイマー
      // 連続再生中は初期化しない
      if (!this.__nextPlaying) {
        this.resetSleepTimer();
      }
      this.__nextPlaying = false;

      this._localPlayer.on('loadstart', this.onLocalPlayerLoadstart);
      this._localPlayer.on('progress', this.onLocalPlayerProgress);
      this._localPlayer.on('pause', this.onLocalPlayerPause);
      this._localPlayer.on('play', this.onLocalPlayerPlay);
      this._localPlayer.on('playing', this.onLocalPlayerPlaying);
      this._localPlayer.on('loadedmetadata', this.onLocalPlayerLoadedmetadata);
      this._localPlayer.on('ended', this.onLocalPlayerEnded);
      this._localPlayer.on('error', this.onLocalPlayerError);
      this._localPlayer.on('durationchange', this.onLocalPlayerDurationchange);
      this._localPlayer.on('ratechange', this.onLocalPlayerRatechange);
      this._localPlayer.on('volumechange', this.onLocalPlayerVolumeChange);
      this._localPlayer.on('stalled', this.onLocalPlayerStalled);
      this._localPlayer.on('emptied', this.onLocalPlayerEmptied);
      this._localPlayer.on('suspend', this.onLocalPlayerSuspend);
      this._localPlayer.on('waiting', this.onLocalPlayerWaiting);
      this._localPlayer.on('buffering', this.onLocalPlayerBuffering);
      this._localPlayer.on('buffered', this.onLocalPlayerBuffered);
      this._localPlayer.on('canplay', this.onLocalPlayerCanplay);
      this._localPlayer.on('seeked', this.onLocalPlayerSeeked);
      this._localPlayer.on('seeking', this.onLocalPlayerSeeking);
      this._localPlayer.on('canplaythrough', this.onLocalPlayerCanplaythrough);
      this._localPlayer.on('firstplay', this.onLocalPlayerFirstplay);
      this._localPlayer.on('loadeddata', this.onLocalPlayerLoadeddata);
      this._localPlayer.on('timeupdate', this.onLocalPlayerTimeupdate);
      this._localPlayer.on('src-fallback', this.onLocalPlayerSrcFallback);
      this._localPlayer.on('play-terminated', this.onLocalPlayerPlayTerminated);
    }
  }

  loaded(flag) {
    // console.log('PlayerApp: loaded: ' + flag);
    if (flag === undefined) return this.__loaded;
    if (flag === this.__loaded) return;
    if (flag) {
      this.__loaded = flag;
      this._emitter.emit('loaded');
      if (this.__autoplay && !this.__started) {
        // playが先に呼ばれる場合startedの更新はしていない為
        // このタイミングでpausedでなければstaredを呼ぶ
        if (!this.localPlayer().paused()) {
          this.started(true);
        } else {
          // console.log('PlayerApp: play 1')
          setTimeout(async () => {
            try {
              await this.localPlayer().play();
            } catch (e) {
              console.error(e)
              this.__autoplayError = true;
              this._emitter.emit('autoPlayError');
            }
          }, 0);
        }
      }
      if (this.__started) {
        this._emitter.emit('playStartCompleted');
      }
    } else {
      this.__loaded = flag;
    }
  }

  started(flag) {
    // console.log('PlayerApp: started: ' + flag);
    if (flag === undefined) return this.__started;
    if (flag === this.__started) return;
    if (flag) {
      if (this.__loaded) {
        this.__started = flag;
        this._emitter.emit('playStartCompleted');
      }
    } else {
      this.__started = flag;
    }
  }

  canplayed(flag) {
    // console.log('PlayerApp: canplayed: ' + flag);
    if (flag === undefined) return this.__canplayed;
    if (flag === this.__canplayed) return;
    if (flag) {
      this.__canplayed = flag;
      // canplayが呼ばれる前にplayが呼ばれるケースがあり
      // 先にplayされた場合はこのタイミングで再生させる
      if (this.__autoplay && this.__started && !this.__paused) {
        // console.log('PlayerApp: play 2')
        setTimeout(() => { this.localPlayer().play(); }, 0);
      }
    } else {
      this.__canplayed = flag;
    }
  }

  // player events

  onLocalPlayerLoadstart() {
    // console.log('PlayerApp: onLocalPlayerLoadstart');
    this._emitter.emit('loadstart');
  }

  onLocalPlayerLoadedmetadata() {
    // console.log('PlayerApp: onLocalPlayerLoadedmetadata');
    this._emitter.emit('loadedmetadata');
  }

  onLocalPlayerLoadeddata() {
    // console.log('PlayerApp: onLocalPlayerLoadeddata');
    this.triggerLoadEvent();
    this._emitter.emit('loadeddata');
  }

  onLocalPlayerProgress() {
    // console.log('PlayerApp: onLocalPlayerProgress');
    this._emitter.emit('progress');
  }

  onLocalPlayerPause() {
    // console.log('PlayerApp: onLocalPlayerPause');
    if (this.playReload) return;

    const exec = () => {
      this.stopBeacon();
      if (typeof this.__pFrom !== 'undefined') {
        let addPt = parseInt(((new Date().getTime()) - this.__pFrom) / 1000, 10);
        if (!isNaN(addPt)) {
          this.__pt += addPt;
        }
        this.trackEvent('pause', {pt: parseInt(this.__pt, 10)});
      }
      this.__pFrom = undefined;
      this.__paused = true;

      if (this.pauseTimer) clearTimeout(this.pauseTimer);
      delete this.pauseTimer;
      this._emitter.emit('pause');

      // シーク中だったらendedしない
      if (this._overSeeking && !this.__seeking) {
        this.onLocalPlayerEnded();
      }
    }

    // mac safari フルスクリーンにした時にpause とplay が連続で通知されるケースがある
    this.pauseTimer = setTimeout(exec, 0);
  }

  onLocalPlayerPlay() {
    // console.log('PlayerApp: onLocalPlayerPlay');

    this.__autoplayError = false;

    if (this.playReload) return;

    if (!this.loaded() || !this.canplayed()) {
      this.__autoplay = true;
    }
    this.started(true);
    this.__pFrom = new Date().getTime();
    this.trackEvent('play', {pt: parseInt(this.__pt, 10)});
    this.startBeacon();
    this.__paused = false;
    this.__seeking = false;

    if (this.pauseTimer) clearTimeout(this.pauseTimer);
    delete this.pauseTimer;
    this._emitter.emit('play');
  }

  onLocalPlayerPlaying() {
    // console.log('PlayerApp: onLocalPlayerPlaying');

    // 自動再生エラーしてるのにイベントが発生する
    if (this.__autoplayError) return;

    this.seekToEnded = false;
    if (!this.__pFrom) {
      this.__pFrom = new Date().getTime();
    }
    if (!this.__paused) {
      this.__paused = false;
    }
    this.__seeking = false;
    this._emitter.emit('playing');
  }

  onLocalPlayerEnded() {
    // console.log('PlayerApp: onLocalPlayerEnded');
    if (this.playReload) {
      this.localPlayer().currentTime(0);
      return;
    }
    this.stopBeacon();
    if (typeof this.__pFrom !== 'undefined') {
      let addPt = parseInt(((new Date().getTime()) - this.__pFrom) / 1000, 10);
      if (!isNaN(addPt)) {
        this.__pt += addPt;
      }
      this.trackEvent('ended', {pt: parseInt(this.__pt, 10)});
    } else {
      this.trackEvent('ended');
    }
    this.endedTimer = setTimeout(() => {
      if (this.endedTimer) clearTimeout(this.endedTimer);
      delete this.endedTimer;
    }, 100);
    this._emitter.emit('ended');
    this.__pFrom = undefined;
    this._overSeeking = false;
  }

  onLocalPlayerError() {
    // console.log('PlayerApp: onLocalPlayerError');
  }

  onLocalPlayerDurationchange() {
    // console.log('PlayerApp: onLocalPlayerDurationchange');
    if (this.localPlayer().duration() === Infinity) {
      // ライブの時にこのタイミングでplayさせると再生されない
      // チャンネルの時にこのタイミングでplayさせないと再生されない
      // 送らせて処理させる
      setTimeout(() => {　this.triggerLoadEvent(); }, 1000);
    }
    this._emitter.emit('durationchange', this.localPlayer().duration());
  }

  onLocalPlayerRatechange() {
    // console.log('PlayerApp: onLocalPlayerRatechange');
    const playbackRate = this.localPlayer().playbackRate();
    if (playbackRate === 0) return;
    this._emitter.emit('ratechange', playbackRate);
  }

  onLocalPlayerVolumeChange() {
    // console.log('PlayerApp: onLocalPlayerVolumeChange', this.localPlayer().volume());
    this._emitter.emit('volumechange', this.localPlayer().volume());
  }

  onLocalPlayerStalled() {
    // console.log('PlayerApp: onLocalPlayerStalled');
    this._emitter.emit('stalled');
  }

  onLocalPlayerEmptied() {
    // console.log('PlayerApp: onLocalPlayerEmptied');
    this._emitter.emit('emptied');
  }

  onLocalPlayerSuspend() {
    // console.log('PlayerApp: onLocalPlayerSuspend');
    this._emitter.emit('suspend');
  }

  onLocalPlayerWaiting() {
    // console.log('PlayerApp: onLocalPlayerWaiting');
    this._emitter.emit('waiting');
  }

  onLocalPlayerBuffering() {
    // console.log('PlayerApp: onLocalPlayerBuffering');
    this._emitter.emit('buffering');
  }

  onLocalPlayerBuffered() {
    // console.log('PlayerApp: onLocalPlayerBuffered');
    this._emitter.emit('buffered');
  }

  onLocalPlayerCanplay() {
    // console.log('PlayerApp: onLocalPlayerCanplay');
    this.triggerLoadEvent();
    this.canplayed(true);
    this._emitter.emit('canplay');
  }

  onLocalPlayerSeeked() {
    // console.log('PlayerApp: onLocalPlayerSeeked');
    if (this.endedTimer) return;

    this.seekToEnded = true;
    this.__seeking = false;
    this._emitter.emit('seeked');

    if (this._overSeeking) {
      this.onLocalPlayerEnded();
    }
  }

  onLocalPlayerSeeking() {
    // console.log('PlayerApp: onLocalPlayerSeeking');
    if (this.endedTimer) return;

    this.__seeking = true;
    this._emitter.emit('seeking');
  }

  onLocalPlayerCanplaythrough() {
    // console.log('PlayerApp: onLocalPlayerCanplaythrough');
    this._emitter.emit('canplaythrough');
  }

  onLocalPlayerFirstplay() {
    // console.log('PlayerApp: onLocalPlayerFirstplay');
    this._emitter.emit('firstplay');
  }

  onLocalPlayerTimeupdate() {
    // console.log('PlayerApp: onLocalPlayerTimeupdate');
    if (this.endedTimer) return;

    this.__currentPlayerTime = this.localPlayer().currentTime();

    // 5秒以上の再生の場合
    if (!this.__watcing && this.__currentPlayerTime >= 5) {
      this.__watcing = true;
      this.trackEvent('watching');
    }

    // asset:60792515 endedが通知されない
    // playbackRateが0に変更されTimeupdateが一度通知されて止まる
    if (this.sendEndedTimer) {
      clearTimeout(this.sendEndedTimer);
      delete this.sendEndedTimer;
    }
    this.sendEndedTimer = setTimeout(() => {
      if (!this.localPlayer()) return;
      const duration = this.localPlayer().duration();
      if (duration !== Infinity && duration - this.__currentPlayerTime < 1 && this.localPlayer().playbackRate() == 0　&& !this.localPlayer().paused()) {
        this._emitter.emit('ended');
      }
    }, 1000);

    this.milestone();
    this._emitter.emit('timeupdate', this.localPlayer().currentTime());
  }

  onLocalPlayerSrcFallback() {
    // console.log('PlayerApp: onLocalPlayerSrcFallback');
    this._emitter.emit('src-fallback');
  }

  onLocalPlayerPlayTerminated() {
    // console.log('PlayerApp: onLocalPlayerPlayTerminated');
    // this._emitter.emit('play-terminated');
    this.__autoplayError = true;
    this._emitter.emit('autoPlayError');
  }

  triggerLoadEvent() {
    // console.log('PlayerApp: triggerLoadEvent', this.loaded());
    this.loaded(true);
  }

  localPlayer() {
    if (this._localPlayer) {
      if (typeof this._localPlayer.loadMedia === 'function') {
        return this._localPlayer;
      } else if (this._localPlayer.player_) {
        return this._localPlayer;
      }
    }
    return null;
  }

  castPlayer() {
    return null;
  }

  retrievePlayerId(userId) {
    const exec = () => {
      return new Promise((resolve, reject) => {
        var session = cast.framework.CastContext.getInstance().getCurrentSession();
        if (!session) return reject('no session');

        let mediaSession = session.getMediaSession();
        if (!_.get(mediaSession, 'media')) return reject('no media');

        if (userId !== _.get(mediaSession.media, 'playbackData.userId')) return resolve();

        if (!_.get(mediaSession.media, 'playbackData.playerId')) return resolve();

        this._playerId = mediaSession.media.playbackData.playerId;
        resolve();
      });
    }
    return promiseRetry(exec);
  }



  addEmitterListener(eventName, fn) {
    if (eventName == 'sendEvent') {
      this.removeEmitterListener(eventName);
    }
    this._listeners.push({
      subscription: this._emitter.addListener(eventName, fn),
      eventName: eventName,
      fn: fn,
    });
  }

  removeEmitterListener(eventName, fn) {
    let index = _.findIndex(this._listeners, (listener) => {
      if (fn) {
        return listener.eventName == eventName && listener.fn == fn;
      } else {
        return listener.eventName == eventName;
      }
    });
    if (index !== -1) {
      this._listeners[index].subscription.remove();
      this._listeners.splice(index, 1);
    }
  }

  // playerApp interface

  on(eventName, fn) {
    //console.log('PlayerApp: on', eventName);
    if (this.localPlayer()) {
      this._localPlayer.on(eventName, fn);
    }
    this.addEmitterListener(eventName, fn);
  }

  off(eventName, fn) {
    //console.log('PlayerApp: off', eventName);
    if (this.localPlayer()) {
      this._localPlayer.off(eventName, fn);
    }
    this.removeEmitterListener(eventName, fn);
  }

  seekable() {
    // console.log('seekable');
    if (this.localPlayer()) return this._localPlayer.seekable();
    return false;
  };

  currentTime(currentTime) {
    // console.log('currentTime', currentTime);

    // duration以上を指定された時は擬似的にendedさせる
    if (this._overSeeking) return this.duration();
    if (currentTime !== undefined) {
      const duration = this.duration();
      if (duration !== Infinity && duration <= currentTime) {
        this._overSeeking = true;
        if (this.__paused) {
          if (!this.__seeking) this.onLocalPlayerEnded();
        } else {
          this.pause();
        }
        return;
      }
    }

    if (this.localPlayer()) return this._localPlayer.currentTime(currentTime);
    return 0;
  };

  duration() {
    // console.log('duration', this.playerHandler.getMediaDuration());
    if (this.localPlayer()) return this._localPlayer.duration();
    return 0;
  };

  volume(volume) {
    // console.log('volume', volume);
    if (this.localPlayer()) return this._localPlayer.volume(volume);
    return 0;
  };

  muted(muted) {
    // console.log('muted', muted);
    if (this.localPlayer()) return this._localPlayer.muted(muted);
    return null;
  };

  playbackRate(rate) {
    // console.log('playbackRate', rate);
    if (this.localPlayer()) return this._localPlayer.playbackRate(rate);
    return null;
  };

  buffered() {
    // console.log('buffered');
    if (this.localPlayer()) return this._localPlayer.buffered();
    return null;
  };

  play() {
    // console.log('PlayerApp: play');
    if (!this.started() && !this.__paused) {
      this.__autoplay = true;
      if (!this.__autoplayError) return null;
    }
    if (this.localPlayer()) return this._localPlayer.play();
    return null;
  }

  pause() {
    // console.log('PlayerApp: pause');
    this.__autoplay = false;
    if (this.localPlayer()) return this._localPlayer.pause();
    return null;
  }

  paused() {
    if (this.localPlayer() && typeof this._localPlayer.paused === 'function') return this._localPlayer.paused();
    return this.__paused;
  }

  qualityLevels() {
    //console.log('qualityLevels');
    if (this.localPlayer()) {
      if (typeof this._localPlayer.qualityLevels === 'function') {
        return this._localPlayer.qualityLevels();
      }
    }
    return null;
  };

  reload() {
    if (this.localPlayer()) {
      this.playReload = true;
      if (this.seekToEnded) {
        this.localPlayer().currentTime(this.localPlayer().currentTime() - 1);
      } else {
        this.localPlayer().currentTime(0);
      }
    }
    return null;
  }

  playbackData() {
    return null;
  }

  settings(settings) {
    return null;
  }

  seekPreview() {
    return null;
  }

  playbackRule() {
    return null;
  }

  milestone() {
    //console.log('PlayerApp: milestone');
    // milestoneの計算を行う
    if (this.duration() && this.duration() !== Infinity) {
      const milestone = this.__currentPlayerTime/this.duration();
      if (this.__milestone < milestone) {
        if (milestone >= 0.25 && this.__milestone < 0.25) {
          this.__milestone = 0.25;
          this.trackEvent('milestone', {'percentage': '25'})
        }
        if (milestone >= 0.5 && this.__milestone < 0.5) {
          this.__milestone = 0.5;
          this.trackEvent('milestone', {'percentage': '50'})
        }
        if (milestone >= 0.75 && this.__milestone < 0.75) {
          this.__milestone = 0.75;
          this.trackEvent('milestone', {'percentage': '75'})
        }
      }
    }
  }

  startBeacon() {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: beacon::start');

    if (this.__beaconIntervalId) return;
    this.__beaconIntervalId = setInterval(() => {
      this._emitter.emit('beacon');
    }, BEACON_SPAN * 1000);
  }

  stopBeacon() {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: beacon::stop');

    if (this.__beaconIntervalId) {
      clearInterval(this.__beaconIntervalId);
      this.__beaconIntervalId = undefined;
    }
  }

  trackEvent(event, params = {}, async) {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: trackEvent', event, params);

    if (!event) return;

    // TODO: この値を毎回そもそも送る必要性があるのか疑問ではある
    params = Object.assign({
      event,
    }, params);

    this._emitter.emit('sendEvent', '/track', params, async);
  }

  beacon(analytics, params = {}, async) {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: beacon', params);

    if (analytics.psid) {
      params.psid = analytics.psid;
    }

    this._emitter.emit('sendEvent', '/beacon', params, async);
  }

  sendEvent(path, params = {}, browserInfo, profile, isFullScreen, playbackRate, activeVideo, trackService, autoPlay, subtitleSelection, async) {
    if (!this.localPlayer() || !activeVideo) return;
    //console.log('PlayerApp: sendEvent', path, params);

    const metadata = _.get(activeVideo, 'metadata', {});
    const analytics = _.get(activeVideo, 'analytics', {});
    const stockParams = _.get(activeVideo, 'stockParams', {});
    const session = _.get(activeVideo, 'session', {});
    const mediaValues = _.get(activeVideo, 'mediaValues', {});

    /**
     * device_type
     * 001=PC（Windows）
     * 002=PC（Mac）
     * 003=PC（その他）
     * 004=iOS
     * 005=Android
     * 006=Fire TVstick
     */
    let device_type = '001';
    if (browserInfo.isMac) {
      device_type == '002';
    } else if (browserInfo.isWindows7 || browserInfo.isWindows8 || browserInfo.isWindows8_1 || browserInfo.isWindows10) {
      device_type == '001';
    } else if (browserInfo.isIOS) {
      device_type == '004';
    } else if (browserInfo.isAndroid) {
      device_type == '005';
    } else if (!browserInfo.isMobile) {
      device_type == '001';
    }
    params = Object.assign({
      resolution: mediaValues.resolution ? mediaValues.resolution : window.screen.availWidth + 'x' + window.screen.availHeight,
      screen_mode: (isFullScreen) ? '002' : '001',
      video_type: '001', // video_type: isAdPlaying ? '002' : '001',
      kids_flag: (profile && profile.isKids) ? '1' : '0',
      device_type,
      delivery_type: metadata.type === 'linear_channel_meta' ? '004' : '001', // 001=vod,002=dic,003=live,004=linear
      ts: Math.round((new Date()).getTime() / 1000),
      playback_rate: playbackRate,
    }, analytics, browserInfo.deviceInfo, params)

    delete params.dsid;
    // 上書き

    params.apv = _.get(this._options, "config.appVersion");
    params.pv = '2.0.0';
    params.pn = 'H Player';
    params.uid = stockParams.uid;
    params.puid = stockParams.puid;
    params.psid = session.id;
    params.autoplay = autoPlay;
    if (2 == activeVideo.ovpId) {
      params.ct = 1;
    } else if (3 == activeVideo.ovpId) {
      params.ct = 2;
    }
    params.meta_id = metadata.idInSchema;
    if (metadata.seriesId) params.series_id = metadata.seriesId;

    const pg_t_idx = [
      'full_episode',
      'making',
      'trailer',
      'web_original',
      'interview',
      'clip',
      'special',
      'short_film',
      'advertisement',
      'highlight',
      'music_video',
    ].indexOf(_.get(metadata, 'programmingType.value'));
    params.pg_t = pg_t_idx + 1;
    if (mediaValues.endingStartPosition) params.ecp = mediaValues.endingStartPosition;
      params.cc = false;
    params.cl = 'none';
    for (var i=0; i<subtitleSelection.length; i++) {
      if (subtitleSelection[i] == 'ja_cc') params.cc = true;
      if (subtitleSelection[i] == 'ja') params.cl = 'ja';
      if (subtitleSelection[i] == 'en') params.cl = 'en';
    }

    let currentTime = -1;
    if (this.localPlayer()) {
      params.cdn = this.localPlayer().currentSource().cdn || 'akamai';
      params.playback_rate = this.localPlayer().playbackRate();
      params.duration = this.localPlayer().duration();
      if (params.delivery_type !== '004' && params.duration === Infinity) {
        params.delivery_type = '003';
      }
      // iPhoneの場合はplayerのfullscreenを参照
      if (browserInfo.isIOS && !browserInfo.isIPad) {
        params.screen_mode = this.localPlayer().isFullscreen() ? '002' : '001';
      }

      // TODO: エラーしたらcurrentTimeを取得することはできないため、この値は外部から取得すべきなきがする
      //
      if (typeof params.playback_rate === 'undefined') {
        currentTime = parseInt(this.localPlayer().currentTime(), 10);
        if (isNaN(currentTime)) {
          currentTime = -1;
        }
      }
    }
    if (currentTime === -1) {
      currentTime = this.__currentPlayerTime;
    }
    params.s = parseInt(currentTime, 10);
    this.send(`${trackService.protocol}://${trackService.hostname}${path}`, params, async);
  }

  send(host, params, async = true) {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: send', host, params);

    const query = Object.keys(params).map(function(key){
      return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    // sendBeaconが利用できるときは利用する
    let status = false;
    if (navigator.sendBeacon) {
      try {
        const blob = new Blob([query], { type: 'application/x-www-form-urlencoded' });
        status = navigator.sendBeacon(host, blob);
      } catch (e) {
        // safariでunload時のsendBeaconはエラーする為xhrで処理する
      }
    }
    if (status) return;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${host}?${query}`, async);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
      }
    };
    xhr.send(null);
  };

  getPlaybackSessionId(sessionId, analytics, playContext) {
    if (sessionId) {
      return sessionId;
    } else if (analytics.psid) {
      return analytics.psid;
    } else if (playContext) {
      return playContext.get('session').id;
    }
    return null;
  }

  checkVideo(playbackService, authContext, playbackSessionId, handler) {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: checkVideo');

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status === 401 && xhr.status === 403) {
          window.location.reload();
          return;
        }
        if (handler) handler(xhr.response);
      }
    };

    xhr.open('POST', `${playbackService.protocol}://${playbackService.hostname}/session/check`, true);
    xhr.responseType = 'json';
    if (authContext) {
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
    }
    xhr.setRequestHeader('X-Playback-Session-Id', playbackSessionId);
    xhr.withCredentials = true;
    xhr.send();
  }

  dispose(playbackService, authContext, playbackSessionId, async) {
    // console.log('PlayerApp: dispose', playbackSessionId, async);

    this.stopBeacon();
    if (typeof this.__pFrom !== 'undefined') {
      let addPt = parseInt(((new Date().getTime()) - this.__pFrom) / 1000, 10);
      if (!isNaN(addPt)) {
        this.__pt += addPt;
      }
    }
    this.trackEvent('dispose', {pt: parseInt(this.__pt, 10)}, async);
    this.__pt = 0;

    this.sessionClose(playbackService, authContext, playbackSessionId, async);

    if (this.localPlayer()) {
      this._localPlayer.off('loadstart', this.onLocalPlayerLoadstart);
      this._localPlayer.off('progress', this.onLocalPlayerProgress);
      this._localPlayer.off('pause', this.onLocalPlayerPause);
      this._localPlayer.off('play', this.onLocalPlayerPlay);
      this._localPlayer.off('playing', this.onLocalPlayerPlaying);
      this._localPlayer.off('loadedmetadata', this.onLocalPlayerLoadedmetadata);
      this._localPlayer.off('ended', this.onLocalPlayerEnded);
      this._localPlayer.off('error', this.onLocalPlayerError);
      this._localPlayer.off('durationchange', this.onLocalPlayerDurationchange);
      this._localPlayer.off('ratechange', this.onLocalPlayerRatechange);
      this._localPlayer.off('volumechange', this.onLocalPlayerVolumeChange);
      this._localPlayer.off('stalled', this.onLocalPlayerStalled);
      this._localPlayer.off('emptied', this.onLocalPlayerEmptied);
      this._localPlayer.off('suspend', this.onLocalPlayerSuspend);
      this._localPlayer.off('waiting', this.onLocalPlayerWaiting);
      this._localPlayer.off('buffering', this.onLocalPlayerBuffering);
      this._localPlayer.off('buffered', this.onLocalPlayerBuffered);
      this._localPlayer.off('canplay', this.onLocalPlayerCanplay);
      this._localPlayer.off('seeked', this.onLocalPlayerSeeked);
      this._localPlayer.off('seeking', this.onLocalPlayerSeeking);
      this._localPlayer.off('canplaythrough', this.onLocalPlayerCanplaythrough);
      this._localPlayer.off('firstplay', this.onLocalPlayerFirstplay);
      this._localPlayer.off('loadeddata', this.onLocalPlayerLoadeddata);
      this._localPlayer.off('timeupdate', this.onLocalPlayerTimeupdate);
      this._localPlayer.off('src-fallback', this.onLocalPlayerSrcFallback);
      this._localPlayer.off('play-terminated', this.onLocalPlayerPlayTerminated);
    }
    this.loaded(false);
    this.started(false);
    this.canplayed(false);
    this.__autoplay = false;
    this.__currentPlayerTime = 0;
    this.__paused = true;
    this.__seeking = false;
    this.__pt = 0;
    this.__pFrom = undefined;
    this.__beaconIntervalId = undefined;
    this.__milestone = 0;
    this._overSeeking = false;
    this._localPlayer = null;
    delete this._localPlayer;
  }

  sessionClose(playbackService, authContext, playbackSessionId, async) {
    // console.log('PlayerApp: sessionClose');
    if (!playbackSessionId) return;

    const url = `${playbackService.protocol}://${playbackService.hostname}/session/close`;

    if (window.fetch) {
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Playback-Session-Id': playbackSessionId,
      };
      if (authContext){
        headers['Authorization'] = `Bearer ${authContext.token}`;
        headers['X-User-Id'] = authContext.id;
      }
      window.fetch(url, {
        method: "POST",
        headers: headers,
        keepalive: !async,
      }).then(response => {
        // console.log(response);
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      }).then(response => {
        // console.log(response);
      }).catch(e => {
        console.log(e);
      });
    } else {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function() {
        // console.log('sessionClose:xhr', xhr);
      };
      xhr.open('POST', url, async);
      if (authContext) {
        xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
        xhr.setRequestHeader('X-User-Id', authContext.id);
      }
      xhr.setRequestHeader('X-Playback-Session-Id', playbackSessionId);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.withCredentials = true;
      xhr.send();
    }
  }

  onetimeToken(tokenService, authContext) {
    //console.log('PlayerApp: onetimeToken');
    tokenService.pathname = _.join(_.concat(tokenService.path, 'onetime-token/publish'), '/');

    let headers = {};
    if (authContext) {
      headers['Authorization'] = `Bearer ${authContext.token}`;
      headers['X-Token-Id'] = authContext.id;
      headers['X-Session-Token'] = authContext.sessionToken;
    } else {
      return Promise.resolve();
    }

    return this._axios.formPost(url.format(tokenService), {}, {headers}).then((result) => {
      return _.get(result, 'data.onetime_token');
    });
  }

  setNextPlaying(value=true) {
    //console.log('PlayerApp: setNextPlaying');
    this.__nextPlaying = value;
    if (this.checkSleepTimer()) {
      this.resetSleepTimer();
    }
  }

  resetSleepTimer() {
    //console.log('PlayerApp: resetSleepTimer');
    this.__sleepTime = (new Date()).getTime();
  }

  checkSleepTimer() {
    //console.log('PlayerApp: checkSleepTimer');
    return (new Date()).getTime() - this.__sleepTime > 3 * 60 * 60 * 1000 // 3時間
    //return (new Date()).getTime() - this.__sleepTime > 3 * 1000 // 3時間
  }
}

export default PlayerApp;
