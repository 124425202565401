import _ from 'lodash';
import PathEvaluator from '../../../sketch-platform/utils/PathEvaluator';
import FalcorDataSource from 'falcor-http-datasource';
import namespace from '../../../common/namespace';
import initAppContext from '../initAppContext';
import history from '../../history';
import * as COOKIE from '../../../constants/cookie';

import { ReactClientRenderer } from '../../../sketch-platform/renderers';

window.app = window.app || {};
window.app.reactContext = window.app.reactContext || {};

window.onpageshow = function(event) {
};

const endpoint = '/pathEvaluator';
const falcorModel = new PathEvaluator({
  collectRatio: 0.75,
  source: new FalcorDataSource(endpoint, {
    headers: {
      'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
    },
  }),
  errorSelector(error) {
    console.error('errorSelector', error);
    error.$expires = -1000 * 60 * 2;
  },
});
falcorModel.setCache(window.app.falcorCache);

const model = _.cloneDeep(window.app.reactContext);
const container = document.getElementById('appMountPoint');
const ua = navigator.userAgent.toLowerCase();
const viewport = document.getElementById('viewport');
// ios13以降のipad判定のため
if (/macintosh/.test(ua) && 'ontouchend' in document) {
  viewport.setAttribute('content', 'width=980');
}

initAppContext(falcorModel, history, function(appContext) {
  new ReactClientRenderer(container, appContext).render((err, a) => {
    if (err) {
      console.error(err);
    }
  });
});
