import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import BackgroundImage from '../../../common/components/BackgroundImage';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import getImage from '../../../../common/getImage';
import TitleCard from './TitleCard';
import getBroadcasterData from '../../../../common/getBroadcasterData';

const PC_FEATURE_NUM = 3;
const SP_FEATURE_NUM = 2;

/**
 * トップページ特集棚出し
 */
class HomeSpecialContent extends Component {
  static propTypes = {};

  static defaultProps = {};

  static contextTypes = {
    getModelData: PropTypes.func,
    spMode: PropTypes.bool,
    history: PropTypes.object,
    models: PropTypes.object,
    company: PropTypes.bool,
    isEachCompany: PropTypes.bool,
    routeHandler: PropTypes.object,
    home: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dispose: null,
      fetchDataError: null,
      loading: false,
    };

    this.slicedFeatures = this.slicedFeatures.bind(this)
    this.getFeatureHeaderTitle = this.getFeatureHeaderTitle.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * 特集一覧のJSONから先頭3件を絞る
   */
  slicedFeatures(featureList) {
    const featureListArr = Object.keys(featureList).map((key) => {
      return featureList[key];
    });

    return _.slice(featureListArr, 0, 3);
  }

  /**
   * 特集ヘッダータイトル
   */
  getFeatureHeaderTitle() {
    const companyCode = _.get(this.props.routeHandler, 'params.companyCode');
    const isEachCompany = _.get(this.props.routeHandler, 'isEachCompany');
    if (isEachCompany && companyCode) {
      return _.get(getBroadcasterData(companyCode), 'label') + ' 特集';
    } else {
      return '特集';
    }
  }

  render() {
    if (_.isEmpty(_.get(this.props, 'featureList'))) {
      return null;
    }

    const featureList = _.omit(this.props.featureList, ['count', 'current_page']); // 特集リスト
    const companyCode = _.get(this.props.routeHandler, 'params.companyCode');
    const isEachCompany = _.get(this.props.routeHandler, 'isEachCompany');
    const detailTo = isEachCompany ? routes.featureDetailEachCompany : routes.featureDetailTrenta;
    const img_src = this.context.models.config.data.logoPath;

    // sliceFeatureList: モジュールに表示する先頭3件(sp2件)の特集
    const sliceFeatureList = this.slicedFeatures(featureList);

    //TRENTA総合トップ、TRENTA各社トップ, 各社UIの各社トップの特集棚出し
    const items = _.compact(_.map(sliceFeatureList, (itemData, index) => {
      if (!itemData || !itemData.feature_id) return;
      if (!itemData.id && itemData.tag_id) {
        itemData.id = itemData.tag_id;
      }
      if (_.get(itemData, 'title')) {
        _.set(itemData, 'value', itemData.title);
      }

      // 記事カード生成
      const itemCard = _.compact(_.map(itemData.article, (articleData, $index) => {
        if (!articleData || _.isEmpty(articleData)) return null;
        if (this.context.spMode) {
          if ($index < 2) {
            return <TitleCard itemData={articleData} default={true} key={`titlecard-${$index}`} />;
          }
          return null;
        } else {
          return <TitleCard itemData={articleData} default={true} key={`titlecard-${$index}`} />;
        }
      }));

      // 全ての記事へ遷移数情報生成
      const params = this.props.company ? (
        { companyCode: companyCode, tagName: itemData.value }
      ) : (
        { tagName: itemData.value }
      );

      return (
        <div className="specialContentItem" key={`home-special-content-${index}`}>
          <div className="specialContentItem-header">
            <div className="specialContentItem-header-left" key={`feature_notification_${index}`}>
              <BackgroundImage
                className="specialContentItem-header-img"
                backgroundImageSize="300x150"
                url={getImage({ itemData, special: true }, this.context)}
                altText={_.get(itemData, 'value')}
              />
              <h2 className="specialContentItem-header-title">{itemData.value}</h2>
            </div>
            <div className="specialContentItem-header-right">
              <MainViewLink to={detailTo} params={params}>
                すべての記事
                <i className="fa-article-title-arrow"></i>
              </MainViewLink>
            </div>
          </div>
          <div className="specialContentItem-body">
            <div className="news-list-items">{itemCard}</div>
          </div>
        </div>
      );
    }));

    // 一覧画面へ遷移するパラメータ生成
    const featureAllViewParams = this.props.company ? (
      {
        to: routes.featureEachCompany,
        params: {
          companyCode: _.get(this.props.routeHandler.params, 'companyCode')
        }
      }
    ) : (
      {
        to: routes.featureTrenta,
        params: {}
      }
    );

    return (
      <div className="specialContent" key="home-special-content">
        <React.Fragment>
          <div className="specialContent-header">
            <div className="specialContent-title">
              {!isEachCompany && (
                <span className="specialContent-title-logo">
                  <img src={img_src} />
                </span>
              )}
              <span className="specialContent-title-text">{this.getFeatureHeaderTitle()}</span>
            </div>
            <MainViewLink
              className="specialContent-to-list-button button secondary medium radius bold"
              to={featureAllViewParams.to}
              params={featureAllViewParams.params}
            >
              一覧へ
            </MainViewLink>
          </div>
          <div className="specialContent-body">{items}</div>
        </React.Fragment>
      </div>
    );
  }
}

const root = withLayoutContext(HomeSpecialContent);
root.getPaths = HomeSpecialContent.getPaths;
export default root;
