import React from 'react';
const ModalContext = React.createContext();
ModalContext.displayName = 'ModalContext';
export default ModalContext;
export const withModalContext = (Component) => {
  const withModalContext = React.forwardRef((props, ref) => {
    return (
      <ModalContext.Consumer>
        {(context) => React.createElement(Component, { ref, ...props, ...context })}
      </ModalContext.Consumer>
    );
  });
  return withModalContext;
};
