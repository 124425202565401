import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withModalContext } from '../../../common/context/ModalContext';

class LivePlayer extends Component {
  static propTypes = {
  };

  static contextTypes = {
    spMode: PropTypes.bool,
    models: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.streaksPlayerVersion = _.get(context, 'models.config.data.streaksPlayerVersion', "2.5.2");
  }

  componentDidMount() {
    const self = this;
    const styles = document.getElementsByTagName('link');
    const styleFirst = _.get(styles, '0');
    if (_.findIndex(styles, { href: `https://players.streaks.jp/sdk/v${this.streaksPlayerVersion}/streaksplayer.min.css` }) < 0) {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `https://players.streaks.jp/sdk/v${this.streaksPlayerVersion}/streaksplayer.min.css`;
      styleFirst.parentNode.insertBefore(style, styleFirst);
    }
    let scripts = document.getElementsByTagName("script");
    const scriptFirst = _.get(scripts, '0');
    if (_.findIndex(scripts, { src: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js' }) < 0) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://imasdk.googleapis.com/js/sdkloader/ima3.js';
      scriptFirst.parentNode.insertBefore(script, scriptFirst);
    }
    if (_.findIndex(scripts, { src: `https://players.streaks.jp/sdk/v${this.streaksPlayerVersion}/streaksplayer.min.js` }) < 0) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://players.streaks.jp/sdk/v${this.streaksPlayerVersion}/streaksplayer.min.js`;
      scriptFirst.parentNode.insertBefore(script, scriptFirst);
      script.onload = () => {
        if (typeof streaksplayer !== 'function') return;
        self.play();
      };
    } else {
      if (typeof streaksplayer !== 'function') return;
      self.play();
    }
    this._isMounted = true;
  }

  componentDidUpdate() {}
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    this._isMounted = false;
  }

  play() {
    // レンダリング時に、<.media>内のDOMをリセット
    document.querySelector('.media').innerHTML = '';

    const video = document.createElement('video');
    video.setAttribute('id','live');
    video.setAttribute('controls', 'enabled');
    video.setAttribute('preload', 'none');
    if (this.context.spMode) {
      video.setAttribute('class', 'spMode');
    }

    // <.media> のDOMを修正
    document.querySelector('.media').appendChild(video);
    const options = {
      autoplay: true,
      preload: 'none',
      chromeless: false,
      advertising: {
        requestMode: 'ondemand',
        clickableRegExp: /.*/,
      },
      customQualityLevels: [
        {
          label: '自動',
          selected: true,
        },
        {
          label: '高画質',
          selected: false,
        },
        {
          label: '低画質',
          selected: false,
          maximumHeight: 479,
        },
      ],
      manifest: {
        withCredentials: true
      },
      streaming: {
        withCredentials: true
      },
    };
    // console.log("streaks player options: ", options);
    this.player = streaksplayer(video, options, () => {});
    const self = this;
    this.player.ready(() => {
      if (_.get(self.props, 'handlePlayerReady')) {
        self.props.handlePlayerReady(
          self.player || _.get(self.coreRef, 'current.player'),
          self.props,
          true,
        );
      }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    return (
      <div className="media"></div>
    );
  }
}

export default withModalContext(LivePlayer);
