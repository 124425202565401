/* eslint-disable import/prefer-default-export */

class GenericError {
  constructor(err) {
    if (err) {
      if (typeof err === 'string') {
        this.message = err;
      } else if (err instanceof Error) {
        this.message = err.message;
        this.stack = err.stack;
        this.originalError = err;
      }
    } else {
      this.title = '予期せぬエラーが発生しました';
    }
  }
}

const FORBIDDEN = 'ERROR_FORBIDDEN';
class ForbiddenError extends GenericError {
  constructor(err) {
    super(err);
    this.status = 403;
    this.message = FORBIDDEN;
  }
}

const PROFILE_FORBIDDEN = 'ERROR_PROFILE_FORBIDDEN';
class ProfileForbiddenError extends GenericError {
  constructor(err) {
    super(err);
    this.status = 403;
    this.message = PROFILE_FORBIDDEN;
  }
}

const NOTFOUND = 'ERROR_NOTFOUND';
class NotfoundError extends GenericError {
  constructor(err) {
    super(err);
    this.status = 404;
    this.message = NOTFOUND;
  }
}

const BADREQUEST = 'ERROR_BADREQUEST';
class BadRequestError extends GenericError {
  constructor(err) {
    super(err);
    this.message = '';
  }
};

const OS_NOT_SUPPORT = 'ERROR_OS_NOT_SUPPORT';
class OsNotSupportError extends GenericError {
  constructor(err) {
    super(err);
    this.status = 200;
    this.message = OS_NOT_SUPPORT;
  }
}

const BROWSER_NOT_SUPPORT = 'ERROR_BROWSER_NOT_SUPPORT';
class BrowserNotSupportError extends GenericError {
  constructor(err) {
    super(err);
    this.status = 401;
    this.message = BROWSER_NOT_SUPPORT;
  }
}

const TIMEOUT = 'ERROR_TIMEOUT';
class TimeoutError extends GenericError {
  constructor(err) {
    super(err);
    this.title = 'アクセスを処理できませんでした';
    this.messages = ['現在アクセスが集中している為、繋がりにくくなっています。', 'しばらくたってから再度アクセスをお願いします。'];
  }
};

const CONGESTIONERROR = 'ERROR_CONGESTION';
class CongestionError extends GenericError {
  constructor(err) {
    super(err);
    this.status = 500;
    this.message = CONGESTIONERROR;
  }
}

const UNAUTHORIZED = 'ERROR_UNAUTHORIZED';
class UnauthorizedError extends GenericError {
  constructor(err) {
    super(err);
    this.status = 401;
    this.message = UNAUTHORIZED;
  }
}

const RATING_UNMATCH = 'RATING_UNMATCH';
const EVENT_SUSPEND = 'EVENT_SUSPEND';
const LICENSE_INVALID = 'LICENSE_INVALID';
const PLAYBACK_SESSION_ID_INVALID = 'PLAYBACK_SESSION_ID_INVALID';
const LOCAL_TIME_INVALID = 'LOCAL_TIME_INVALID';
const NOT_ACCOUNT_OWNER = 'NOT_ACCOUNT_OWNER';
const META_NOT_VIEWABLE = 'META_NOT_VIEWABLE';
const AVAILABLE_ENTITLEMENT = 'AVAILABLE_ENTITLEMENT';
const CONTENTS_PROTECTION = 'CONTENTS_PROTECTION';

const MAILADDRESS_REGISTERED = 'MAILADDRESS_REGISTERED';
class MailaddressRegisteredError extends GenericError {
  constructor(err) {
    super(err);
    this.status = 400;
    this.message = 'すでに使用されているメールアドレスです。';
  }
}

// eSuiteサーバでエラーが発生した時
const PAYMENT_SERVER_ERROR = 'PAYMENT_SERVER_ERROR';
class PaymentServerError extends GenericError {
  constructor(err) {
    super(err);
    this.status = 200;
    this.message = PAYMENT_SERVER_ERROR;
  }
}

const AGREEMENT_REQUIRED = 'AGREEMENT_REQUIRED';
const ALREADY_AGREED = 'ALREADY_AGREED';
const REAUTH_SERVER_ERROR = 'REAUTH_SERVER_ERROR';
const VIEWING_DEVICE_LIMIT = 'VIEWING_DEVICE_LIMIT';
const META_NOT_DELIVERY_STARTED = 'META_NOT_DELIVERY_STARTED';
const META_DELIVERY_ENDED = 'META_DELIVERY_ENDED';
const SLEEP_TIMER = 'SLEEP_TIMER';
const LINEAR_SLEEP_TIMER = 'LINEAR_SLEEP_TIMER';
const PLAYER_TIMEOUT = 'PLAYER_TIMEOUT';
const EXERCISE_ALERT = 'EXERCISE_ALERT';
const SESSION_OPEN = 'SESSION_OPEN';

export {
  GenericError,
  FORBIDDEN,
  ForbiddenError,
  PROFILE_FORBIDDEN,
  ProfileForbiddenError,
  NOTFOUND,
  NotfoundError,
  UNAUTHORIZED,
  UnauthorizedError,
  BADREQUEST,
  BadRequestError,
  OS_NOT_SUPPORT,
  OsNotSupportError,
  BROWSER_NOT_SUPPORT,
  BrowserNotSupportError,
  TIMEOUT,
  TimeoutError,
  CONGESTIONERROR,
  CongestionError,
  RATING_UNMATCH,
  EVENT_SUSPEND,
  LICENSE_INVALID,
  PLAYBACK_SESSION_ID_INVALID,
  LOCAL_TIME_INVALID,
  PAYMENT_SERVER_ERROR,
  PaymentServerError,
  META_NOT_VIEWABLE,
  AVAILABLE_ENTITLEMENT,
  CONTENTS_PROTECTION,
  MAILADDRESS_REGISTERED,
  MailaddressRegisteredError,
  AGREEMENT_REQUIRED,
  REAUTH_SERVER_ERROR,
  VIEWING_DEVICE_LIMIT,
  META_NOT_DELIVERY_STARTED,
  META_DELIVERY_ENDED,
  SLEEP_TIMER,
  LINEAR_SLEEP_TIMER,
  PLAYER_TIMEOUT,
  EXERCISE_ALERT,
  SESSION_OPEN,
};
