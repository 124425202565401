import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import PagerBlock from './PagerBlock';
import routes from '../../../common/routes';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import { getCardResizeImage, isResizable } from '../../../../common/preloadImages';
import getImage from '../../../../common/getImage';

const PERPAGE = 10;
class InformationContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    topText: PropTypes.string,
  };

  static getPaths = function (models, options, props) {
    let paths = [];
    const indexes = function (models, options, props) {
      const page = _.get(props, 'routeHandler.query.p');
      let from, to;
      if (page) {
        from = page * PERPAGE - PERPAGE;
        to = page * PERPAGE - 1;
      }
      return {
        from: from || (props && props.fromNum) || 0,
        to: to
          ? to
          : props && props.toNum
          ? props.toNum
          : options && options.numTitles
          ? options.numTitles + (props.fromNum || 0)
          : PERPAGE - 1,
      };
    };

    const rootPath = this.getRootPath(models, options, props);

    paths = paths.concat([
      [indexes(models, options, props), ['id', 'title', 'thumbnail', 'publish_start_date']],
      ['count'],
      ['current_page'],
    ]);

    return _.map(paths, (path) => rootPath.concat(path));
  };

  static getRootPath = function (models, options, props) {
    let companyCode;
    if (_.get(props.routeHandler, 'params.companyCode')) {
      companyCode = _.get(props.routeHandler, 'params.companyCode');
    } else {
      companyCode = 'ntv';
    }
    return ['notification', companyCode, 'お知らせ'];
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  constructor(props, context) {
    super(props, context);

    this.onClickPager = this.onClickPager.bind(this);

    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.totalCount = this.model.getSync(rootPath.concat('count'));
    this.lastPage = this.totalCount && Math.ceil(this.totalCount / PERPAGE);
    this.companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');
    this.items = this.model.getSync(rootPath);
    if (this.items && Object.keys(this.items) && Object.keys(this.items).length) {
      const firstIndex = Object.keys(this.items)[0];
      this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
    }

    if (this.companyCode === 'ntv') {
      this.to = routes.info;
      this.params = {};
    } else {
      this.to = routes.infoEachCompany;
      this.params = { companyCode: this.companyCode };
    }

    this.state = {
      dispose: null,
      fetchDataError: null,
      loading: false,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (_.get(this.props, 'routeHandler.query.p') && this.currentPage !== this.props.routeHandler.query.p) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    if (_.get(props, 'routeHandler.query.p') != state.currentPage) {
      return {
        currentPage: _.get(props, 'routeHandler.query.p', 1),
      };
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.currentPage != this.state.currentPage) {
      this.fetchData(this.props, this.context, { scrollToTop: true });
    }
  }

  onClickPager(page) {
    if (page) {
      const props = Object.assign({}, this.props, {
        fromNum: page * PERPAGE - PERPAGE,
        toNum: page * PERPAGE - 1,
      });
      this.fetchData(props, this.context, { scrollToTop: true });
    }
  }

  fetchData(props = this.props, context = this.context, options = {}) {
    const paths = this.constructor.getPaths(context.models, {}, props);

    const evaluator = this.model.fetch(paths);
    evaluator
      .then((res) => {
        this.items = _.get(res, `json.notification.${this.companyCode}.お知らせ`, {});
        if (this.items) {
          const firstIndex = Object.keys(this.items)[0];
          this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
        }

        const newState = {
          fetchDataError: null,
          dispose: null,
          loading: false,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);

        if (_.get(options, 'scrollToTop')) {
          window.scrollTo({ top: 0 });
        }
      })
      .catch((e) => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    let spBlock;
    let pcBlock;

    let cards = [];
    let items = this.items;

    if (items && _.size(items)) {
      // preload imageの設定
      const articleImages = _.compact(
        _.map(
          _.filter(items, e => e && e.id),
          itemData => {
            const src = _.get(itemData, 'thumbnail');
            return isResizable(src) ?
              getCardResizeImage({ itemData, information: true }, this.context) :
              src || getImage(itemData, this.context);
          },
        ),
      );
      if (!_.isEmpty(articleImages)) this.props.setPreloadImages(articleImages);
    };

    cards = _.compact(
      _.map(items || [], (itemData, index) => {
        if (!itemData || !itemData.id) return;
        return (
          <TitleCard
            // key={`information_card_${itemData.id}`}
            key={`information_card_${index}`}
            itemData={itemData}
            // information={!this.context.spMode}
            information={true}
          />
        );
      }),
    );

    spBlock = (
      <div className="informationContent">
        <div className="informationContent-header">
          <h2 className="informationContent-header-title">運営からのお知らせ</h2>
        </div>
        <div className="informationContent-body">{!_.isEmpty(cards) ? cards : <p>該当のコンテンツはありません</p>}</div>
        {this.lastPage > 1 && (
          <div className="btn-block">
            <PagerBlock currentPage={this.currentPage} lastPage={this.lastPage} to={this.to} params={this.params} />
          </div>
        )}
      </div>
    );

    pcBlock = (
      <div className="informationContent">
        <div className="informationContent-header">
          <h2 className="informationContent-header-title">運営からのお知らせ</h2>
        </div>
        <div className="informationContent-body">{!_.isEmpty(cards) ? cards : <p>該当のコンテンツはありません</p>}</div>
        {this.lastPage > 1 && (
          <div className="btn-block">
            <PagerBlock
              currentPage={this.currentPage}
              lastPage={this.lastPage}
              keyPrefix={`information_pager_`}
              to={this.to}
              params={this.params}
              showBtn={4}
            />
          </div>
        )}
      </div>
    );
    const topText = _.get(this.context, 'topText');
    const canonical = `/info${this.currentPage > 1 ? `?p=${this.currentPage}` : ''}`;
    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const bloadcaster = getBroadcasterData(company)
    const pageIndex = this.currentPage > 1 ? `${this.currentPage}ページ目 ` : '' ;
    const titleTag = `${pageIndex}運営からのお知らせ｜${company === 'n24' ? topText : bloadcaster.nnn}`
    const description = company === 'n24'
      ? '日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」からのお知らせ一覧です。'
      : `${bloadcaster.label}のニュースサイト「${bloadcaster.nnn}」からのお知らせ一覧です。`;

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(titleTag) });
            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });
            metas.push({ property: 'og:type', content: 'article' });

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={titleTag} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        {this.context.spMode ? spBlock : pcBlock}
        <Analytics pageTitle={titleTag} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(InformationContent);
root.getPaths = InformationContent.getPaths;
root.getRootPath = InformationContent.getRootPath;
root.getPrefetchPaths = InformationContent.getPrefetchPaths;
export default root;
