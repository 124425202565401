import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class InternalServerError extends Component {

  static bundleName = 'errors/internal_server_error';

  static contextTypes = {
    models: PropTypes.object,
    getModelData: PropTypes.func,
  };

  render() {
    return(
      <div className="contentsWrapper error-page 500-error">
        <div className="error-block">
          <h2 className="error-title">エラーが発生しました</h2>
          <p className="error-text">誠に恐れ入りますが、しばらくしてから再度アクセスをお試しください</p>
          <a href="/" className="error-link">日テレNEWS NNNトップへ</a>
        </div>
      </div>
    );
  }
}
