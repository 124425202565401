import dayjs from 'dayjs';
import _get from 'lodash/get';

export default function getDate(itemData) {
    // 公開開始日が存在する場合  
    const publishStartDate = _get(itemData, 'publish_start_date');
    if (publishStartDate && new Date(publishStartDate).getTime() !== 0) return publishStartDate;
    
    // 公開開始日が存在せず、オリジナル初回配信日時が存在する場合  
    const originalFirstPublishDate = _get(itemData, 'original_first_publish_date');
    const unixTimeOriginalFirstPublishDate = Date.parse(originalFirstPublishDate);
    if (originalFirstPublishDate && new Date(originalFirstPublishDate).getTime() !== 0) return originalFirstPublishDate;
    return _get(itemData, 'first_publish_date') || _get(itemData, 'created_at');
}
