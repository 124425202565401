export default class StaticJsonApp {
  constructor(models, opts = {}) {
    this.__models = models;
    this.__opts = opts;
  }

  fetchNews() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          try {
            let response = xhr.response;
            if (typeof response === 'string') {
              response = JSON.parse(response);
            }
            if (xhr.status !== 200) {
              return reject(response);
            }
            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      xhr.open('get', '/api/staticJson/news');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.send();
    });
  }
  fetchLive() {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          try {
            let response = xhr.response;
            if (typeof response === 'string') {
              response = JSON.parse(response);
            }
            if (xhr.status !== 200) {
              return reject(response);
            }
            return resolve(response);
          } catch (e) {
            return reject(e);
          }
        }
      };
      xhr.open('get', '/api/staticJson/live');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.send();
    });
  }
}
