import { configureStore } from '@reduxjs/toolkit';
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: 'noop',
  initialState: {
    value: 0,
  },
  reducers: {
    noop: (state) => 0,
  }
});

const store = configureStore({
  reducer: slice,
});

export default store;