import Slider from '@logiclogic/react-slider';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PresTrackedContainer from '../../../common/components/PresTrackedContainer';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import SpSlider from './SpSlider';

const getWidth = function (elem) {
  if (!elem) {
    return 0;
  }
  return elem.getBoundingClientRect().width || elem.offsetWidth || 0;
};

const getHeight = function (elem) {
  if (!elem) {
    return 0;
  }
  return elem.getBoundingClientRect().height || elem.offsetHeight || 0;
};

const SliderItemRows = (props) => {
  return (
    <div className={`slider-item-rows size-${props.size}`} key={props.key}>
      {React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, {
          // sliderItemId: props.sliderItemId,
          // itemTabbable: props.itemTabbable,
          size: props.size,
        });
      })}
    </div>
  );
};

class Row extends Component {
  static propTypes = {
    containsDoubleWides: PropTypes.bool,
    enablePushOut: PropTypes.bool,
    enablePeek: PropTypes.bool,
    enablePaginationIndicator: PropTypes.bool,
    enableLooping: PropTypes.bool,
    initialSlide: PropTypes.number,
    enableArrows: PropTypes.bool,
    handleSliderMove: PropTypes.func,
    isMyListRow: PropTypes.bool,
    orderedItemList: PropTypes.array,
    numOfItemRows: PropTypes.number.isRequired,
    rowNum: PropTypes.number,
    totalItems: PropTypes.number,
    loadingComponent: PropTypes.object,
    loading: PropTypes.bool,
    columnsInRow: PropTypes.number,
    viewColumnsInRow: PropTypes.number,
    centerMode: PropTypes.bool,
    autoplay: PropTypes.bool,
    autoplaySpeed: PropTypes.number,
    enablePeekBeforeMoveOnce: PropTypes.bool,
  };

  static defaultProps = {
    enablePaginationIndicator: true,
    enableLooping: true,
    initialSlide: 0,
    containsDoubleWides: false,
    handleSliderMove: null,
    isMyListRow: false,
    numOfItemRows: 1,
    orderedItemList: null,
    rowNum: null,
    totalItems: null,
    loadingComponent: null,
    autoplay: false,
    autoplaySpeed: 5000,
    enablePeekBeforeMoveOnce: false,
  };

  static contextTypes = {
    getModelData: PropTypes.func,
    // columnsInRow: PropTypes.number,
    currentPage: PropTypes.string,
    history: PropTypes.object,
    isInitialRender: PropTypes.bool,
    isKidsPage: PropTypes.bool,
    isOverlayPage: PropTypes.bool,
    isRtl: PropTypes.bool,
    isTallRow: PropTypes.bool,
    listContext: PropTypes.string,
    disableSpMode: PropTypes.bool,
    listType: PropTypes.string,
    loggingApp: PropTypes.object,
    canvasId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    profileManager: PropTypes.string,
    requestId: PropTypes.string,
    routeHandler: PropTypes.object,
    trackId: PropTypes.number,
    focusManager: PropTypes.object,
    spMode: PropTypes.bool,
  };

  static childContextTypes = {
    rowNum: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);
    this.handleSliderMove = this.handleSliderMove.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleRowFocus = this.handleRowFocus.bind(this);

    this.sliderRef = React.createRef();
    this.rowRef = React.createRef();

    this.state = {
      isPopOpen: false,
      lowestVisibleItemIndex: 0,
    };
  }

  componentDidMount() {
    if (this.context.focusManager) {
      const el = ReactDOM.findDOMNode(this.rowRef.current);
      if (el) {
        this.context.focusManager.collectFocasableElements(el);
      }
    }
  }

  componentWillUnmount() {
    if (this.popAnimationId) {
      clearTimeout(this.popAnimationId);
      delete this.popAnimationId;
    }
  }

  getChildContext() {
    return { rowNum: this.props.rowNum };
  }

  handleMouseEnter(e) {
    this.isMouse = true;
  }

  handleMouseLeave(e) {
    this.isMouse = false;
  }

  handleMouseMove(e) {
    this.isMouse = true;
    this.setState({ keyboardFocus: false });
  }

  handleRowFocus(event) {
    if (this.isMouse) return;
    if (event.target.classList.contains('sliderRefocus')) {
      this.setState({ keyboardFocus: true });
    }
  }

  handleKeyDown(event) {
    // Tab
    if (event.which === 9 && !event.shiftKey) {
      if (event.target.classList.contains('handleNext')) {
        event.target.tabIndex = -1;
        this.setState({ keyboardFocus: false });
      } else {
        const links = event.currentTarget.querySelectorAll('div[tabindex="0"]');
        const index = _.findIndex(links, (link) => {
          return link.id === event.target.id;
        });
        if (links.length == index + 1) {
          const arrow = event.currentTarget.querySelector('.handleNext');
          if (arrow) {
            arrow.tabIndex = 0;
          } else {
            this.setState({ keyboardFocus: false });
          }
        }
      }

      // Tab with Shift
    } else if (event.which === 9 && event.shiftKey) {
      if (event.target.classList.contains('handlePrev')) {
        event.target.tabIndex = -1;
        this.setState({ keyboardFocus: false });
      } else {
        const links = event.currentTarget.querySelectorAll('div[tabindex="0"]');
        const index = _.findIndex(links, (link) => {
          return link.id === event.target.id;
        });
        if (index === 0) {
          const arrow = event.currentTarget.querySelector('.handlePrev');
          if (arrow) {
            arrow.tabIndex = 0;
          } else {
            this.setState({ keyboardFocus: false });
          }
        }
      }
    }
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');

    const settings = {
      initialSlide: this.props.initialSlide,
      swipe: false,
      enableLooping: this.props.enableLooping,
      enablePeek: this.props.enablePeek,
      enablePaginationIndicator: this.props.enablePaginationIndicator,
      enableSwipe: browserInfo.isIOS || browserInfo.isAndroid,
      centerMode: this.props.centerMode,
      autoplay: this.props.autoplay,
      autoplaySpeed: this.props.autoplaySpeed,
      enablePeekBeforeMoveOnce: this.props.enablePeekBeforeMoveOnce,
      enableArrows: this.props.enableArrows,
    };

    let totalItems = Math.ceil(this.props.totalItems / this.props.numOfItemRows);
    if (
      this.props.columnsInRow > 2 &&
      this.props.numOfItemRows !== 1 &&
      (this.props.catchLayout === 'image' || this.props.catchLayout === 'image_text')
    ) {
      const rowCount = this.props.catchLayout === 'image' ? 3 : 5;
      totalItems = Math.ceil((this.props.totalItems + rowCount) / this.props.numOfItemRows);
    }

    let columnsInRow = this.props.columnsInRow;

    if (this.props.viewColumnsInRow) {
      columnsInRow = this.props.viewColumnsInRow;
    }

    return (
      <div className="row" data-column-items={columnsInRow} ref={this.rowRef}>
        <PresTrackedContainer>
          {this.columnsInRow !== 0 ? (
            <div
              className={classnames('row-content', {
                'slider-hover-trigger-layer': columnsInRow !== 1,
                'keyboard-focus': this.state.keyboardFocus,
              })}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseMove={this.handleMouseMove}
              onFocus={this.handleRowFocus}
              onKeyDown={this.handleKeyDown}
            >
              <Slider
                ref={this.sliderRef}
                itemsInRow={columnsInRow}
                totalItems={totalItems}
                onSliderMove={this.handleSliderMove}
                loadingComponent={this.props.loadingComponent}
                arrowTabbable={false}
                {...settings}
              >
                {this.renderChildren()}
              </Slider>
            </div>
          ) : (
            this.renderChildren()
          )}
        </PresTrackedContainer>
      </div>
    );
  }

  renderChildren() {
    let rowCount = 0;
    let childFrom;
    let childTo;

    const children = React.Children.map(this.props.children, (child, index) => {
      const rankNum = this.context.listContext === 'ranking' ? child.props.rankNum || index : index;
      let sliderRefocus = true;
      if (
        this.props.columnsInRow > 2 &&
        (this.props.catchLayout === 'image' || this.props.catchLayout === 'image_text')
      ) {
        sliderRefocus = childFrom <= index && index <= childTo;
      }
      return React.cloneElement(child, {});
    });
    if (this.props.numOfItemRows === 1) {
      return children;
    }

    const browserInfo = this.context.getModelData('browserInfo');

    let _children = _.chunk(children, this.props.numOfItemRows);

    if (
      this.props.columnsInRow > 2 &&
      (this.props.catchLayout === 'image' || this.props.catchLayout === 'image_text')
    ) {
      if (this.props.catchLayout === 'image') {
        _children = _.flatten([_.first(children), null, _.chunk(_.tail(children), this.props.numOfItemRows)]);
      } else {
        _children = _.flatten([_.first(children), null, null, _.chunk(_.tail(children), this.props.numOfItemRows)]);
      }
    }
    return _.map(_children, (chunkedChildren, index) => {
      const props = {
        size: `1x${this.props.numOfItemRows}`,
        isHide: chunkedChildren === null,
      };
      if (
        this.props.columnsInRow > 2 &&
        (this.props.catchLayout === 'image' || this.props.catchLayout === 'image_text')
      ) {
        if (index === 0) {
          props.size = `${rowCount}x${this.props.numOfItemRows}`;
        } else if (index === 1 && rowCount === 3) {
          props.size = `${rowCount - 1}x${this.props.numOfItemRows}`;
        }
      }
      return (
        <SliderItemRows key={`slider_item_row_${this.props.rowNum}-${index}`} {...props}>
          {chunkedChildren}
        </SliderItemRows>
      );
    });
  }

  handleSliderMove(nextState, actionType) {
    this.setState({ lowestVisibleItemIndex: nextState.lowestVisibleItemIndex });
    if (this.props.handleSliderMove) {
      this.props.handleSliderMove(nextState, actionType);
    }
  }
}

const root = withLayoutContext(Row);

export default root;
