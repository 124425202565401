import PropTypes from 'prop-types';
import TitleCard from './TitleCard';
import React from 'react';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import _ from 'lodash';
import categoryCode2Name from '../../../../common/categoryCode2Name';

/**
 * 注目の記事（TRENTAトップ）
 */
class FeatureArticles extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    sideContent: PropTypes.bool,
    getAttention: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    // BlueroseLayoutで取得した注目の記事
    this.items = this.props.getAttention;

    this.state = {
      categoryQuery: categoryCode2Name(
        _.get(this.context, 'routeHandler.params.categoryCode'),
        _.get(context, 'models.config.data'),
      ),
      categoryCode: _.get(this.context, 'routeHandler.params.categoryCode', 'all'),
      dispose: null,
      fetchDataError: null,
      loading: false,
      rankingList: true,
      ctx: this.context,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.items) {
      this.fetchData();
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
  }

  static getDerivedStateFromProps(props, state) {
    const categoryCode = _.get(props, 'categoryCode');
    if (categoryCode) {
      return {
        categoryQuery: categoryCode2Name(_.get(props, 'categoryCode'), _.get(state.ctx, 'models.config.data')),
        categoryCode: _.get(props, 'categoryCode', 'all')
      };
    }
    return null;
  }

  render() {
    if(this.state.categoryCode === "sponsored") return; 
    let cards = [];
    cards = _.compact(
      _.map(this.items, (itemData, index) => {
        if (!itemData || !itemData.source_systems) return;
        if (index < 6) {
          return (
            <TitleCard
              key={`ntv_attention_card_${index}`}
              itemData={itemData}
              default={true}
              isNtvHeadline={true}
            />
          )
        }
      })
    );

    const Block = (
      <div className="featureArticles">
        <div className="featureArticles-title">注目の記事</div>
        <div className="featureArticles-content">
          <div className="news-list-items grid">{cards}</div>
        </div>
      </div>
    );

    return Block;
  }
}
const root = withLayoutContext(FeatureArticles);
root.getPaths = FeatureArticles.getPaths;
root.getRootPath = FeatureArticles.getRootPath;
export default root;
