import window from 'global/window';
import hoistStatics from 'hoist-non-react-statics';
import invariant from 'invariant';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { branch, compose, renderNothing, setDisplayName } from 'recompose';
import ModalContent from '../../bluerose/components/modal/ModalContent';
import ModalContext from '../context/ModalContext';

export default function layoutContext(WrappedComponent) {
  invariant(
    typeof WrappedComponent === 'function',
    `You must pass a component to the function returned by ` +
      `layoutContext. Instead received ${JSON.stringify(WrappedComponent)}`,
  );
  const ModalContentEnhance = compose(
    setDisplayName('ModalContentEnhance'),
    branch(({ showModal }) => !showModal, renderNothing),
  )(ModalContent);

  class LayoutContext extends Component {
    static __meta__ = WrappedComponent.__meta__;

    static childContextTypes = {
      offline: PropTypes.bool,
      popType: PropTypes.string,
      models: PropTypes.object,
      node: PropTypes.object,
    };

    static contextTypes = {
      getCookieDough: PropTypes.func,
      getModelData: PropTypes.func,
      store: PropTypes.object,
    };

    constructor(props, context) {
      super(props, context);

      this.state = {
        offline:
          typeof window === 'undefined' || typeof window.navigator === 'undefined' ? false : !window.navigator.onLine,
        showModal: false,
        showDialog: false,
      };
      this._handleUpdateOnlineStatus = this._handleUpdateOnlineStatus.bind(this);
      this.handleHistoryChange = this.handleHistoryChange.bind(this);

      const { store: propsStore } = props;
      this.store = propsStore || context.store;

      this.showModal = this.showModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.showDialog = this.showDialog.bind(this);
      this.closeDialog = this.closeDialog.bind(this);
      this.handleHistoryChange = this.handleHistoryChange.bind(this);
    }

    getChildContext() {
      const model = this.props.model;
      return {
        models: model.models || this.context.models || {},
        node: model.node,
        offline: this.state.offline,
        popType: 'bounce',
      };
    }

    componentDidMount() {
      this._isMounted = true;
      window.addEventListener('online', this._handleUpdateOnlineStatus);
      window.addEventListener('offline', this._handleUpdateOnlineStatus);

      this.props.history.listen(this.handleHistoryChange);
    }

    componentWillUnmount() {
      this._isMounted = false;

      window.removeEventListener('online', this._handleUpdateOnlineStatus);
      window.removeEventListener('offline', this._handleUpdateOnlineStatus);
    }

    _handleUpdateOnlineStatus() {
      if (this._isMounted) {
        if (window.navigator.onLine) {
        }
        this.setState({ offline: !window.navigator.onLine });
      }
    }

    showModal(component, argument) {
      this.setState({
        showModal: true,
        modalComponent: component,
        argument,
      });
    }

    closeModal() {
      this.setState({ showModal: null });
    }

    showDialog(component, argument) {
      this.setState({
        showDialog: true,
        dialogComponent: component,
        argument,
      });
    }

    closeDialog() {
      this.setState({ showDialog: null });
    }

    handleHistoryChange() {
      if (this.state.showModal) {
        this.closeModal();
      }
      if (this.state.showDialog) {
        this.closeDialog();
      }
    }

    render() {
      const props = Object.assign({ model: _.omit(this.props.model, 'models') }, _.omit(this.props, 'model'));
      let classes = '';
      if (this.state.argument && this.state.argument.classes) classes = this.state.argument.classes;

      const modalContextValue = {
        showModal: this.showModal,
        closeModal: this.closeModal,
        showDialog: this.showDialog,
        closeDialog: this.closeDialog,
        showModalState: this.state.showModal,
      };
      return (
        <ModalContext.Provider value={modalContextValue}>
          {React.createElement(WrappedComponent, props)}
          <ModalContentEnhance
            showModal={this.state.showModal}
            onClose={(e) => this.setState({ showModal: false })}
            children={this.state.modalComponent}
            classes={classes}
          />
        </ModalContext.Provider>
      );
    }
  }

  LayoutContext.displayName = 'LayoutContext';

  return hoistStatics(LayoutContext, WrappedComponent);
}
