import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _head from 'lodash/head';
import _toPairs from 'lodash/toPairs';
import _find from 'lodash/find';
import _slice from 'lodash/slice';
import _concat from 'lodash/concat';

/**
 * 記事検索で使用する記事IDを記事IDとprefixをオブジェクトで返却する
 * @param _id 偽装prefix付きの記事ID
 * @return prefix: projectIdをprefix文字列として返却 id: 記事ID
 */
export function getSearchArticleIdWithPrefix(_id, providerPrefix = {}) {
  if (_isEmpty(providerPrefix)) {
    console.log('providerPrefixの設定がありません。');
    return null;
  }

  // idが34文字であれば偽装prefixがあると認定してidとprefixを分離する
  if (_id.length === 34) {
    // console.log("IDが34文字です。prefixを判定します。", _id)
    const camouflagePrefix = _id.substring(0, 2);
    const id = _id.substring(2, _id.length);
    // console.log("separated id and camouflagePrefix: ", id, camouflagePrefix)

    // 取得した偽装prefixをprojectIdに置き換える
    const prefix = GetProjectIdFronCamouflagePrefix(camouflagePrefix, providerPrefix);

    // エラーハンドリング
    if (!prefix || !id) {
      console.error("Couldn't find project id or id", { prefix, id });
      return {};
    }

    const returnObj = {
      prefix,
      id,
    };

    // console.log("search target id and prefix: ", JSON.stringify(returnObj))

    return returnObj;
  }

  // console.log("IDが34文字ではないためそのまま返却します。", _id)
  return {
    prefix: null,
    id: _id,
  };
}

/**
 * source_systemsから出稿元のProjectIdを取得する
 */
export function GetSourceProjectId(item) {
  if (!item) return;

  const sourceSystems = _get(item, 'source_systems');

  if (_isEmpty(sourceSystems)) return null;

  if (_isArray(sourceSystems)) {
    const sourceProject = _head(sourceSystems);
    return _get(sourceProject, 'project', '');
  }

  return _get(sourceSystems, 'project', '');
}

/**
 * source_systemsから出稿元のidを取得する
 */
export function GetSourceId(item) {
  if (!item) return;

  const sourceSystems = _get(item, 'source_systems');

  if (_isEmpty(sourceSystems)) return null;

  if (_isArray(sourceSystems)) {
    const sourceProject = _head(sourceSystems);
    return _get(sourceProject, 'id', '');
  }

  return _get(sourceSystems, 'id', '');
}

/**
 * projectIdから偽装prefixを返却する
 */
export function GetCamouflagePrefix(projectId, providerPrefix = {}) {
  return Object.keys(providerPrefix).find((key) => providerPrefix[key] === projectId);
}

/**
 * 偽装prefixからprojectIdを返却する
 */
export function GetProjectIdFronCamouflagePrefix(camPrefix, providerPrefix = {}) {
  return _find(providerPrefix, (val, key) => key === camPrefix);
}

/**
 * 渡されたitemからProjectIdとidを取り出し、偽装prefixに差し替えてURLの記事IDとして渡す
 */
export function GetArticleLinkId(item, providerPrefix = {}) {
  if (!item || _isEmpty(item)) return null;

  const projectId = GetSourceProjectId(item);
  const id = GetSourceId(item);

  // console.log("GetArticleLinkId projectId id: ", projectId, id);

  if (!id || !projectId) return null;

  if (projectId === 'n24' || (0 < id.length && id.length < 32)) {
    // projectIdがn24もしくはidが32文字未満なら記事IDをそのまま返す
    // 32文字未満のIDは旧環境からの移行記事のため日テレしか持っていない
    return id;
  }

  // projectIdがn24以外であれば偽装prefixを先頭につけて返す
  const camouflagePrefix = GetCamouflagePrefix(projectId, providerPrefix);
  // console.log("取得されたcamouflagePrefix: ", camouflagePrefix)
  if (!camouflagePrefix) {
    console.warn('not found camouflagePrefix, return original id. id-> ', id);
    return id;
  }

  const camouflageArticleId = `${camouflagePrefix}${id}`;

  // console.log("camouflage article id: ", camouflageArticleId)
  return camouflageArticleId;
}

/**
 * 記事IDからTRENTA UI NTV or NNN(各社)記事であるかの判定
 * @param articleId URL上の記事ID
 * @param providerPrefix env設定ファイルから記事データ取得
 */
export function isTrentaNTVArticle(articleId, providerPrefix) {
  const prefixAndArticle = getSearchArticleIdWithPrefix(articleId, providerPrefix);
  return _get(prefixAndArticle, 'prefix', null) === null;
}
