import _get from 'lodash/get';
import getImage from './getImage';
import { isResizable } from './preloadImages'; 
import url from 'url';

export default function getResizedImage(props, context, imageWidth = null) {
    if (!props || !context) return null;
    const image = getImage(props, context);
    const urlParse = url.parse(image, true);
    if (!_get(urlParse, 'query.w') && isResizable(image) && imageWidth) {
        urlParse.query.w = imageWidth;
    }
    return url.format(urlParse);
}
