/**
 * Author記事一覧(タグ記事一覧ベース)
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import Share from './Share';
import PagerBlock from './PagerBlock';
import nl2br from "../../../../common/react-nl2br";
import str2a from "../../../../common/react-str2a";
import removeTag from '../../../../common/react-removeTag';
import * as ERROR from "../../../../constants/error";
import routes from '../../../common/routes';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
import TaboolaWidget from '../../../common/components/TaboolaWidget';

const PERPAGE = 12;
class AuthorContent extends React.Component {
  // static contextTypes = {
  //   routeHandler: PropTypes.object,
  //   spMode: PropTypes.bool,
  //   models: PropTypes.object,
  //   isSpaError: PropTypes.func,
  // };

  // static getPaths = function(models, options, props) {
  //   let paths = [];
  //   const indexes = function (models, options, props) {
  //     const page = _.get(props, 'routeHandler.query.p');
  //     let from, to;
  //     if (page) {
  //       from = (page * PERPAGE) - PERPAGE;
  //       to = (page * PERPAGE) - 1;
  //     }
  //     return {
  //       from: from || (props && props.fromNum) || 0,
  //       to: to ? to
  //           : props && props.toNum
  //           ? props.toNum
  //           : options && options.numTitles
  //           ? options.numTitles + (props.fromNum || 0)
  //           : PERPAGE - 1,
  //     };
  //   };

  //   paths = paths.concat([
  //     ['tag', 'byTitle', props.tagName, 'hash'],
  //     ['article', 'byTag', props.tagName, 'hashTag', indexes(models, options, props), [
  //       'id',
  //       'title',
  //       'thumbnail',
  //       'publish_start_date',
  //       'first_publish_date',
  //       'created_at'
  //     ]],
  //     ['article', 'byTag', props.tagName, 'hashTag', 'count'],
  //     ['article', 'byTag', props.tagName, 'hashTag', 'current_page'],
  //   ]);

  //   return paths;
  // };

  // static getPrefetchPaths = function(models, options, props) {
  //   return this.getPaths(models, options, props);
  // };

  // static afterPrefetch = function(models, options, props) {
  //   return (prefetchResult) => {
  //     const rootPath = ['tag', 'byTitle', props.tagName, 'hash'];
  //     const item = _.get(prefetchResult, ['json'].concat(rootPath));
  //     if (!item) return { error: ERROR.NOTFOUND };
  //     return null;
  //   };
  // };

  // constructor(props, context) {
  //   super(props, context);

  //   this.onClickPager = this.onClickPager.bind(this);

  //   const rootPath = ['article', 'byTag', props.tagName, 'hashTag'];
  //   this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
  //   this.totalCount = this.model.getSync(rootPath.concat('count'));
  //   this.lastPage = this.totalCount && Math.ceil(this.totalCount / PERPAGE);
  //   this.items = this.model.getSync(rootPath);
  //   if (this.items && Object.keys(this.items) && Object.keys(this.items).length) {
  //     const firstIndex = Object.keys(this.items)[0];
  //     this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
  //   }
  //   this.detail = this.model.getSync(['tag', 'byTitle', props.tagName, 'hash']);
  //   this.to = routes.tag;
  //   this.params = { tagName: props.tagName };

  //   this.state = {
  //     dispose: null,
  //     fetchDataError: null,
  //     loading: false,
  //   };
  // }

  // componentDidMount() {
  //   this._isMounted = true;
  //   if (_.get(this.props, 'routeHandler.query.p') && this.currentPage !== this.props.routeHandler.query.p) {
  //     this.fetchData();
  //   }
  // }

  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  // componentWillReceiveProps(nextProps, nextContext) {
  //   if (this.currentPage != _.get(nextProps, 'routeHandler.query.p')) {
  //     this.fetchData(nextProps, this.context, {scrollToTop:true});
  //   }
  // }

  // onClickPager(page) {
  //   if (page) {
  //     const props = Object.assign({}, this.props, {
  //       fromNum: page * PERPAGE - PERPAGE,
  //       toNum: page * PERPAGE - 1,
  //     });
  //     this.fetchData(props, this.context, {scrollToTop:true});
  //   }
  // }

  // fetchData(props = this.props, context = this.context, options = {}) {
  //   const paths = this.constructor.getPaths(context.models, {}, props);

  //   const evaluator = this.model.fetch(paths);
  //   evaluator
  //     .then(res => {
  //       if (_.get(this.detail, 'title')) {
  //         _.set(this.detail, 'value', this.detail.title);
  //       }
  //       this.items = _.get(res, ['json', 'article', 'byTag', _.get(this.detail, 'value'), 'hashTag'], {});
  //       if (this.items) {
  //         const firstIndex = Object.keys(this.items)[0];
  //         this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
  //       }

  //       const newState = {
  //         fetchDataError: null,
  //         dispose: null,
  //         loading: false,
  //       };

  //       if (this._isMounted) this.setState(newState);
  //       else Object.assign(this.state, newState);

  //       if (_.get(options, 'scrollToTop')) {
  //         window.scrollTo({top:0});
  //       }
  //     })
  //     .catch(e => {
  //       const newState = {
  //         fetchDataError: e,
  //         fetchingMoreRows: undefined,
  //         dispose: null,
  //       };
  //       delete this.state[JSON.stringify(paths)];
  //       if (this._isMounted) this.setState(newState);
  //       else Object.assign(this.state, newState);
  //     });
  // }

  // render() {
  //   let spBlock;
  //   let pcBlock;

  //   if (!this.detail) {
  //     if (_.get(this.context, 'isSpaError')) {
  //       this.context.isSpaError();
  //     }
  //     throw new NotFoundError();
  //   }

  //   let cards = [];
  //   let items = this.items;
  //   if (items && _.size(items)) {
  //     items = _.slice(_.values(items), 0, PERPAGE);
  //   }

  //   cards = _.compact(
  //     _.map(items || [], (itemData, index) => {
  //       if (!itemData || !itemData.source_systems) return;
  //       return (
  //         <TitleCard
  //           key={`tag_detail_list_card_${index}_${itemData.source_systems.id}`}
  //           itemData={itemData}
  //           default={true}
  //         />
  //       );
  //     }),
  //   );

  //   const domain = _.get(this.context, 'models.hosts.data.domain_url');
  //   const pageUrl = _.get(this.context, 'models.hosts.data.domain_url') + _.get(this.context, 'routeHandler.url');

  //   if (_.get(this.detail, 'title')) {
  //     _.set(this.detail, 'value', this.detail.title);
  //   }
  //   if (_.get(this.detail, 'content')) {
  //     _.set(this.detail, 'custom_data.content', this.detail.content);
  //   }
  //   spBlock= (
  //     <React.Fragment>
  //       <div className="contents-title tag-title">{_.get(this.detail, 'value')}</div>
  //       {!_.isEmpty(_.get(this.detail, 'custom_data.content')) &&
  //         <p className="contents-text tag-contents-text">{str2a(nl2br(_.get(this.detail, 'custom_data.content', '')))}</p>
  //       }
  //       <div className="title-card-wrapper">
  //         {_.isEmpty(cards) ? (
  //           "該当コンテンツが存在しません。"
  //         ):(cards)}
  //         {!_.isEmpty(cards) ? (
              // <div key={`tag-ad-taboola-${Date.now()}`}>
              //   <TaboolaWidget target="below1" />
              // </div>
  //         ) : null}
  //       </div>
  //       <div className="btn-block">
  //         {this.lastPage > 1 && (
  //           <PagerBlock
  //             currentPage={this.currentPage}
  //             lastPage={this.lastPage}
  //             keyPrefix={`tag_list_pager_`}
  //             to={this.to}
  //             params={this.params}
  //           />
  //         )}
  //       </div>
  //       <Share title={_.get(this.detail, 'value')} routeHandler={this.props.routeHandler} />
  //     </React.Fragment>
  //   );

  //   pcBlock= (
  //     <React.Fragment>
  //       <div className="contents-title tag-title">{_.get(this.detail, 'value')}</div>
  //       {!_.isEmpty(_.get(this.detail, 'custom_data.content')) &&
  //         <p className="contents-text tag-contents-text">{str2a(nl2br(_.get(this.detail, 'custom_data.content', '')))}</p>
  //       }
  //       {_.isEmpty(cards) ? <div className="empty-contents">該当コンテンツが存在しません。</div>
  //       : <div className="title-card-wrapper">
  //           {cards}
  //         </div>
  //       }
  //       <div className="btn-block">
  //         {this.lastPage > 1 && (
  //           <PagerBlock
  //             currentPage={this.currentPage}
  //             lastPage={this.lastPage}
  //             keyPrefix={`tag_list_pager_`}
  //             to={this.to}
  //             params={this.params}
  //           />
  //         )}
  //       </div>
  //       <Share title={_.get(this.detail, 'value')} routeHandler={this.props.routeHandler} />
  //     </React.Fragment>
  //   );

  //   const ogTitle = `「${_.get(this.detail, 'value')}」のニュース ${this.currentPage > 1 ? `${this.currentPage}ページ目` : ''}｜日テレNEWS NNN`;

  //   const canonical = `/tag/${_.get(this.detail, 'value')}${this.currentPage > 1 ? `?p=${this.currentPage}` : ''}`;

  //   return (
  //     <React.Fragment>
  //       <HtmlContext.Consumer>
  //         {({ shortTitle }) => {
  //           const metas = [];

  //           metas.push({ property: 'og:title', content: shortTitle(ogTitle) });
  //           metas.push({ property: 'og:type', content: 'article' });
  //           metas.push({ name: 'description', content: `「${_.get(this.detail, 'value')}」のタグが付いているニュース。日本テレビのニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。` });
  //           metas.push({ property: 'og:description', content: `「${_.get(this.detail, 'value')}」のタグが付いているニュース。日本テレビのニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。` });

  //           return <Helmet
  //             title={ogTitle}
  //             meta={metas}
  //           />;
  //         }}
  //       </HtmlContext.Consumer>
  //       {this.context.spMode ? spBlock: pcBlock}
  //       <Analytics pageTitle={ogTitle} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
  //     </React.Fragment>
  //   );
  // }

  render() {
    return (
      <h1>Author記事一覧</h1>
    );
  }
}
const root = withLayoutContext(AuthorContent);
// root.getPaths = TagContent.getPaths;
// root.getPrefetchPaths = TagContent.getPrefetchPaths;
// root.afterPrefetch = TagContent.afterPrefetch;
export default root;
