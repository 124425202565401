import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import nl2br from '../../../../common/react-nl2br';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import Share from './Share';
import PagerBlock from './PagerBlock';
import getImage from '../../../../common/getImage';
import getResizedImage from '../../../../common/getResizedImage';
import * as ERROR from '../../../../constants/error';
import routes from '../../../common/routes';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
import str2a from '../../../../common/react-str2a';
import removeTag from '../../../../common/react-removeTag';
import url from 'url';
import { getCardResizeImage ,featureMainImage } from '../../../../common/preloadImages';
import BackgroundImage from '../../../common/components/BackgroundImage';
import getBroadcasterData from '../../../../common/getBroadcasterData';

const PERPAGE = 12;
class SpecialContentDetail extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    handleSpecialWideImgPath: PropTypes.func,
    models: PropTypes.object,
    isSpaError: PropTypes.func,
    topText: PropTypes.string,
  };

  static getPaths = function(models, options, props) {
    let paths = [];
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');
    const indexes = function(models, options, props) {
      const page = _.get(props, 'routeHandler.query.p');
      let from, to;
      if (page) {
        from = page * PERPAGE - PERPAGE;
        to = page * PERPAGE - 1;
      }
      return {
        from: from || (props && props.fromNum) || 0,
        to: to
          ? to
          : props && props.toNum
          ? props.toNum
          : options && options.numTitles
          ? options.numTitles + (props.fromNum || 0)
          : PERPAGE - 1,
      };
    };

    paths = paths.concat([
      ['feature', companyCode, props.tagName],
      ['article', 'byTag', companyCode, props.tagName, 'features.title', indexes(models, options, props), [
        'id',
        'title',
        'thumbnail',
        'publish_start_date',
        'first_publish_date',
        'created_at'
      ]],
      ['article', 'byTag', companyCode, props.tagName, 'features.title', 'count'],
      ['article', 'byTag', companyCode, props.tagName, 'features.title', 'current_page'],
    ]);

    return paths;
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function(models, options, props) {
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv')
    return prefetchResult => {
      const rootPath = ['feature', companyCode, props.tagName];
      const item = _.get(prefetchResult, ['json'].concat(rootPath));
      if (!item) return { error: ERROR.NOTFOUND };
      return null;
    };
  };

  constructor(props, context) {
    super(props, context);
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv')

    this.onClickPager = this.onClickPager.bind(this);
    this.buildTitleCards = this.buildTitleCards.bind(this);

    const rootPath = ['article', 'byTag', companyCode, props.tagName, 'features.title'];
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.totalCount = this.model.getSync(rootPath.concat('count'));
    this.lastPage = this.totalCount && Math.ceil(this.totalCount / PERPAGE);
    this.items = this.model.getSync(rootPath);
    if (this.items && Object.keys(this.items) && Object.keys(this.items).length) {
      const firstIndex = Object.keys(this.items)[0];
      this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
    }
    this.detail = this.model.getSync(['feature', companyCode, props.tagName]);

    if (companyCode === 'ntv') {
      this.to = routes.featureDetailTrenta;
      this.params = { tagName: props.tagName };
    } else {
      this.to = routes.featureDetailEachCompany;
      this.params = { tagName: props.tagName, companyCode: companyCode};
    }

    this.state = {
      dispose: null,
      fetchDataError: null,
      loading: false,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (_.get(this.props, 'routeHandler.query.p') && this.currentPage !== this.props.routeHandler.query.p) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    if (_.get(props, 'routeHandler.query.p') != state.currentPage) {
      return {
        currentPage: _.get(props, 'routeHandler.query.p', 1),
      };
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.currentPage != this.state.currentPage) {
      this.fetchData(this.props, this.context, { scrollToTop: true });
    }
  }
  onClickPager(page) {
    if (page) {
      const props = Object.assign({}, this.props, {
        fromNum: page * PERPAGE - PERPAGE,
        toNum: page * PERPAGE - 1,
      });
      this.fetchData(props, this.context, { scrollToTop: true });
    }
  }

  fetchData(props = this.props, context = this.context, options = {}) {
    const paths = this.constructor.getPaths(context.models, {}, props);
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv')


    const evaluator = this.model.fetch(paths);
    evaluator
      .then(res => {
        if (_.get(this.detail, 'title')) {
          _.set(this.detail, 'value', this.detail.title);
        }
        this.items = _.get(res, ['json', 'article', 'byTag', companyCode,  _.get(this.detail, 'value'), 'features.title'], {});
        if (this.items) {
          const firstIndex = Object.keys(this.items)[0];
          this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
        }

        const newState = {
          fetchDataError: null,
          dispose: null,
          loading: false,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);

        if (_.get(options, 'scrollToTop')) {
          window.scrollTo({ top: 0 });
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  /**
   * TitleCardの生成
   */
  buildTitleCards(items) {
    return _.compact(
      _.map(items || [], (itemData, index) => {
        if (!itemData || !itemData.source_systems) return;
        return (
          <TitleCard
            key={`special_detail_card_${index}_${itemData.source_systems.id}`}
            itemData={itemData}
            default={true}
          />
        );
      }),
    );
  }

  render() {
    if (!this.detail) {
      if (_.get(this.context, 'isSpaError')) {
        this.context.isSpaError();
      }
      throw new NotFoundError();
    }

    let items = this.items;
    if (items && _.size(items)) {
      items = _.slice(_.values(items), 0, PERPAGE);

      // preload imageの設定
      const articleImages = _.compact(
        _.map(
          _.filter(items, e => e && e.source_systems),
          itemData => getCardResizeImage({ itemData }, this.context),
        ),
      );
      if (!_.isEmpty(articleImages)) this.props.setPreloadImages(articleImages);
    }

    const cards = this.buildTitleCards(items);

    const domain = _.get(this.context, 'models.hosts.data.domain_url');
    const pageUrl = _.get(this.context, 'models.hosts.data.domain_url') + _.get(this.context, 'routeHandler.url');

    if (_.get(this.detail, 'title')) {
      _.set(this.detail, 'value', this.detail.title);
    }
    if (_.get(this.detail, 'content')) {
      _.set(this.detail, 'custom_data.content', this.detail.content);
    }

    let image = getImage({ itemData: this.detail, special: true }, this.context);
    if (image) {
      image = featureMainImage(image, this.context)
      const urlParse = url.parse(image, true);
      image = url.format(urlParse);
      this.props.setPreloadImages(image);
    }
    const ogImageWidth = 630;
    const ogImage = getResizedImage({ itemData: this.detail, special: true }, this.context, ogImageWidth);

    const topText = _.get(this.context, 'topText');
    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const pageIndex = this.currentPage > 1 ? `${this.currentPage}ページ目 ` : '' ;
    const bloadcaster = getBroadcasterData(company);
    const titleTag = company === 'n24'
      ? `${pageIndex}【特集】${_.get(this.detail, 'value')}｜${topText}`
      : `${pageIndex}【特集】${_.get(this.detail, 'value')}｜${bloadcaster.nnn}｜${bloadcaster.label}のニュース・${bloadcaster.area}のニュース`;

    const canonical = `/feature/${_.get(this.detail, 'value')}${this.currentPage > 1 ? `?p=${this.currentPage}` : ''}`;
    let description;
    if (_.get(this.detail, 'custom_data.content')) {
      description = removeTag(_.get(this.detail, 'custom_data.content'));
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(titleTag) });
            metas.push({ property: 'og:type', content: 'article' });
            if (description) {
              metas.push({ name: 'description', content: description });
              metas.push({ property: 'og:description', content: description });
            }
            metas.push({ property: 'og:image', content: ogImage });

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={titleTag} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        <div className="specialContentDetail">
          {!this.context.spMode ? (
            // SP時は特集イメージ画像はBlueroseLayoutで描画する
            <div className="special-mv-block">
              <BackgroundImage className="artwork" url={image} />
            </div>
          ) : null}
          <div className="specialContentDetail-outline">
            <h1 className="specialContentDetail-title">{_.get(this.detail, 'value')}</h1>
            {!_.isEmpty(_.get(this.detail, 'custom_data.content')) && (
              <p className="specialContentDetail-description">
                {str2a(nl2br(_.get(this.detail, 'custom_data.content', '')))}
              </p>
            )}
          </div>
          <div className="specialContentDetail-content">
            {_.isEmpty(cards) ? (
              <div className="empty-contents">該当コンテンツが存在しません。</div>
            ) : (
              <div className="news-list-items grid">{cards}</div>
            )}
          </div>
          {this.lastPage > 1 && (
            <div className="btn-block">
              <PagerBlock
                currentPage={this.currentPage}
                lastPage={this.lastPage}
                keyPrefix={`feature_pager_`}
                to={this.to}
                params={this.params}
                showBtn={4}
              />
            </div>
          )}
          <Share title={_.get(this.detail, 'value')} routeHandler={this.props.routeHandler} />
        </div>
        <Analytics pageTitle={titleTag} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(SpecialContentDetail);
root.getPaths = SpecialContentDetail.getPaths;
root.getPrefetchPaths = SpecialContentDetail.getPrefetchPaths;
root.afterPrefetch = SpecialContentDetail.afterPrefetch;
export default root;
