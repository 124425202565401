export default function columnsInRow() {
  if (typeof window === 'undefined') return;

  let columnsInRow = 1;
  if (window.matchMedia('screen and (min-width: 1920px)').matches) {
    columnsInRow = 5;
  } else if (
    window.matchMedia(
      'screen and (min-width: 1194px) and (max-width: 1919px)',
    ).matches
  ) {
    columnsInRow = 4;
  } else if (
    window.matchMedia(
      'screen and (min-width: 1024px) and (max-width: 1193px)',
    ).matches
  ) {
    columnsInRow = 4;
  } else if (
    window.matchMedia(
      'screen and (min-width:860px) and (max-width: 1023px)',
    ).matches
  ) {
    columnsInRow = 3;
  } else if (
    window.matchMedia('screen and (max-width: 859px)').matches
  ) {
    columnsInRow = 2;
  }

  return columnsInRow;
}
