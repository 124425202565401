import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _compact from 'lodash/compact';
import PropTypes from 'prop-types';
import React from 'react';
import TitleCard from './TitleCard';
import classnames from 'classnames';
import _ from 'lodash';
import convertAdTagUiViewName from '../../../common/utils/convertAdTagUiViewName';

class HeadlineContent extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  /**
   * 記事データにsource_systems.idが取得できなければ記事として表示対象から除外する
   * list.json記事はそもそも存在しないのでitem.idがあるかを見る
   */
  prepareArticleData = (items, isListJson = false) => {
    const _items = _compact(_forEach(items, (item) => {
      if ((isListJson && item.id) || _get(item, 'source_systems.id')) {
        return item;
      }
      return null;
    }));
    return _items;
  }

  /**
   * ヘッドラインの構築
   */
  buildupHeadline = (items, isListJson = false) => {
    const headlineObject = {
      topItem: '',
      midleItems: [],
      lowItems: []
    };
    const browserInfo = this.context.getModelData('browserInfo');
    const adSpace = browserInfo.isMobile && !_.get(this.props, 'invisibleAd') ? (
      <div className="publicity-contents" key="div-gpt-trenta_sp_small_ad">
        <div id={`div-gpt-${convertAdTagUiViewName(this.context)}_sp_small_ad`} />
      </div>
    ) : null;

    _forEach(items, (item, index) => {
      // 先頭は一番上の要素とする
      const id = isListJson ? _get(item, 'id') : _get(item, 'source_systems.id');
      if (index === 0) {
        headlineObject['topItem'] = (
          <TitleCard
            key={`headline_main_card_${index}_${id}`}
            itemData={item}
            local={isListJson}
            topPage={true}
            maincard={true}
          />
        );
      } else if (1 <= index && index < 4) {
        headlineObject['midleItems'].push(
          <TitleCard
            key={`headline_main_card_${index}_${id}`}
            itemData={item}
            local={isListJson}
            default={true}
          />
        );

        if (index === 2) headlineObject['midleItems'].push(adSpace);
      } else {
        headlineObject['lowItems'].push(
          <TitleCard
            key={`headline_card_${index}_${id}`}
            itemData={item}
            topPage={true}
            topPageDefault={true}
            local={isListJson}
          />
        );
      }
    });

    return headlineObject;
  }

  render() {
    const { items } = this.props;
    const isListJsonPage = _get(this.context.routeHandler, 'listJson', false);
    const articleItems = this.prepareArticleData(items, isListJsonPage);

    if (_isEmpty(articleItems)) {
      return (<div className="headlineModule-empty">該当コンテンツが存在しません</div>);
    }

    const headlineObjects = this.buildupHeadline(articleItems, isListJsonPage);

    return (
      <React.Fragment>
          {_.isEmpty(items[0].title) ? (
            <div className='no-item-text'>該当コンテンツが存在しません。</div>
          ) : (
            <React.Fragment>
              {headlineObjects.topItem}
              <div className="news-list-items headLineRowCards">{headlineObjects.midleItems}</div>
              <div className="news-list-items small headLineSmallCards">{headlineObjects.lowItems}</div>
            </React.Fragment>
          )}

      </React.Fragment>
    );
  }
}

export default HeadlineContent;
