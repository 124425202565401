import PropTypes from 'prop-types';
import TitleCard from './TitleCard';
import React from 'react';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import _ from 'lodash';
import categoryCode2Name from '../../../../common/categoryCode2Name';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import { getCardResizeImage } from '../../../../common/preloadImages';
import HtmlContext from '../../../common/context/HtmlContext';
import Helmet from 'react-helmet';

/**
 * 自局記事棚出し
 */

const PERPAGE = 6;

class HeadlineListEachTrenta extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    sideContent: PropTypes.bool,
    stv: PropTypes.bool,
    companyCode: PropTypes.string,
  };


  static getRootPath = function(models, options, props) {
    const isListJsonPage = _.get(props, 'routeHandler.listJson', false);
    const companyCode = _.get(props, 'companyCode', 'ntv');
    
    if (isListJsonPage && companyCode) {
      return ['article', 'local', companyCode];
    } else {
      return ['article', 'headline', companyCode, 'trenta'];
    }
  };

  static getPaths = function(models, options, props) {
    let paths = [];

    const indexes = function(models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum
            : options && options.numTitles
            ? options.numTitles + (props.fromNum || 0)
            : PERPAGE - 1,
      };
    };

    const rootPath = this.getRootPath(models, options, props);

    paths = paths.concat([
      [
        indexes(models, options, props),
        ['id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
      ],
    ]);

    return _.map(paths, path => rootPath.concat(path));
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      categoryQuery: categoryCode2Name(
        _.get(this.context, 'routeHandler.params.categoryCode'),
        _.get(context, 'models.config.data'),
      ),
      categoryCode: _.get(this.context, 'routeHandler.params.categoryCode', 'all'),
      dispose: null,
      fetchDataError: null,
      loading: false,
      rankingList: true,
      ctx: this.context,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.items) {
      this.fetchData(this.props.model, this.props);
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
  }

  static getDerivedStateFromProps(props, state) {
    const categoryCode = _.get(props, 'categoryCode');
    if (categoryCode) {
      return {
        categoryQuery: categoryCode2Name(_.get(props, 'categoryCode'), _.get(state.ctx, 'models.config.data')),
        categoryCode: _.get(props, 'categoryCode', 'all'),
      };
    }
    return null;
  }

  fetchData(model, props) {
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      this.state.dispose();
    }
    this.state[JSON.stringify(paths)] = paths;
    const evaluator = model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then(res => {
        const responseItems = _.get(res, ['json'].concat(rootPath));
        if (!_.isArray(responseItems)) {
          this.items = _.compact(_.map(responseItems, (item, i) => {
            return item;
          }));
        } else {
          this.item = responseItems;
        }

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    const companyCode = _.get(this.props, 'companyCode', 'n24')
    let items = this.items;
    if (items) {
      if (items && _.size(items)) {
        const displayCnt = this.context.spMode ? PERPAGE - 1 : PERPAGE;
        items = _.slice(_.values(items), 0, displayCnt);
        // preload imageの設定
        const artcileImages = _.compact(
          _.map(
            _.filter(items, e => e && e.source_systems),
            (itemData, i) => getCardResizeImage({ itemData }, this.context),
          ),
        );
        if (!_.isEmpty(artcileImages)) {
          this.props.setPreloadImages(artcileImages)
        };
      }
    };
    const broadcasterData = getBroadcasterData(companyCode === 'ntv' ? 'n24': companyCode);
    const img_src = broadcasterData.img_src.l;


    let cards = [];
    cards = _.compact(
      _.map(this.items, (itemData, index) => {
        if (!itemData || !itemData.source_systems) return;
        return (
          <TitleCard
            key={`ntv_attention_card_${index}`}
            itemData={itemData}
            default={true}
            isNtvHeadline={true}
          />
        )
      })
    )

    let spBlock = (
      <div className="eachCompanyArticle" key="ranking-list">
        <div className="eachCompanyArticle-header">
          <div className="eachCompanyArticle-logo">
            <img src={img_src} className="card-logo" alt={_.get(broadcasterData, 'label')} />
          </div>
          <div className="eachCompanyArticle-title">{_.get(broadcasterData, 'label')}のニュース</div>
        </div>
        <div className="news-list-items grid eachCompanyArticle-content">{cards}</div>
      </div>
    );
    let pcBlock = (
      <div className="eachCompanyArticle" key="ranking-list">
        <div className="eachCompanyArticle-header">
          <div className="eachCompanyArticle-logo">
            <img src={img_src} className="card-logo" alt={_.get(broadcasterData, 'label')} />
          </div>
          <div className="eachCompanyArticle-title">{_.get(broadcasterData, 'label')}のニュース</div>
        </div>
        <div className="news-list-items grid eachCompanyArticle-content">{cards}</div>
      </div>
    );

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {()=>{
            const linkTags = this.props.getPreloadImages();
            return <Helmet link={linkTags} />
          }}
        </HtmlContext.Consumer>
        {this.context.spMode ? spBlock : pcBlock}
      </React.Fragment>
    )
  }
}
const root = withLayoutContext(HeadlineListEachTrenta);
root.getPaths = HeadlineListEachTrenta.getPaths;
root.getRootPath = HeadlineListEachTrenta.getRootPath;
export default root;
