import routes from '../common/routes';
import HomeContent from './components/browse/HomeContent';
import CompanyHomeContent from './components/browse/CompanyHomeContent';
import ArticlesContent from './components/browse/ArticlesContent';
import CategoryContent from './components/browse/CategoryContent';
import TagContent from './components/browse/TagContent';
import LocalContent from './components/browse/LocalContent';
import RankingContent from './components/browse/RankingContent';
import NewContent from './components/browse/NewContent';
import LiveContent from './components/browse/LiveContent';
import SpecialContent from './components/browse/SpecialContent';
import SpecialContentDetail from './components/browse/SpecialContentDetail';
import SnsContent from './components/browse/SnsContent';
import InformationContent from './components/browse/InformationContent';
import InformationContentDetail from './components/browse/InformationContentDetail';
import error from './components/browse/error';
import SearchContent from './components/browse/SearchContent';
import BaseRouteHandler from './layouts/BaseRouteHandler';
import BlueroseLayout from './layouts/BlueroseLayout';
import ArticlesContentImages from './components/browse/ArticlesContentImages';
import LocalDetailContent from './components/browse/LocalDetailContent';
import AuthorContent from './components/browse/AuthorContent';
import SemiStaticPages from './components/browse/SemiStaticPages';

const routeHandlers = [
  {
    // 総合TOP（TRENTAUI）
    route: routes.home,
    components: [BaseRouteHandler, BlueroseLayout, HomeContent],

    uiView: 'home',
    uiViewLabel: 'トップ',
    view: 'browseTitles',
    scrollTag: true,
    eachTrenta: false,
    listJson: false
  },
  {
    // 各社TOP（TRENTA UI）
    route: routes.homeEachTrenta,
    components: [BaseRouteHandler, BlueroseLayout, HomeContent],

    uiView: 'home',
    uiViewLabel: '各社TOP(TRENTA UI)',
    view: 'browseTitles',
    scrollTag: true,
    eachTrenta: true,
    listJson: false
  },
  {
    // 各社TOP（各社 UI）
    route: routes.companyHome,
    components: [BaseRouteHandler, BlueroseLayout, CompanyHomeContent],

    uiView: 'companyHome',
    uiViewLabel: '各社TOP(各社UI)',
    view: 'browseTitles',
    scrollTag: true,
    isEachCompany: true
  },
  {
    route: routes.articles,
    components: [BaseRouteHandler, BlueroseLayout, ArticlesContent],

    uiView: "articles",
    headerLogo: true,
    scrollTag: true,
    isEachCompany: false,
  },
  {
    route: routes.articleDetail,
    components: [BaseRouteHandler, BlueroseLayout, ArticlesContent],

    uiView: "articleDetail",
    headerLogo: true,
    scrollTag: true,
    isEachCompany: false,
  },
  {
    route: routes.articlesEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, ArticlesContent],

    uiView: "articles",
    headerLogo: true,
    scrollTag: true,
    isEachCompany: true,
  },
  {
    route: routes.articleDetailEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, ArticlesContent],

    uiView: "articleDetail",
    headerLogo: true,
    scrollTag: true,
    isEachCompany: true,
  },
  {
    route: routes.articleDetailJson,
    components: [BaseRouteHandler, BlueroseLayout, LocalDetailContent],

    uiView: "articleDetail",
    headerLogo: true,
    scrollTag: true,
    eachCompany: false,
    listJson: true
  },
  {
    // カテゴリートップ(TRENTA初期リリースには含まない)
    route: routes.category,
    components: [BaseRouteHandler, BlueroseLayout, CategoryContent],

    uiView: "category",
    headerLogo: true
  },
  {
    route: routes.tag,
    components: [BaseRouteHandler, BlueroseLayout, TagContent],

    uiView: "tag",
  },
  {
    // 地方局一覧
    route: routes.local,
    components: [BaseRouteHandler, BlueroseLayout, LocalContent],

    uiView: "local",
    headerLogo: true,
    eachCompany: false
  },
  {
    route: routes.rankingTrenta,
    components: [BaseRouteHandler, BlueroseLayout, RankingContent],

    uiView: "ranking",
    headerLogo: true,
    isEachCompany: false
  },
  {
    route: routes.rankingEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, RankingContent],

    uiView: "ranking",
    headerLogo: true,
    isEachCompany: true
  },
  {
    route: routes.newTrenta,
    components: [BaseRouteHandler, BlueroseLayout, NewContent],

    uiView: "new",
    headerLogo: true,
    isEachCompany: false
  },
  {
    route: routes.newEachTrenta,
    components: [BaseRouteHandler, BlueroseLayout, NewContent],

    uiView: "new",
    headerLogo: true,
    isEachTrenta: true
  },
  {
    route: routes.newEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, NewContent],

    uiView: "new",
    headerLogo: true,
    isEachCompany: true
  },
  {
    route: routes.liveTrenta,
    components: [BaseRouteHandler, BlueroseLayout, LiveContent],

    uiView: "live",
    isEachCompany: false
  },
  {
    route: routes.liveEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, LiveContent],

    uiView: "live",
    isEachCompany: true
  },
  {
    // 特集一覧（TRENTAUI）
    route: routes.featureTrenta,
    components: [BaseRouteHandler, BlueroseLayout, SpecialContent],

    uiView: "special",
    headerLogo: true,
    isEachCompany: false
  },
  {
    // 特集一覧（各社UI）
    route: routes.featureEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, SpecialContent],

    uiView: "special",
    headerLogo: true,
    isEachCompany: true
  },
  {
    // 特集記事一覧（TRENTAUI）
    route: routes.featureDetailTrenta,
    components: [BaseRouteHandler, BlueroseLayout, SpecialContentDetail],

    uiView: "specialdetail",
    headerLogo: true,
    scrollTag: true,
    isEachCompany: false
  },
  {
    // 特集記事一覧（各社UI）
    route: routes.featureDetailEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, SpecialContentDetail],

    uiView: "specialdetail",
    headerLogo: true,
    scrollTag: true,
    isEachCompany: true
  },
  {
    route: routes.sns,
    components: [BaseRouteHandler, BlueroseLayout, SnsContent],

    uiView: "sns",
    headerLogo: true
  },
  {
    route: routes.error,
    components: [BaseRouteHandler, BlueroseLayout, error],
  },
  {
    route: routes.search,
    components: [BaseRouteHandler, BlueroseLayout, SearchContent],

    uiView: "search",
    headerLogo: true
  },
  {
    route: routes.info,
    components: [BaseRouteHandler, BlueroseLayout, InformationContent],

    uiView: "info",
    headerLogo: true,
    isEachCompany: false

  },
  {
    route: routes.infoEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, InformationContent],

    uiView: "info",
    headerLogo: true,
    isEachCompany: true

  },
  {
    route: routes.infoDetail,
    components: [BaseRouteHandler, BlueroseLayout, InformationContentDetail],

    uiView: "InformationContentDetail",
    headerLogo: true,
    isEachCompany: false
  },
  {
    route: routes.infoDetailEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, InformationContentDetail],

    uiView: "InformationContentDetail",
    headerLogo: true,
    isEachCompany: true
  },
  {
    route: routes.infoEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, InformationContent],

    uiView: "infoEachCompany",
    headerLogo: true

  },
  {
    route: routes.infoDetailEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, InformationContentDetail],

    uiView: "InformationContentDetailEachCompany",
    headerLogo: true
  },
  {
    route: routes.articlesImages,
    components: [BaseRouteHandler, BlueroseLayout, ArticlesContentImages],

    uiView: "ArticlesContentImages",
  },
  {
    route: routes.articleDetailImagesTrenta,
    components: [BaseRouteHandler, BlueroseLayout, ArticlesContentImages],

    uiView: "ArticleDetailContentImages",
    headerLogo: true,
    scrollTag: true,
    isEachCompany: false
  },
  {
    route: routes.articleDetailImagesEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, ArticlesContentImages],

    uiView: "ArticleDetailContentImagesEachCompany",
    headerLogo: true,
    scrollTag: true,
    isEachCompany: true
  },
  {
    route: routes.author,
    components: [BaseRouteHandler, BlueroseLayout, TagContent],
    
    uiView: "author",
  },
  {
    route: routes.authorEachCompany,
    components: [BaseRouteHandler, BlueroseLayout, AuthorContent],
    
    uiView: "author",
  },
  /**
   * 半静的ページ
   * 画面元のJSONファイル (S3)
   * - Prod => https://s3.console.aws.amazon.com/s3/buckets/production-n24-static-publish-bucket?region=ap-northeast-1&prefix=pages/&showversions=false
   * - STG => https://s3.console.aws.amazon.com/s3/buckets/staging-n24-static-publish-bucket?region=ap-northeast-1&prefix=pages/&showversions=false
   */
  {
    route: routes.semiStaticPage,
    components: [BaseRouteHandler, BlueroseLayout, SemiStaticPages],

    uiView: 'SemiStaticPages',
    useMetaPopIn: false,
  },

];

export default routeHandlers;
