import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';

class InformationContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);


    this.state = {
      maincard: true,
      introduction: true,
      category: true,
      ranking: true,
      ad: true,
      thumbnail: true,
      wrapperPublicity: true,
      scroll: true,
      rankingMenu: true,
      special: true,
    };
  }


  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }




  render() {
    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle('日テレNEWS NNN') });
            metas.push({ property: 'og:type', content: 'video.tv_show' });

            return <Helmet title="日テレNEWS NNN" meta={metas} />;
          }}
        </HtmlContext.Consumer>
          <div className="contentsWrapper error-page">
            <div className="error-block">
              <div className="error-title">お探しの記事は<br className="visible-md"/>見つかりませんでした</div>
              <p className="error-text">URLが正しく入力されていないか、<br />このページが削除された可能性があります。</p>
              <a className="error-link">日テレNEWS NNNトップへ</a>
            </div>
          </div>
        <Analytics pageTitle={'404エラー'} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(InformationContent);
export default root;
