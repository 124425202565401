import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FeatureArticles from '../../../bluerose/components/browse/FeatureArticles';
import _take from 'lodash/take';
import routes from '../../../common/routes';

export default class NotFound extends Component {
  static bundleName = 'errors/NotFound';

  static contextTypes = {
    getModelData: PropTypes.func,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.searchInputRef = React.createRef();
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    const attentions = this.model.getSync(['article', 'getAttention']);
    this.getAttention = _take(attentions, 6);
  }

  componentDidMount() {
    setTimeout(() => {
      // 画像詳細ページの不正クエリの場合は記事ページにリダイレクト。
      if (window.location.href.includes('image?p=')) {
        window.location.href = window.location.href.match(/.*\//)[0].replace(/\/$/, '');
      }
    }, 1500);
  }


  handleSearchClick() {
    const query = {
      q: this.searchInputRef.current.value || '',
    };
    const url = routes.search.makePath({}, query);
    window.location.href = url;
  }

  handleKeyDown(e) {
    if (e.which === 13) {
      this.handleSearchClick();
    }
  }

  render() {
    return (
      <>
        <div className="contentsWrapper 404-error">
          <div className="error-block">
            <div className="error-block-text">
              <div className="error-title">ご指定のページが見つかりませんでした</div>
              <p className="error-text">
                URLが正しく入力されていないか、
                <br />
                このページが削除された可能性があります。
              </p>
            </div>
            <div>
              <div className="error-search-text">探している記事をキーワードで検索</div>
              <div>
                {this.context.spMode ? 
                  <div className="search-click">
                    <div className="search-input">
                      <div className="search-input-text">
                        <input
                          type="text"
                          ref={this.searchInputRef}
                          placeholder="キーワードを入力"
                          onKeyDown={this.handleKeyDown}
                          maxLength={30}
                        />
                      </div>
                      <span className="btn btn-fill black-color btn-small-s" onClick={this.handleSearchClick}>
                        検索
                      </span>
                    </div>
                  </div>
                :
                  <>
                    <div className="search-input error-search-input">
                      <input
                        type="text"
                        ref={this.searchInputRef}
                        placeholder="キーワードを入力"
                        onKeyDown={this.handleKeyDown}
                        maxLength={30}
                      />
                      <i className="fa-search search-icon" onClick={this.handleSearchClick} />
                    </div>
                    <div onClick={this.handleSearchOpen} />
                  </>
                }
              </div>
              <div className="error-feature-articles">
                <FeatureArticles getAttention={this.getAttention} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
