import React from 'react';

/**
 * ダミー用再生ボタン
 * @param {object} props
 * @param {boolean} props.viewPlayerBtn 再生ボタン表示フラグ
 * @returns {React.JSX.Element}
 */
export default function DummyPlayButton(props) {
  const { viewPlayerBtn } = props;

  if (!viewPlayerBtn) {
    return null;
  }

  return (
    <button
      className="play-button"
      type="button"
      aria-label="Play Video"
      title="Play Video"
      aria-disabled="false"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white">
        <path d="M0 0h24v24H0V0z" fill="none"></path>
        <path d="M10 8.64L15.27 12 10 15.36V8.64M8 5v14l11-7L8 5z"></path>
      </svg>
    </button>
  );
}
