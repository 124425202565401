"use strict";

import blueroseApp from './blueroseApp';
import namespace from '../../common/namespace';

export default function initAppContext(pathEvaluator, history) {
  var cb = arguments.length > 2 && typeof arguments[2] === 'function' ? arguments[2] : function(){};
  const appContext = blueroseApp.createContext({
    history,
    pathEvaluator,
    model: _.assign(namespace.reactContext, {pathEvaluator}),
    renderSource: "client"
  }, {
    handleRouteChange:function(state, routeHandler){
      if (typeof namespace.getDiscoverApp === 'function') {
        namespace.getDiscoveryApp().getActionCreators().changeRoute({
          currentRouteHandler: namespace.getRouteHandler(),
          newRouteHandler: routeHandler
        })
      }
      return state;
    }
  });

  namespace.appContext = appContext;
  cb(appContext);
}

