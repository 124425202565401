import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withModalContext } from '../../../common/context/ModalContext';
import classnames from 'classnames';
import routes from '../../../common/routes';
import categoryName2Code from '../../../../common/categoryName2Code';

class DropdownMenu extends Component {
  static propTypes = {
    calendar: PropTypes.bool,
    categoryTag: PropTypes.bool,
    search: PropTypes.bool,
    current: PropTypes.string,
  };

  static contextTypes = {
    history: PropTypes.object,
    spMode: PropTypes.bool,
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.closeDropdownMenu = this.closeDropdownMenu.bind(this);

    this.isEachCompany = _.get(context, 'routeHandler.isEachCompany', false);
    this.companyCode = _.get(this.context, 'routeHandler.params.companyCode');
    this.state = {
      itemOpen: false,
    };
  }

  handleSelectChange(e, val) {
    if (e && !val) val = _.get(e, 'target.value', 'all');
    if (this.props.categoryTag) {
      if (!this.props.search) {
        window.location.href = this.getMakePathURL(val);
      }
    }
    if (this.props.onChange) {
      this.props.onChange(val);
    }
  }

  /**
   * ドロップダウンメニュー選択先のURL(パス)を生成
   * @param {*} val 
   * @return URL
   */
  getMakePathURL (val) {
    let to = routes.newTrenta;
    let params = {categoryCode: val};

    if (_.get(this.context, 'routeHandler.isEachTrenta', false)) {
      to = routes.newEachTrenta;
      params.companyCode = _.get(this.context, 'routeHandler.params.companyCode');
    } else if (this.isEachCompany) {
      to = routes.newEachCompany;
      params.companyCode = _.get(this.context, 'routeHandler.params.companyCode');
    }
    return to.makePath(params);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.search) {
      document.addEventListener('click', this.closeDropdownMenu, false);
    }
  }

  // DropdownMenu 以外の要素をクリックした場合、DropdownMenu表示中の場合、stateを非表示へ更新
  closeDropdownMenu(e) {
    const browserInfo = this.context.getModelData('browserInfo');
    if  (browserInfo.isIOS || browserInfo.isAndroid) return; // スマホブラウザは除外 (selectタグ仕様のため)
    if (!this.state.itemOpen) return; // 非表示状態は除外

    const eventTargetClassName =  e?.target?.classList.contains('currentOption') ? e.target.parentElement.classList : e?.target?.classList;
    if (eventTargetClassName.contains('search-dropdown')) return; // DropdownMenuの場合、除外 (clickイベント「handleMenuClick」判定処理を利用)

    this.setState({ itemOpen: false });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleMenuClick() {
    this.setState({ itemOpen: !this.state.itemOpen });
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    let categoryList = [];
    let list = this.props.list;
    if (list && list.length) {
      let defaultList = [];
      if (this.props.categoryTag) {
        if (this.props.search) {
          // SearchContentからの呼び出し
          defaultList.push({
            title: "カテゴリ"
          });
        } else {
          // NewContentからの呼び出し
          defaultList.push({
            value: "all",
            title: "すべて",
          });
        }
      }
      if (defaultList.length) {
        list = defaultList.concat(list);
      }
    }
    categoryList = _.compact(
      _.map(list || [], (itemData, index) => {
        let value;
        let label;
        if (this.props.categoryTag) {
          if (!itemData || !itemData.title || itemData.title === '特集') return;
          value = itemData.value || categoryName2Code(_.get(itemData, 'title'), _.get(this.context, 'models.config.data'), this.companyCode);
          label = _.get(itemData, 'title');

          // 各社UI YTV・CTVの場合、 カテゴリコード「culture」 => 「entertainment」利用
          if (value === 'culture' && this.isEachCompany && _.includes(['ytv', 'ctv'], this.companyCode)) {
            value = 'entertainment';
            label = this.companyCode === 'ctv' ? 'エンタメ' : '芸能';
          }
        }
        if (this.props.localArea) {
          if (!itemData || !itemData.label) return;
          value = index;
          label = _.get(itemData, 'label');
        }
        if (browserInfo.isIOS || browserInfo.isAndroid) {
          return (
            <option value={value} key={`category_option_${index}`}>{label}</option>
          );
        } else {
          return (
            <div key={`category_option_${index}`}
                 data-value={value}
                 className={classnames('option', {active: (this.props.current || 'カテゴリ') === label})}
                 onClick={() => this.handleSelectChange(null, value)}
            >{label}</div>
          );
        }
      }),
    );

    return (
      <React.Fragment>
        {this.props.categoryTag ?
          (
            <div className={classnames('dropdown',{'search': this.props.pcMode})}>
              {browserInfo.isIOS || browserInfo.isAndroid ?
                <select name="example" className={classnames('example',{'search-dropdown': this.props.search})} required value={categoryName2Code(this.props.current || 'category', _.get(this.context, 'models.config.data'), this.companyCode)} onChange={this.handleSelectChange}>
                  {categoryList}
                </select>
                :
                <div onClick={this.handleMenuClick} className={classnames('example pc',{'search-dropdown': this.props.search})}>
                  <h2 className="currentOption">{this.props.current || 'カテゴリ'}</h2>
                  <div className={classnames('itemlist',{'open' :this.state.itemOpen})}>
                    {categoryList}
                  </div>
                </div>
              }
            </div>
          ):
          this.props.localArea ?
          (
            <div className={classnames('dropdown',{'search': this.props.pcMode})} onClick={()=> this.handleMenuClick()}>
              {browserInfo.isIOS || browserInfo.isAndroid ?
                <select name="example" className="example" required value={_.get(this.props, 'current')} onChange={this.handleSelectChange}>
                  {categoryList}
                </select>
                :
                <div className="example pc" onClick={this.handleMenuClick}>
                  <h2 className="currentOption">{list && list[this.props.current] && list[this.props.current]['label'] || ''}</h2>
                  <div className={classnames('itemlist',{'open' :this.state.itemOpen})}>
                    {categoryList}
                  </div>
                </div>
              }
            </div>
          ):
          null
        }
      </React.Fragment>
    );
  }
}

export default withModalContext(DropdownMenu);
