import _get from 'lodash/get';

/**
 * 広告タグマスタと付帯データのオブジェクト管理モデル
 * @param {Object} params 付帯データのオブジェクト
 * @param {string} params.nnn 発局の局ID etc: (stv)
 * @param {string} params.category カテゴリー名の英名 etc: society
 * @param {array} params.hash_tags ハッシュタグ。配列で持ち、複数の場合もある。
 * @return {Object} 広告タグマスタ
 */
export default (params = {}) => {
  const NNN = _get(params, 'nnn', '');
  const CAT = _get(params, 'category', '');
  const TAG = _get(params, 'tags', []);
  return {
    // SP
    // ①ヘッドライン SP
    news_top_sp_small_ad: {
      divId: 'div-gpt-news_top_sp_small_ad',
      gpt: {
        unitCode: '/176070532/news_top_sp_small_ad',
        sizes: [320, 100],
      },
      aps: {
        slotName: '/176070532/news_top_sp_small_ad',
        sizes: [[320, 100]],
      },
    },
    news_top_sp_2nd_rec: {
      divId: 'div-gpt-news_top_sp_2nd_rec',
      gpt: {
        unitCode: '/176070532/news_top_sp_2nd_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_top_sp_2nd_rec',
        sizes: [[300, 250]],
      },
    },
    news_top_sp_3rd_rec: {
      divId: 'div-gpt-news_top_sp_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_top_sp_3rd_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_top_sp_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_top_sp_4th_rec: {
      divId: 'div-gpt-news_top_sp_4th_rec',
      gpt: {
        unitCode: '/176070532/news_top_sp_4th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_top_sp_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_top_sp_5th_rec: {
      divId: 'div-gpt-news_top_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_top_sp_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_top_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_top_sp_overlay: {
      divId: 'div-gpt-news_top_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_top_sp_overlay',
        sizes: [320, 50],
      },
      aps: {
        slotName: '/176070532/news_top_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    //　②各社TOP SP(NNNパラメータあり)
    news_nnn_top_sp_small_ad: {
      divId: 'div-gpt-news_nnn_top_sp_small_ad',
      gpt: {
        unitCode: '/176070532/news_nnn_top_sp_small_ad',
        sizes: [320, 100],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_sp_small_ad',
        sizes: [[320, 100]],
      },
    },
    news_nnn_top_sp_2nd_rec: {
      divId: 'div-gpt-news_nnn_top_sp_2nd_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_top_sp_2nd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_sp_2nd_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_top_sp_3rd_rec: {
      divId: 'div-gpt-news_nnn_top_sp_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_top_sp_3rd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_sp_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_top_sp_4th_rec: {
      divId: 'div-gpt-news_nnn_top_sp_4th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_top_sp_4th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_sp_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_top_sp_5th_rec: {
      divId: 'div-gpt-news_nnn_top_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_top_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_top_sp_overlay: {
      divId: 'div-gpt-news_nnn_top_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_top_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ③新着(NTV) SP(CATパラメータあり)
    news_new_sp_5th_rec: {
      divId: 'div-gpt-news_new_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_new_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_new_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_new_sp_overlay: {
      divId: 'div-gpt-news_new_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_new_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_new_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ④新着(NNN) SP(CAT, NNNパラメータあり)
    news_nnn_new_sp_5th_rec: {
      divId: 'div-gpt-news_nnn_new_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_new_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_new_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_new_sp_overlay: {
      divId: 'div-gpt-news_nnn_new_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_new_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_new_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    //　⑤記事詳細(NTV) SP(CAT, TAGパラメータあり)
    news_article_sp_1st_rec: {
      divId: 'div-gpt-news_article_sp_1st_rec',
      gpt: {
        unitCode: '/176070532/news_article_sp_1st_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_article_sp_1st_rec',
        sizes: [[300, 250]],
      },
    },
    news_article_sp_5th_rec: {
      divId: 'div-gpt-news_article_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_article_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_article_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_article_sp_ydn_rec: {
      divId: 'div-gpt-news_article_sp_ydn_rec',
      gpt: {
        unitCode: '/176070532/news_article_sp_ydn_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_article_sp_ydn_rec',
        sizes: [[300, 250]],
      },
    },
    news_article_sp_overlay: {
      divId: 'div-gpt-news_article_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_article_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_article_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    //　⑥記事詳細(NNN) SP(NNN, CAT, TAGパラメータあり)
    news_nnn_article_sp_1st_rec: {
      divId: 'div-gpt-news_nnn_article_sp_1st_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_article_sp_1st_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_sp_1st_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_article_sp_5th_rec: {
      divId: 'div-gpt-news_nnn_article_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_article_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_article_sp_ydn_rec: {
      divId: 'div-gpt-news_nnn_article_sp_ydn_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_article_sp_ydn_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_sp_ydn_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_article_sp_overlay: {
      divId: 'div-gpt-news_nnn_article_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_article_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    //　⑦記事詳細－画像ページ（NTV） SP(CATパラメータあり)
    news_image_sp_article_rec: {
      divId: 'div-gpt-news_image_sp_article_rec',
      gpt: {
        unitCode: '/176070532/news_image_sp_article_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_sp_article_rec',
        sizes: [[300, 250]],
      },
    },
    news_image_sp_5th_rec: {
      divId: 'div-gpt-news_image_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_image_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_image_sp_ydn_rec: {
      divId: 'div-gpt-news_image_sp_ydn_rec',
      gpt: {
        unitCode: '/176070532/news_image_sp_ydn_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_sp_ydn_rec',
        sizes: [[300, 250]],
      },
    },
    news_image_sp_overlay: {
      divId: 'div-gpt-news_image_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_image_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    //　⑧記事詳細－画像ページ（NNN） SP(CAT, NNNパラメータあり)
    news_nnn_image_sp_article_rec: {
      divId: 'div-gpt-news_nnn_image_sp_article_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_image_sp_article_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_sp_article_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_image_sp_5th_rec: {
      divId: 'div-gpt-news_nnn_image_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_image_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_image_sp_ydn_rec: {
      divId: 'div-gpt-news_nnn_image_sp_ydn_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_image_sp_ydn_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_sp_ydn_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_image_sp_overlay: {
      divId: 'div-gpt-news_nnn_image_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_image_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ⑨特集一覧・特集記事一覧（NTV） SP
    news_feature_sp_5th_rec: {
      divId: 'div-gpt-news_feature_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_feature_sp_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_feature_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_feature_sp_overlay: {
      divId: 'div-gpt-news_feature_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_feature_sp_overlay',
        sizes: [320, 50],
      },
      aps: {
        slotName: '/176070532/news_feature_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ⑩特集一覧・特集記事一覧（NNN） SP(NNNパラメータあり)
    news_nnn_feature_sp_5th_rec: {
      divId: 'div-gpt-news_nnn_feature_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_feature_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_feature_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_feature_sp_overlay: {
      divId: 'div-gpt-news_nnn_feature_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_feature_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_feature_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ⑪ランキング（NTV） SP(CATパラメータあり)
    news_ranking_sp_5th_rec: {
      divId: 'div-gpt-news_ranking_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_ranking_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_ranking_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_ranking_sp_overlay: {
      divId: 'div-gpt-news_ranking_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_ranking_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_ranking_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ⑫ランキング（NNN） SP(NNNパラメータあり)
    news_nnn_ranking_sp_5th_rec: {
      divId: 'div-gpt-news_nnn_ranking_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_ranking_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_ranking_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_ranking_sp_overlay: {
      divId: 'div-gpt-news_nnn_ranking_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_ranking_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_ranking_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ⑬検索結果（NTV） SP
    news_search_sp_5th_rec: {
      divId: 'div-gpt-news_search_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_search_sp_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_search_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_search_sp_overlay: {
      divId: 'div-gpt-news_search_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_search_sp_overlay',
        sizes: [320, 50],
      },
      aps: {
        slotName: '/176070532/news_search_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ⑭地域（NTV） SP
    news_area_sp_5th_rec: {
      divId: 'div-gpt-news_area_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_area_sp_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_area_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_area_sp_overlay: {
      divId: 'div-gpt-news_area_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_area_sp_overlay',
        sizes: [320, 50],
      },
      aps: {
        slotName: '/176070532/news_area_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ⑮タグ記事一覧（NTV） SP(TAGパラメータあり)
    news_tag_sp_5th_rec: {
      divId: 'div-gpt-news_tag_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_tag_sp_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_tag_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_tag_sp_overlay: {
      divId: 'div-gpt-news_tag_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_tag_sp_overlay',
        sizes: [320, 50],
        keyValues: [
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_tag_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ⑯ライブ（NTV） SP
    news_live_sp_5th_rec: {
      divId: 'div-gpt-news_live_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_live_sp_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_live_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_live_sp_overlay: {
      divId: 'div-gpt-news_live_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_live_sp_overlay',
        sizes: [320, 50],
      },
      aps: {
        slotName: '/176070532/news_live_sp_overlay',
        sizes: [[320, 50]],
      },
    },
    // ⑰その他 SP
    news_other_sp_5th_rec: {
      divId: 'div-gpt-news_other_sp_5th_rec',
      gpt: {
        unitCode: '/176070532/news_other_sp_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_other_sp_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_other_sp_overlay: {
      divId: 'div-gpt-news_other_sp_overlay',
      gpt: {
        unitCode: '/176070532/news_other_sp_overlay',
        sizes: [320, 50],
      },
      aps: {
        slotName: '/176070532/news_other_sp_overlay',
        sizes: [[320, 50]],
      },
    },
  
  
    // PC
    // ①ヘッドライン PC
    news_top_pc_3rd_rec: {
      divId: 'div-gpt-news_top_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_top_pc_3rd_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_top_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_top_pc_4th_rec: {
      divId: 'div-gpt-news_top_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_top_pc_4th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_top_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_top_pc_5th_rec: {
      divId: 'div-gpt-news_top_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_top_pc_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_top_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_top_pc_6th_rec: {
      divId: 'div-gpt-news_top_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_top_pc_6th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_top_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_top_pc_bigrec: {
      divId: 'div-gpt-news_top_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_top_pc_bigrec',
        sizes: [300, 600],
      },
      aps: {
        slotName: '/176070532/news_top_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_top_pc_overlay: {
      divId: 'div-gpt-news_top_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_top_pc_overlay',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_top_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ②各社TOP PC(NNNパラメータあり)
    news_nnn_top_pc_3rd_rec: {
      divId: 'div-gpt-news_nnn_top_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_top_pc_3rd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_top_pc_4th_rec: {
      divId: 'div-gpt-news_nnn_top_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_top_pc_4th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_top_pc_5th_rec: {
      divId: 'div-gpt-news_nnn_top_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_top_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_top_pc_6th_rec: {
      divId: 'div-gpt-news_nnn_top_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_top_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_top_pc_bigrec: {
      divId: 'div-gpt-news_nnn_top_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_nnn_top_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_nnn_top_pc_overlay: {
      divId: 'div-gpt-news_nnn_top_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_top_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_top_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ③新着(NTV) PC(CATパラメータあり)
    news_new_pc_bnr: {
      divId: 'div-gpt-news_new_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_new_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_new_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_new_pc_3rd_rec: {
      divId: 'div-gpt-news_new_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_new_pc_3rd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_new_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_new_pc_4th_rec: {
      divId: 'div-gpt-news_new_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_new_pc_4th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_new_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_new_pc_5th_rec: {
      divId: 'div-gpt-news_new_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_new_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_new_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_new_pc_6th_rec: {
      divId: 'div-gpt-news_new_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_new_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_new_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_new_pc_bigrec: {
      divId: 'div-gpt-news_new_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_new_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_new_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_new_pc_overlay: {
      divId: 'div-gpt-news_new_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_new_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_new_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ④新着(NNN) PC(NNN, CATパラメータあり)
    news_nnn_new_pc_bnr: {
      divId: 'div-gpt-news_nnn_new_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_nnn_new_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_new_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_nnn_new_pc_3rd_rec: {
      divId: 'div-gpt-news_nnn_new_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_new_pc_3rd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_new_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_new_pc_4th_rec: {
      divId: 'div-gpt-news_nnn_new_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_new_pc_4th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_new_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_new_pc_5th_rec: {
      divId: 'div-gpt-news_nnn_new_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_new_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_new_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_new_pc_6th_rec: {
      divId: 'div-gpt-news_nnn_new_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_new_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_new_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_new_pc_bigrec: {
      divId: 'div-gpt-news_nnn_new_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_nnn_new_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_new_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_nnn_new_pc_overlay: {
      divId: 'div-gpt-news_nnn_new_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_new_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_new_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    //　⑤記事詳細(NTV) PC(CAT, TAGパラメータあり)
    news_article_pc_bnr: {
      divId: 'div-gpt-news_article_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_article_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_article_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_article_pc_1st_rec: {
      divId: 'div-gpt-news_article_pc_1st_rec',
      gpt: {
        unitCode: '/176070532/news_article_pc_1st_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_article_pc_1st_rec',
        sizes: [[300, 250]],
      },
    },
    news_article_pc_2nd_rec: {
      divId: 'div-gpt-news_article_pc_2nd_rec',
      gpt: {
        unitCode: '/176070532/news_article_pc_2nd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_article_pc_2nd_rec',
        sizes: [[300, 250]],
      },
    },
    news_article_pc_ydn_rec: {
        divId: 'div-gpt-news_article_pc_ydn_rec',
        gpt: {
          unitCode: '/176070532/news_article_pc_ydn_rec',
          sizes: [468, 60],
          keyValues: [
            {
              key: "news_category",
              value: CAT
            },
            {
              key: "news_tag",
              value: TAG
            }
          ],
        },
        aps: {
          slotName: '/176070532/news_article_pc_ydn_rec',
          sizes: [[468, 60]],
        },
      },
    news_article_pc_5th_rec: {
      divId: 'div-gpt-news_article_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_article_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_article_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_article_pc_6th_rec: {
      divId: 'div-gpt-news_article_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_article_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_article_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_article_pc_bigrec: {
      divId: 'div-gpt-news_article_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_article_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_article_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_article_pc_overlay: {
      divId: 'div-gpt-news_article_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_article_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_article_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    //　⑥記事詳細(NNN) PC(NNN, CAT, TAGパラメータあり)
    news_nnn_article_pc_bnr: {
      divId: 'div-gpt-news_nnn_article_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_nnn_article_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_nnn_article_pc_1st_rec: {
      divId: 'div-gpt-news_nnn_article_pc_1st_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_article_pc_1st_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_pc_1st_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_article_pc_2nd_rec: {
      divId: 'div-gpt-news_nnn_article_pc_2nd_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_article_pc_2nd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_pc_2nd_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_article_pc_ydn_rec: {
        divId: 'div-gpt-news_nnn_article_pc_ydn_rec',
        gpt: {
          unitCode: '/176070532/news_nnn_article_pc_ydn_rec',
          sizes: [468, 60],
          keyValues: [
            {
              key: "news_channel",
              value: NNN
            },
            {
              key: "news_category",
              value: CAT
            },
            {
              key: "news_tag",
              value: TAG
            }
          ],
        },
        aps: {
          slotName: '/176070532/news_nnn_article_pc_ydn_rec',
          sizes: [[468, 60]],
        },
      },
    news_nnn_article_pc_5th_rec: {
      divId: 'div-gpt-news_nnn_article_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_article_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_article_pc_6th_rec: {
      divId: 'div-gpt-news_nnn_article_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_article_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_article_pc_bigrec: {
      divId: 'div-gpt-news_nnn_article_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_nnn_article_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_nnn_article_pc_overlay: {
      divId: 'div-gpt-news_nnn_article_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_article_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
          {
            key: "news_tag",
            value: TAG
          }
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_article_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    //　⑦記事詳細－画像ページ（NTV） PC(CATパラメータあり)
    news_image_pc_bnr: {
      divId: 'div-gpt-news_image_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_image_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_image_pc_1st_rec: {
      divId: 'div-gpt-news_image_pc_1st_rec',
      gpt: {
        unitCode: '/176070532/news_image_pc_1st_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_pc_1st_rec',
        sizes: [[300, 250]],
      },
    },
    news_image_pc_2nd_rec: {
      divId: 'div-gpt-news_image_pc_2nd_rec',
      gpt: {
        unitCode: '/176070532/news_image_pc_2nd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_pc_2nd_rec',
        sizes: [[300, 250]],
      },
    },
    news_image_pc_ydn_rec: {
        divId: 'div-gpt-news_image_pc_ydn_rec',
        gpt: {
          unitCode: '/176070532/news_image_pc_ydn_rec',
          sizes: [468, 60],
          keyValues: [
            {
              key: "news_category",
              value: CAT
            },
          ],
        },
        aps: {
          slotName: '/176070532/news_image_pc_ydn_rec',
          sizes: [[468, 60]],
        },
      },
    news_image_pc_5th_rec: {
      divId: 'div-gpt-news_image_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_image_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_image_pc_6th_rec: {
      divId: 'div-gpt-news_image_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_image_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_image_pc_bigrec: {
      divId: 'div-gpt-news_image_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_image_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_image_pc_overlay: {
      divId: 'div-gpt-news_image_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_image_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_image_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    //　⑧記事詳細－画像ページ（NNN） PC(NNN, CATパラメータあり)
    news_nnn_image_pc_bnr: {
      divId: 'div-gpt-news_nnn_image_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_nnn_image_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_nnn_image_pc_1st_rec: {
      divId: 'div-gpt-news_nnn_image_pc_1st_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_image_pc_1st_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_pc_1st_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_image_pc_2nd_rec: {
      divId: 'div-gpt-news_nnn_image_pc_2nd_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_image_pc_2nd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_pc_2nd_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_image_pc_ydn_rec: {
        divId: 'div-gpt-news_nnn_image_pc_ydn_rec',
        gpt: {
          unitCode: '/176070532/news_nnn_image_pc_ydn_rec',
          sizes: [468, 60],
          keyValues: [
            {
              key: "news_channel",
              value: NNN
            },
            {
              key: "news_category",
              value: CAT
            },
          ],
        },
        aps: {
          slotName: '/176070532/news_nnn_image_pc_ydn_rec',
          sizes: [[468, 60]],
        },
      },
    news_nnn_image_pc_5th_rec: {
      divId: 'div-gpt-news_nnn_image_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_image_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_image_pc_6th_rec: {
      divId: 'div-gpt-news_nnn_image_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_image_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_image_pc_bigrec: {
      divId: 'div-gpt-news_nnn_image_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_nnn_image_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_nnn_image_pc_overlay: {
      divId: 'div-gpt-news_nnn_image_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_image_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_image_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ⑨特集一覧・特集記事一覧（NTV） PC
    news_feature_pc_bnr: {
      divId: 'div-gpt-news_feature_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_feature_pc_bnr',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_feature_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_feature_pc_3rd_rec: {
      divId: 'div-gpt-news_feature_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_feature_pc_3rd_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_feature_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_feature_pc_4th_rec: {
      divId: 'div-gpt-news_feature_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_feature_pc_4th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_feature_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_feature_pc_5th_rec: {
      divId: 'div-gpt-news_feature_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_feature_pc_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_feature_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_feature_pc_6th_rec: {
      divId: 'div-gpt-news_feature_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_feature_pc_6th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_feature_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_feature_pc_bigrec: {
      divId: 'div-gpt-news_feature_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_feature_pc_bigrec',
        sizes: [300, 600],
      },
      aps: {
        slotName: '/176070532/news_feature_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_feature_pc_overlay: {
      divId: 'div-gpt-news_feature_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_feature_pc_overlay',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_feature_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ⑩特集一覧・特集記事一覧（NNN） PC(NNNパラメータあり)
    news_nnn_feature_pc_bnr: {
      divId: 'div-gpt-news_nnn_feature_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_nnn_feature_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_feature_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_nnn_feature_pc_3rd_rec: {
      divId: 'div-gpt-news_nnn_feature_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_feature_pc_3rd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_feature_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_feature_pc_4th_rec: {
      divId: 'div-gpt-news_nnn_feature_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_feature_pc_4th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_feature_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_feature_pc_5th_rec: {
      divId: 'div-gpt-news_nnn_feature_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_feature_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_feature_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_feature_pc_6th_rec: {
      divId: 'div-gpt-news_nnn_feature_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_feature_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_feature_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_feature_pc_bigrec: {
      divId: 'div-gpt-news_nnn_feature_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_nnn_feature_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_feature_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_nnn_feature_pc_overlay: {
      divId: 'div-gpt-news_nnn_feature_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_feature_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_feature_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ⑪ランキング（NTV） PC（NTV） PC(CATパラメータあり)
    news_ranking_pc_bnr: {
      divId: 'div-gpt-news_ranking_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_ranking_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_ranking_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_ranking_pc_3rd_rec: {
      divId: 'div-gpt-news_ranking_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_ranking_pc_3rd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_ranking_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_ranking_pc_4th_rec: {
      divId: 'div-gpt-news_ranking_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_ranking_pc_4th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_ranking_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_ranking_pc_5th_rec: {
      divId: 'div-gpt-news_ranking_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_ranking_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_ranking_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_ranking_pc_6th_rec: {
      divId: 'div-gpt-news_ranking_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_ranking_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_ranking_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_ranking_pc_bigrec: {
      divId: 'div-gpt-news_ranking_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_ranking_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_ranking_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_ranking_pc_overlay: {
      divId: 'div-gpt-news_ranking_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_ranking_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_category",
            value: CAT
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_ranking_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ⑫ランキング（NNN） PC(NNNパラメータあり)
    news_nnn_ranking_pc_bnr: {
      divId: 'div-gpt-news_nnn_ranking_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_nnn_ranking_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_ranking_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_nnn_ranking_pc_3rd_rec: {
      divId: 'div-gpt-news_nnn_ranking_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_ranking_pc_3rd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_ranking_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_ranking_pc_4th_rec: {
      divId: 'div-gpt-news_nnn_ranking_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_ranking_pc_4th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_ranking_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_ranking_pc_5th_rec: {
      divId: 'div-gpt-news_nnn_ranking_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_ranking_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_ranking_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_ranking_pc_6th_rec: {
      divId: 'div-gpt-news_nnn_ranking_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_nnn_ranking_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_ranking_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_nnn_ranking_pc_bigrec: {
      divId: 'div-gpt-news_nnn_ranking_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_nnn_ranking_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_ranking_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_nnn_ranking_pc_overlay: {
      divId: 'div-gpt-news_nnn_ranking_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_nnn_ranking_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_channel",
            value: NNN
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_nnn_ranking_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ⑬検索結果（NTV） PC
    news_search_pc_bnr: {
      divId: 'div-gpt-news_search_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_search_pc_bnr',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_search_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_search_pc_3rd_rec: {
      divId: 'div-gpt-news_search_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_search_pc_3rd_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_search_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_search_pc_4th_rec: {
      divId: 'div-gpt-news_search_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_search_pc_4th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_search_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_search_pc_5th_rec: {
      divId: 'div-gpt-news_search_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_search_pc_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_search_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_search_pc_6th_rec: {
      divId: 'div-gpt-news_search_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_search_pc_6th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_search_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_search_pc_bigrec: {
      divId: 'div-gpt-news_search_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_search_pc_bigrec',
        sizes: [300, 600],
      },
      aps: {
        slotName: '/176070532/news_search_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_search_pc_overlay: {
      divId: 'div-gpt-news_search_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_search_pc_overlay',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_search_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ⑭地域（NTV） PC
    news_area_pc_bnr: {
      divId: 'div-gpt-news_area_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_area_pc_bnr',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_area_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_area_pc_3rd_rec: {
      divId: 'div-gpt-news_area_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_area_pc_3rd_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_area_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_area_pc_4th_rec: {
      divId: 'div-gpt-news_area_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_area_pc_4th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_area_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_area_pc_5th_rec: {
      divId: 'div-gpt-news_area_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_area_pc_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_area_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_area_pc_6th_rec: {
      divId: 'div-gpt-news_area_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_area_pc_6th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_area_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_area_pc_bigrec: {
      divId: 'div-gpt-news_area_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_area_pc_bigrec',
        sizes: [300, 600],
      },
      aps: {
        slotName: '/176070532/news_area_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_area_pc_overlay: {
      divId: 'div-gpt-news_area_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_area_pc_overlay',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_area_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ⑮タグ記事一覧（NTV） PC(TAGパラメータあり)
    news_tag_pc_bnr: {
      divId: 'div-gpt-news_tag_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_tag_pc_bnr',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_tag_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_tag_pc_3rd_rec: {
      divId: 'div-gpt-news_tag_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_tag_pc_3rd_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_tag_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_tag_pc_4th_rec: {
      divId: 'div-gpt-news_tag_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_tag_pc_4th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_tag_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_tag_pc_5th_rec: {
      divId: 'div-gpt-news_tag_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_tag_pc_5th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_tag_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_tag_pc_6th_rec: {
      divId: 'div-gpt-news_tag_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_tag_pc_6th_rec',
        sizes: [300, 250],
        keyValues: [
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_tag_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_tag_pc_bigrec: {
      divId: 'div-gpt-news_tag_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_tag_pc_bigrec',
        sizes: [300, 600],
        keyValues: [
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_tag_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_tag_pc_overlay: {
      divId: 'div-gpt-news_tag_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_tag_pc_overlay',
        sizes: [728, 90],
        keyValues: [
          {
            key: "news_tag",
            value: TAG
          },
        ],
      },
      aps: {
        slotName: '/176070532/news_tag_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ⑯ライブ（NTV） PC
    news_live_pc_bnr: {
      divId: 'div-gpt-news_live_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_live_pc_bnr',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_live_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_live_pc_3rd_rec: {
      divId: 'div-gpt-news_live_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_live_pc_3rd_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_live_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_live_pc_4th_rec: {
      divId: 'div-gpt-news_live_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_live_pc_4th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_live_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_live_pc_5th_rec: {
      divId: 'div-gpt-news_live_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_live_pc_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_live_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_live_pc_6th_rec: {
      divId: 'div-gpt-news_live_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_live_pc_6th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_live_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_live_pc_bigrec: {
      divId: 'div-gpt-news_live_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_live_pc_bigrec',
        sizes: [300, 600],
      },
      aps: {
        slotName: '/176070532/news_live_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_live_pc_overlay: {
      divId: 'div-gpt-news_live_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_live_pc_overlay',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_live_pc_overlay',
        sizes: [[728, 90]],
      },
    },
    // ⑰その他 PC
    news_other_pc_bnr: {
      divId: 'div-gpt-news_other_pc_bnr',
      gpt: {
        unitCode: '/176070532/news_other_pc_bnr',
        sizes: [728, 90],
      },
      aps: {
        slotName: '/176070532/news_other_pc_bnr',
        sizes: [[728, 90]],
      },
    },
    news_other_pc_3rd_rec: {
      divId: 'div-gpt-news_other_pc_3rd_rec',
      gpt: {
        unitCode: '/176070532/news_other_pc_3rd_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_other_pc_3rd_rec',
        sizes: [[300, 250]],
      },
    },
    news_other_pc_4th_rec: {
      divId: 'div-gpt-news_other_pc_4th_rec',
      gpt: {
        unitCode: '/176070532/news_other_pc_4th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_other_pc_4th_rec',
        sizes: [[300, 250]],
      },
    },
    news_other_pc_5th_rec: {
      divId: 'div-gpt-news_other_pc_5th_rec',
      gpt: {
        unitCode: '/176070532/news_other_pc_5th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_other_pc_5th_rec',
        sizes: [[300, 250]],
      },
    },
    news_other_pc_6th_rec: {
      divId: 'div-gpt-news_other_pc_6th_rec',
      gpt: {
        unitCode: '/176070532/news_other_pc_6th_rec',
        sizes: [300, 250],
      },
      aps: {
        slotName: '/176070532/news_other_pc_6th_rec',
        sizes: [[300, 250]],
      },
    },
    news_other_pc_bigrec: {
      divId: 'div-gpt-news_other_pc_bigrec',
      gpt: {
        unitCode: '/176070532/news_other_pc_bigrec',
        sizes: [300, 600],
      },
      aps: {
        slotName: '/176070532/news_other_pc_bigrec',
        sizes: [[300, 600]],
      },
    },
    news_other_pc_overlay: {
      divId: 'div-gpt-news_other_pc_overlay',
      gpt: {
        unitCode: '/176070532/news_other_pc_overlay',
        sizes: [728, 90],
  
      },
      aps: {
        slotName: '/176070532/news_other_pc_overlay',
        sizes: [[728, 90]],
      },
    },
  };
};
