import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import getDate from '../../../common/getDate';
import getBroadcasterData from '../../../common/getBroadcasterData';
import lineClamp from '../../../common/lineClamp';

const COMPANY_NAME = '日本テレビ放送網株式会社';
const PROFILE_PAGE_DATE_CREATED = '2024-08-28T11:00:00+09:00';
// 他の構造化データのエンティティを変更したら都度更新
const PROFILE_PAGE_DATE_MODIFIED = '2024-08-28T11:00:00+09:00';

/**
* Author構造化データ生成
*/
const getAuthors = (context, item, broadcasterData) => {
  const isEachCompany = _.get(context, 'routeHandler.isEachCompany', false); // 各社UIであるか
  const authors = _.get(item, 'authorInfos', []);
  const mergedAuthors = _.compact(_.concat(
    _.get(authors, 'program'),
    _.get(authors, 'group'),
    _.get(authors, 'person')
  )) ?? [];

  // Authorの名前
  const getAuthorName = (author) => {
    if (_.get(author, 'name')) {
      return _.get(author, 'name');
    }
    if (!isEachCompany) {
      // TRENTA UIの場合
      return COMPANY_NAME;
    }
    return _.get(broadcasterData, 'company_name', '');
  }

  // AuthorのURL
  const getAuthorUrl = () => {
    if (!isEachCompany) {
      // TRENTA UIの場合
      return 'https://www.ntv.co.jp/info/outline/outline.html';
    }
    return _.get(broadcasterData, 'author.url', '');
  }

  // Authorの画像URL
  const getAuthorImage = () => {
    if (!isEachCompany) {
      // TRENTA UIの場合
      return 'https://www.ntv.co.jp/assets/images/logo/ntv_black.svg';
    }
    return _.get(context, 'models.hosts.data.ssr_host', '') + _.get(broadcasterData, 'img_src.each_svg', '');
  }

  // AuthorのID
  const getAuthorId = () => {
    if (!isEachCompany) {
      // TRENTA UIの場合
      return 'https://www.ntv.co.jp/';
    }
    return _.get(broadcasterData, 'author.id', '');
  }

  // Author構造化データ本体
  if (_.isEmpty(mergedAuthors)) {
    return {
      '@id': getAuthorId(),
      '@type': 'Organization',
      name: getAuthorName(),
      url: getAuthorUrl(),
      image: getAuthorImage()
    }
  }
  return _.compact(_.map(mergedAuthors, (author) => {
    const authorType = _.get(author, 'type') === 'person' ? 'Person' : 'Organization';
    const name = getAuthorName(author);
    const url = getAuthorUrl(author);
    const image = getAuthorImage(author);
    return {
      '@id': getAuthorId(),
      '@type': authorType,
      name,
      url,
      image
    }
  }))
}

/**
 * 記事構造化データpublisher
 */
const getPublisher = (context, item, broadCast) => {
  const isEachCompany = _.get(context, 'routeHandler.isEachCompany', false); // 各社UIであるか
  const defaultSchema = {
    '@id': '',
    '@type': 'Organization',
    name: '',
    url: '',
    logo: {
      '@type': 'ImageObject',
      contentUrl: '',
      width: 600,
      height: 60,
    }
  }
  const publisherId = !isEachCompany ? 'https://news.ntv.co.jp' : `https://news.ntv.co.jp/n/${_.get(broadCast, 'title')}`;
  const publisherName = !isEachCompany ? '日テレNEWS NNN' : _.get(broadCast, 'nnn');
  const publisherUrl = !isEachCompany ? 'https://news.ntv.co.jp/pages/about' : publisherId;
  const imageUrl = !isEachCompany ? 'https://news.ntv.co.jp/images/logo.svg' : _.get(context, 'models.hosts.data.ssr_host', '') + _.get(broadCast, 'img_src.e_m_svg', '');
  _.set(defaultSchema, '@id', publisherId);
  _.set(defaultSchema, 'name', publisherName);
  _.set(defaultSchema, 'url', publisherUrl); // 各社UIは@idと同じ値
  _.set(defaultSchema, 'logo.contentUrl', imageUrl);
  return defaultSchema;
}

/**
 * 各ページ構造化タグ生成コンポーネント
 * 処理が長くなったのでコンポーネントに切り出し
 * 資料ページ：https://ntv-tech.backlog.com/view/TRENTA_PLAY_NTV-870
 */
class GetPageSchemaObjects extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    // Organization構造化タグを出し分ける対象UI一覧
    this.targetPageList = [
      'articleDetail',
      'ArticleDetailContentImages',
      'ArticleDetailContentImagesEachCompany',
      'new',
      'home',
      'companyHome',
      'ranking',
      'live',
      'special',
      'specialdetail',
      'info',
      'InformationContentDetail',
      'infoEachCompany',
      'InformationContentDetailEachCompany',
    ];
  }

  /**
   * 記事構造化データ
   */
  generateArticleSchemaObject = () => {
    const { item, canonical, image } = this.props;
    const defaultSchema = {
      '@context': 'http://schema.org',
      '@type': 'NewsArticle',
      inLanguage: 'ja',
      headline: '',
      description: '',
      datePublished: '',
      dateModified: '',
      genre: '',
      keywords: '',
      mainEntityOfPage: '',
      author: '',
      publisher: '',
    }
    const date = getDate(item);
    const companyCode = _.get(this.context.routeHandler, 'params.companyCode', 'n24');
    const broadCast = getBroadcasterData(companyCode);
    const updatedAt = _.get(item, 'updated_at', '');
    _.set(defaultSchema, 'headline', lineClamp(_.get(item, 'title'), 55));
    _.set(defaultSchema, 'description', lineClamp(_.get(item, 'summary'), 100));
    _.set(defaultSchema, 'datePublished', dayjs(date).tz().format());
    _.set(defaultSchema, 'dateModified', _.get(item, 'latest_delivery_version_date', updatedAt));
    _.set(defaultSchema, 'genre', _.get(item, 'categoryTags', ['']));
    _.set(defaultSchema, 'keywords', _.get(item, 'hashTag', ['']));
    _.set(defaultSchema, 'mainEntityOfPage', {
      '@type': 'WebPage',
      '@id': _.get(this.context, 'models.hosts.data.ssr_host') + canonical,
    });
    _.set(defaultSchema, 'author', getAuthors(this.context, item, broadCast));
    _.set(defaultSchema, 'publisher', getPublisher(this.context, item, broadCast));
    if (image) {
      _.set(defaultSchema, 'image', {
        '@type': 'ImageObject',
        url: image,
      });
    }
    return defaultSchema;
  }

  /**
   * パンくず構造化データ
   */
  generateBreadCrumbSchema = () => {
    const { item, canonical } = this.props;
    const categoryTag = _.get(item, 'categoryTag');
    const categoryCode = _.get(item, 'categoryCode');
    const title = _.get(item, 'title');
    if (!categoryTag || !categoryCode || !title) {
      return null;
    }
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: '日テレNEWS NNN',
          item: _.get(this.context, 'models.hosts.data.ssr_host'),
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: categoryTag,
          item: `${_.get(this.context, 'models.hosts.data.ssr_host')}/category/${item.categoryCode}`,
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: title,
          item: _.get(this.context, 'models.hosts.data.ssr_host') + canonical,
        },
      ],
    };
  }

  /**
   * Organization構造化データ（１つ目）
   */
  generateOrganizationFirstSchema = () => {
    const companyCode = _.get(this.context, 'routeHandler.params.companyCode', 'n24');
    const broadcasterData = getBroadcasterData(companyCode);
    const isEachCompany = _.get(this.context, 'routeHandler.isEachCompany', false); // 各社UIであるか
    const uiView = _.get(this.context, 'routeHandler.uiView');
    const isTargetUiView = _.includes(this.targetPageList, uiView); //出し分け対象のUIであるか
    const name =
      !isTargetUiView ? '日テレNEWS NNN' :
      (isTargetUiView && !isEachCompany) ? '日テレNEWS NNN' : _.get(broadcasterData, 'nnn', '');
    const id = 
      !isTargetUiView ? 'https://news.ntv.co.jp/' : 
      (isTargetUiView && !isEachCompany) ? 'https://news.ntv.co.jp/' : `https://news.ntv.co.jp/n/${_.get(broadcasterData, 'title')}`;
    const url =
      !isTargetUiView ? 'https://news.ntv.co.jp/pages/about' : 
      (isTargetUiView && !isEachCompany) ? 'https://news.ntv.co.jp/pages/about' : `https://news.ntv.co.jp/n/${_.get(broadcasterData, 'title')}`;
    const imageUrl = 
      !isTargetUiView ? 'https://news.ntv.co.jp/images/logo.svg' :
      (isTargetUiView && !isEachCompany) ? 'https://news.ntv.co.jp/images/logo.svg' : _.get(this.context, 'models.hosts.data.ssr_host', '') + _.get(broadcasterData, 'img_src.e_m_svg', '');

    return {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      '@id': id,
      'url': url,
      'name': name,
      'logo': {
        '@type': 'ImageObject',
        'url': imageUrl,
      },
    }
  }

  /**
   * Organization構造化データ（２つ目）
   */
  generateOrganizationSecondSchema = () => {
    const companyCode = _.get(this.context, 'routeHandler.params.companyCode', 'n24');
    const broadcasterData = getBroadcasterData(companyCode);
    const isEachCompany = _.get(this.context, 'routeHandler.isEachCompany', false); // 各社UIであるか
    const uiView = _.get(this.context, 'routeHandler.uiView');
    const isTargetUiView = _.includes(this.targetPageList, uiView); //出し分け対象のUIであるか
    const name =
      !isTargetUiView ? '日本テレビ放送網株式会社' :
      (isTargetUiView && !isEachCompany) ? '日本テレビ放送網株式会社' : _.get(broadcasterData, 'company_name', '');
    const id = 
      !isTargetUiView ? 'https://www.ntv.co.jp/' : 
      (isTargetUiView && !isEachCompany) ? 'https://www.ntv.co.jp/' : _.get(broadcasterData, 'author.id', '');
    const url = 
      !isTargetUiView ? 'https://www.ntv.co.jp/info/outline/outline.html' :
      (isTargetUiView && !isEachCompany) ? 'https://www.ntv.co.jp/info/outline/outline.html' : _.get(broadcasterData, 'author.url', '');
    const imageUrl = 
      !isTargetUiView ? 'https://www.ntv.co.jp/assets/images/logo/ntv_black.svg' :
      (isTargetUiView && !isEachCompany) ? 'https://www.ntv.co.jp/assets/images/logo/ntv_black.svg' : _.get(this.context, 'models.hosts.data.ssr_host', '') + _.get(broadcasterData, 'img_src.each_svg', '');
    
    return {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      '@id': id,
      'name': name,
      'url': url,
      'image': imageUrl,
    }
  }

  /**
   * WebSite構造化データ
   */
  generateWebSiteSchema = () => {
    const companyCode = _.get(this.context, 'routeHandler.params.companyCode', 'n24');
    const broadcasterData = getBroadcasterData(companyCode);
    const isEachCompany = _.get(this.context, 'routeHandler.isEachCompany', false); // 各社UIであるか
    const name = !isEachCompany ? '日テレNEWS NNN' : _.get(broadcasterData, 'nnn', '');
    const url = !isEachCompany ? 'https://news.ntv.co.jp' : `https://news.ntv.co.jp/n/${_.get(broadcasterData, 'title')}`;
    // const id = _.get(this.context, 'models.hosts.data.ssr_host') + _.get(this.context, 'routeHandler.url', '');
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      // '@id': id,
      'name': name,
      'alternateName': name,
      'url': url,
      'potentialAction': {
        '@type': 'SearchAction',
        'target': 'https://news.ntv.co.jp/search?q={search_term}',
        'query-input': 'required name=search_term'
      },
    }
  }

  /**
   * ProfilePage構造化データ
   */
  generateProfilePageSchema = () => {
    return {
      '@context': 'http://schema.org',
      '@type': 'ProfilePage',
      'dateCreated': PROFILE_PAGE_DATE_CREATED,
      'dateModified': PROFILE_PAGE_DATE_MODIFIED,
      mainEntity: {
        '@type': 'Organization',
        'name': COMPANY_NAME,
        'alternateName': '日テレ',
        'identifier': 'NTV'
      },
      'image': 'https://news.ntv.co.jp/images/logo.svg',
      'sameAs': 'https://www.ntv.co.jp/info/outline/outline.html'
    };
  }
}

export default GetPageSchemaObjects;
