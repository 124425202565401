import React from 'react';
import PropTypes from 'prop-types';
import url from 'url';
import _ from 'lodash';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { isResizable } from '../../../common/preloadImages';

import * as DOMUtils from './../../../sketch-platform/utils/DOMUtils';

const getSrc = (props, context) => {
  if (_.isEmpty(props.url)) return;
  const src = props.url;
  const backgroundImageSize = _.get(context, 'backgroundImageSize') || _.get(props, 'backgroundImageSize');

  // backgroundImageSize指定なし / timageもしくはgimage 以外の場合は、srcをそのまま出力
  if (!backgroundImageSize || !isResizable(src)) return src;

  // サイズ指定
  const urlParse = url.parse(props.url, true);
  if (!_.isEmpty(_.get(urlParse, 'query.size'))) {
    urlParse.query.size = backgroundImageSize;
  } else {
    const [w, h] = backgroundImageSize.split('x');
    urlParse.query.w = w;
  }
  delete urlParse.search;
  return url.format(urlParse);};

class BackgroundImage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.imageRef = React.createRef();
    this.handleEvent = _.throttle(this.handleEvent.bind(this), 300, {leading: true, trailing: true});
    const browserInfo = context.getModelData('browserInfo');
    this.usingLazyLoad = true;
    if (_.get(props, 'disableLazyLoad')) {
      this.usingLazyLoad = false;
    }
    this.state = {
      show: (!browserInfo.isIOS && !browserInfo.isAndroid),
      src: getSrc(props, context),
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      this.timer = window.requestAnimationFrame(this.handleEvent);
    }
    this.imageEl = new Image();
    this.imageEl.onload = () => {
      if (!this._isMounted) return;
      if (this.state.src !== this.imageEl.src) {
        this.setState({src: this.imageEl.src});
      }
      this.imageEl.onload = null;
      this.imageEl.onerror = null;
    };
    this.imageEl.onerror = () => {
      if (!this._isMounted) return;
    };
    this.loadImage();
  }

  componentWillUnmount() {
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      if (this.timer) window.cancelAnimationFrame(this.timer);
      delete this.timer;
    }
  }

  loadImage() {
    if (!this.state.src) return;
    this.imageEl.originalSrc = this.state.src;
    this.imageEl.src = this.imageEl.originalSrc;
  }

  handleEvent() {
    if (this.state.show) return;
    const el = this.imageRef.current;
    if (el) {
      const {width, height} = DOMUtils.getWindowRect();
      const {top, left} = DOMUtils.getRect(el);
      if (width + width/2 > left && height + height/2 > top) {
        if (this.timer) window.cancelAnimationFrame(this.timer);
        delete this.timer;
        this.setState({show: true});
      }
    }
    this.timer = window.requestAnimationFrame(this.handleEvent);
  }

  render() {
    const newProps = _.omit(this.props, 'children', 'url', 'altText', 'backgroundImageSize', 'disableLazyLoad',);
    let url = null;
    if (!_.isEmpty(this.state.src) && this.state.show && this.props.imageTag) {
      return url = this.state.src
    }
    if (this.props.imageTag) {
      return (
        <img ref={this.imageRef} src={url} alt={this.props.altText ? this.props.altText : null} />
      )
    } else {
      if (this.usingLazyLoad) {
        return (
          <LazyLoadComponent
            delayTime={50}
            placeholder={<div className={_.get(newProps, 'className')}>{this.props.children}</div>}
          >
            <div ref={this.imageRef} {...newProps}>
              <img src={this.state.src} alt={this.props.altText ? this.props.altText : null} />
              {this.props.children}
            </div>
          </LazyLoadComponent>
        );
      } else {
        return (
          <div ref={this.imageRef} {...newProps}>
            <img src={this.state.src} alt={this.props.altText ? this.props.altText : null} />
            {this.props.children}
          </div>
        );
      }
    }
  }
}

BackgroundImage.contextTypes = {
  getModelData: PropTypes.func,
  backgroundImageSize: PropTypes.string,
};

export default BackgroundImage;
