import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PagerBlock from './PagerBlock';
import { withModalContext } from '../../../common/context/ModalContext';
import BackgroundImage from '../../../common/components/BackgroundImage';
import MainViewLink from '../../../common/components/MainViewLink';
import url from 'url';
import { MAX_SIZES_IMAGE, MAX_WIDTH_IMAGE } from '../../../../constants/config'

class ThumbnailList extends Component {
  static propTypes = {
    wrapperPublicity: PropTypes.bool,
    articleImage: PropTypes.bool,
  };

  static contextTypes = {
    history: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.imageSlider = this.imageSlider.bind(this);
    this.renderImageSlider = this.renderImageSlider.bind(this);
    // 記事広告の場合は最後の画像はバナーとして扱うので、画像に表示されないように取り除く
    const images = this.props.params.categoryCode === "sponsored" ? this.props.images.slice(0, this.props.images.length - 1) : this.props.images;

    let currentIndex = 1;
    if (_.get(props, 'current')) {
      currentIndex = Number(props.current);
    }
    this.current = currentIndex;

    if (this.props.articleImage) {
      this.state = {
        currentIndex: currentIndex,
        mainImg: images[currentIndex - 1],
        imgs: images,
        lastIndex: images.length
      };
    } else {
      this.state = {
        currentIndex: currentIndex,
        mainImg: images[currentIndex - 1],
        imgs: images,
        lastIndex: images.length
      };
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    const current = Number(_.get(props, 'current'));
    if (current != state.currentIndex) {
      return {
        currentIndex: current,
        mainImg: _.get(props, `images.${current - 1}`),
      };
    }
    return null;
  }

  renderImageSlider() {
    let items = [];

    const to = _.get(this.props, 'to');
    const params = _.get(this.props, 'params');
    items = _.compact(
      _.map(_.get(this.state, 'imgs') || [], (img, index) => {
        if (!img || !_.get(img, 'url')) return;

        const queryParams = { p: index + 1 };
        const className = index === (this.state.currentIndex - 1) ? "imglist-itemblock-link active" : "imglist-itemblock-link"

        return (
          <div className="imglist-itemblock" title={img.caption || ''} key={`thumbnailList_container_${index}`}>
            <MainViewLink
              key={`thumbnailList_${index}`}
              to={to}
              params={params}
              query={queryParams}
              className={className}
            >
              <div className="imglist-item">
                <p className="imglist-caption">{img.caption || ''}</p>
                <BackgroundImage
                  className="artwork slider"
                  url={img.url}
                  backgroundImageSize={MAX_SIZES_IMAGE}
                  key={`thumbnails${index}`}
                />
              </div>
            </MainViewLink>
          </div>
        );
      }),
    );

    return items;
  }

  imageSlider(index) {
    const image = _.get(this.state, ['imgs', index - 1]);
    if (image) {
      this.setState({ mainImg: image, currentIndex: index });
    }
  }

  render() {
    let mainImgUrl = _.get(this.state, 'mainImg.url');

    if (!mainImgUrl) return null;

    mainImgUrl = url.parse(mainImgUrl, true);
    if (mainImgUrl) {
      mainImgUrl.query.w = MAX_WIDTH_IMAGE;
    }
    mainImgUrl = url.format(mainImgUrl);
    if (this.props.articleImage) {
      return (
        <div className="articleDetail-contentImages">
          <div className="articleDetail-contentImages-screen">
            <img
              id="imgmain"
              src={mainImgUrl}
              key={`article_images_${_.get(this.state, 'currentIndex')}`}
              alt={_.get(this.state, 'mainImg.caption') || ''}
            />
            <p className="articleDetail-contentImages-caption" id="imgmain-caption">
              {_.get(this.state, 'mainImg.caption')}
            </p>
          </div>
          <div className="articleDetail-contentImages-pager">
            {_.get(this.state, 'lastIndex', 0) > 1 && (
              <PagerBlock
                imglist={true}
                items={_.get(this.state, 'imgs')}
                currentPage={_.get(this.state, 'currentIndex')}
                onClickPager={this.imageSlider}
                to={_.get(this.props, 'to')}
                params={_.get(this.props, 'params')}
                lastPage={_.get(this.state, 'lastIndex')}
              />
            )}
          </div>
          <div className="articleDetail-contentImages-view-all-button">
            <MainViewLink
              className="button primary large radius"
              to={_.get(this.props, 'articleTo')}
              params={_.get(this.props, 'articleParams')}
            >
              この記事を読む
            </MainViewLink>
          </div>
          <div className="articleDetail-contentImages-imglist">{this.renderImageSlider()}</div>
        </div>
      );
    } else {
      return (
        <div className="articleDetail-contentImages">
          <div className="articleDetail-contentImages-screen">
            <img
              id="imgmain"
              src={mainImgUrl}
              key={`article_images_${_.get(this.state, 'currentIndex')}`}
              alt={_.get(this.state, 'mainImg.caption') || ''}
            />
            <p className="articleDetail-contentImages-caption" id="imgmain-caption">
              {_.get(this.state, 'mainImg.caption') || ''}
            </p>
          </div>
          <div className="articleDetail-contentImages-pager">
            {_.get(this.state, 'lastIndex', 0) > 1 && (
              <PagerBlock
                imglist={true}
                items={_.get(this.state, 'imgs')}
                currentPage={_.get(this.state, 'currentIndex')}
                onClickPager={this.imageSlider}
                to={_.get(this.props, 'to')}
                params={_.get(this.props, 'params')}
                lastPage={_.get(this.state, 'lastIndex')}
              />
            )}
          </div>
          <div className="articleDetail-contentImages-view-all-button">
            <MainViewLink
              className="button primary large radius"
              to={_.get(this.props, 'articleTo')}
              params={_.get(this.props, 'articleParams')}
            >
              この記事を読む
            </MainViewLink>
          </div>
          <div className="articleDetail-contentImages-imglist">{this.renderImageSlider()}</div>
        </div>
      );
    }
  }
}

export default withModalContext(ThumbnailList);
