import _ from 'lodash';
import qs from 'query-string';

function getSchema(path) {
  return _.endsWith(path, '://') ? path : `${path.replace(/[\/|:]/g, '')}://`;
}

function getPath(path) {
  let ret = path;
  if (!_.startsWith(ret, '/')) {
    ret = `/${ret}`;
  }
  return ret;
}
function getQuery(path) {
  const ret = qs.stringify(path);
  return ret ? `?${ret}` : '';
}
function getHash(path) {
  if (_.isUndefined(path) || _.isEmpty(path)) {
    return '';
  }
  let ret = path;
  if (!_.startsWith(ret, '#')) {
    ret = `#${ret}`;
  }
  return ret;
}

const create = function(options) {
  const doc = typeof document !== 'undefined' ? document : {};
  const protocol =
    options.protocol || _.get(doc, 'location.protocol', 'https:');
  const hostname = options.hostname || _.get(doc, 'location.hostname', '');
  return [
    getSchema(protocol),
    hostname,
    getPath(options.path || '/'),
    getQuery(options.query || ''),
    getHash(options.hash),
  ].join('');
};

const createRelative = function(options) {
  return [
    getPath(options.path || '/'),
    getQuery(options.query || ''),
    getHash(options.hash),
  ].join('');
};

const locationToConfig = function(options) {
  let query;
  if (options.search === '') {
    query = {};
  } else {
    const queryMap = options.search.replace(/^\?/, '').split('&');
    query = _.reduce(
      queryMap,
      (result, value) => {
        const map = value.split('=');
        if (map.length === 2) {
          result[map[0]] = map[1];
        } else if (map.length === 1) {
          result[map[0]] = null;
        }
        return result;
      },
      {},
    );
  }

  return {
    protocol: getSchema(options.protocol),
    hostname: options.hostname,
    path: getPath(options.pathname),
    hash: getHash(options.hash),
    query: e,
  };
};

const requestToConfig = function(options) {
  return {
    protocol: 'https:',
    hostname: typeof document !== 'undefined' ? document.location.hostname : '',
    path: getPath(_.result(options, 'getPath', '/')),
    query: options.query || '',
  };
};

export default {
  create,
  createRelative,
  locationToConfig,
  requestToConfig,
};
