import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import Share from './Share';
import dayjs from 'dayjs';
import BackgroundImage from "../../../common/components/BackgroundImage";
import nl2br from "../../../../common/react-nl2br";
import classnames from "classnames";
import Player from "./Player";
import getImage from "../../../../common/getImage";
import * as ERROR from "../../../../constants/error";
import {NotFoundError} from "../../../common/components/ErrorBoundary";
import lineClamp from '../../../../common/lineClamp';
import url from "url";
import Axios from '../../../../common/Axios';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import routes from '../../../common/routes';
import removeTag from '../../../../common/react-removeTag';
import { resizeLargeImages } from '../../../../common/preloadImages';
import DummyPlayButton from '../player/DummyPlayButton';

class LocalDetailContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    isSpaError: PropTypes.func,
    getModelData: PropTypes.func,
    projectRefIdMapping: PropTypes.object,
  };

  static defaultProps = {
    viewPlayerBtn: true
  }

  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);

    return [rootPath];
  };

  static getRootPath = function(models, options, props) {
    return ['article', 'localDetail', props.id];
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function(models, options, props) {
    const self = this;
    return (prefetchResult) => {
      const rootPath = self.getRootPath(models, null, props);
      const item = _.get(prefetchResult, ['json'].concat(rootPath));
      if (!item) return { error: ERROR.NOTFOUND };
      return null;
    };
  };

  constructor(props, context) {
    super(props, context);

    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.item = this.model.getSync(rootPath);

    this.state = {
      isLoadVideo: false,
      viewPlayerBtn: props.viewPlayerBtn,
    };
    this.mediaObject = this.mediaObject.bind(this);
    this.handlePlayButton = this.handlePlayButton.bind(this);
    this.isStreaksPlayer = this.isStreaksPlayer.bind(this);
    this.axios = new Axios({ xhr: true });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // StreaksPlayerを使用するか
  // this.item.videoのIDが32桁ならSTREAKSのUUID判定とする
  isStreaksPlayer(videoId) {
    if (!videoId || videoId.length !== 32) {
      return false;
    } else {
      // UUIDに記号は含まれないので記号があればSTREAKSのIDではない
      const reg = new RegExp(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/, 'g');
      if (reg.test(videoId)) {
        return false;
      } else {
        return true;
      }
    }
  }

  // メディアキーセット
  mediaObject() {
    if (!_.get(this.item, 'media')) {
      this.item['media'] = { media_id: this.item.video };
    }
  }

  // 通常記事のように再生したときにSTREAKSのメディアクエリ生成
  handlePlayButton() {
    const self = this;
    if (_.get(self.item, 'video') && _.get(self.state, 'viewPlayerBtn') && !self.state.isLoadVideo) {
      try {
        const projectRefIdMapping = _.get(self.context, 'projectRefIdMapping');
        const providerId = _.get(self.item, 'provider_id', 'ntv');
        // ---- 開発用コード（rabで固定する）
        const projectRefId = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'preview' ? 'rab-news' : _.get(projectRefIdMapping, providerId);
        // ---- 本番用コード
        // const projectRefId = _.get(projectRefIdMapping, providerId);

        if (!projectRefId) {
          throw new Error(`対応するプロジェクト参照IDがありません。providerId: ${providerId}`)
        }
        // ---- 開発用コード（本番の"playback.api.streaks.jp"に向ける）
        const hostname = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'preview' ? 'playback.api.streaks.jp' : self.context.getModelData('services', 'playback', 'hostname');
        // ---- 本番用コード
        // const hostname = self.context.getModelData('services', 'playback', 'hostname');
        const playbackService = {
          name: 'papi',
          protocol: 'https',
          hostname: hostname,
          path: ['v1', 'projects', projectRefId, 'medias'],
        };
        playbackService.pathname = _.join(_.concat(playbackService.path, self.item.media.media_id), '/');
        this.axios.get(url.format(playbackService), {}, {}).then(result => {
          const media = _.get(result, 'data');
          if (media) {
            self.item.media = _.merge({}, self.item.media, media);
            self.setState({
              isLoadVideo: true,
              viewPlayerBtn: false,
            });
          }
        });
      } catch (e) {
        console.error('handlePlayButton event error : ', e);
      }
    }
  }


  render() {
    const { isLoadVideo } = this.state;
    const bloadcaster = getBroadcasterData(_.get(this.item, 'provider_id'));
    const img_src = bloadcaster.img_src.l;

    let spBlock;
    let pcBlock;
    const browserInfo = this.context.getModelData('browserInfo');

    if (!this.item) {
      if (_.get(this.context, 'isSpaError')) {
        this.context.isSpaError();
      }
      throw new NotFoundError();
    }

    let date = _.get(this.item, 'pubDate');
    date = date && date.replace(/\./g, "-");

    // 地方局TYPELINE導入に伴いブラコからSTREAKSに切り替えする記事の振り分け
    const isStreaks = this.isStreaksPlayer(_.get(this.item, 'video'));
    // STREAKSの場合はitemにmediaキーを追加して通常の記事の時と同じようにmedia_idを入れ込む
    if (isStreaks) {
      this.mediaObject();
    }

    // preload imageの設定
    if (_.get(this.item, 'video') && isStreaks) {
      const articleImage = this.item.thumbnail || _.get(this.item, 'media.poster.src');
      // streaks player時のみ、w指定あり
      if (articleImage) {
        this.props.setPreloadImages(resizeLargeImages(articleImage, _.get(this.context, 'spMode')));
      }
    } else {
      const articleImage = this.item.thumbnail || getImage({ itemData: this.item, local: true }, this.context);
      if (articleImage) this.props.setPreloadImages(articleImage);
    }

    spBlock = (
      <React.Fragment>
        <article className="player-contents">
        <div className='player-title articleDetail-content-header'>
            <h1 className="player-title title player-detail-title">{browserInfo.isIE ? lineClamp(_.get(this.item, 'title'), 56) : _.get(this.item, 'title')}</h1>
            <div className="articleDetail-content-header-categories-meta-bottom">
              <div className='articleDetail-content-header-categories-meta-bottom-logo'>
                <img src={img_src} className="card-logo" alt={bloadcaster.label} />
              </div>
              <div className="articleDetail-content-header-categories-meta-bottom-date">
                {dayjs(date).format('YYYY年M月D日 H:mm')}
              </div>
            </div>
          </div>
          {_.get(this.item, 'video') ?
            <div className="player-block">
              {isStreaks ? (
                <React.Fragment>
                  {isLoadVideo ? (
                    <Player type={'streaks'} article={this.item} item={_.get(this.item, 'media')} image={{url: this.item.thumbnail }}/>
                  ) : (
                    <div className={classnames('player-block',{'handle-play-button': _.get(this.state, 'viewPlayerBtn')})} onClick={this.handlePlayButton} >
                      <BackgroundImage
                        backgroundImageSize={_.get(this.context, 'spMode') ? '400x200' : '700x400'}
                        disableLazyLoad={true}
                        className="artwork articles-thumbnail"
                        url={this.item.thumbnail}
                        altText={this.item.title}
                      />
                      <DummyPlayButton viewPlayerBtn={_.get(this.state, 'viewPlayerBtn')} />
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <Player type={'brightcove'} data={this.item} isLocal={true} />
              )}
            </div>
            :
            <div className="player-block">
              <BackgroundImage className="artwork articles-thumbnail" url={getImage({itemData: this.item, local: true}, this.context)} key={"local"} altText={_.get(this.item, 'title')} />
            </div>
          }
          <p className="player-text">{nl2br(_.get(this.item, 'contents', ''))}</p>
        </article>
        <div className='list-json-detail'>
          <Share title={_.get(this.item, 'title')} routeHandler={this.props.routeHandler} />
        </div>
      </React.Fragment>
    );

    pcBlock = (
      <React.Fragment>
        <article className="player-contents">
          <div className='player-title articleDetail-content-header'>
            <h1 className="player-title title player-detail-title">{browserInfo.isIE ? lineClamp(_.get(this.item, 'title'), 56) : _.get(this.item, 'title')}</h1>
            <div className="articleDetail-content-header-categories-meta-bottom">
              <div className='articleDetail-content-header-categories-meta-bottom-logo'>
                <img src={img_src} className="card-logo" alt={bloadcaster.label} />
              </div>
              <div className="articleDetail-content-header-categories-meta-bottom-date">
                {dayjs(date).format('YYYY年M月D日 H:mm')}
              </div>
            </div>
          </div>
          {_.get(this.item, 'video') ?
            <div className={classnames('player-block',{'articles-thumbnail-img': !_.get(this.item, 'video')})}>
              {isStreaks ? (
                <React.Fragment>
                  {isLoadVideo ? (
                    <Player type={'streaks'} article={this.item} item={_.get(this.item, 'media')} image={{url: this.item.thumbnail }}/>
                  ) : (
                    <div className={classnames('player-block',{'handle-play-button': _.get(this.state, 'viewPlayerBtn')})} onClick={this.handlePlayButton} >
                      <BackgroundImage
                        backgroundImageSize={_.get(this.context, 'spMode') ? '400x200' : '700x400'}
                        disableLazyLoad={true}
                        className="artwork articles-thumbnail"
                        url={this.item.thumbnail}
                        altText={this.item.title}
                      />
                      <DummyPlayButton viewPlayerBtn={_.get(this.state, 'viewPlayerBtn')} />
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <Player type={'brightcove'} data={this.item} isLocal={true} />
              )}
            </div>
            :
            <div className={classnames('player-block',{'articles-thumbnail-img': !_.get(this.item, 'video')})}>
              <BackgroundImage className="artwork articles-thumbnail" url={getImage({itemData: this.item, local: true}, this.context)} key={"local"} altText={_.get(this.item, 'title')} />
            </div>
          }
          <p className="player-text">{nl2br(_.get(this.item, 'contents', ''))}</p>
        </article>
        <div className='list-json-detail'>
          <Share title={_.get(this.item, 'title')} routeHandler={this.props.routeHandler} />
        </div>
      </React.Fragment>
    );

    let image = getImage({itemData: this.item, local: true}, this.context);
    if (image) {
      const urlParse = url.parse(image, true);
      if (!_.get(urlParse, 'query.w')) {
        urlParse.query.w = 630;
      }
      image = url.format(urlParse);
    }

    const title = `${_.get(this.item, 'title')}｜日テレNEWS NNN`;
    const $firstParagraph = this.item.contents.split('<br><br>')[0];
    const firstParagraph = removeTag($firstParagraph);

    const canonical = `/nnn/${_.get(this.props, 'id')}`;
    const customCanonical = _.get(this.item, 'newsPageUrl');
    let unviewCanonical = false;
    if (!customCanonical) {
      unviewCanonical = true;
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(title) });
            metas.push({ property: 'og:type', content: 'article' });
            if (_.get(this.item, 'contents')) {
              metas.push({ name: 'description', content: firstParagraph });
              metas.push({ property: 'og:description', content: firstParagraph });
            }
            metas.push({ property: 'ntvnews-recommendable-article', content: 'false' });
            metas.push({ property: 'og:image', content: image });
            const providerID = bloadcaster.title !== "n24" ? bloadcaster.title.toUpperCase() : 'NTV';
            metas.push({ name: 'popIn:category', content: providerID })

            const linkTags = this.props.getPreloadImages();

            return <Helmet
              title={title}
              meta={metas}
              link={linkTags}
            />;
          }}
        </HtmlContext.Consumer>
        {this.context.spMode ? spBlock : pcBlock }
        <Analytics
          pageTitle={title}
          path={canonical}
          customCanonical={customCanonical}
          unviewCanonical={unviewCanonical}
          env={_.get(this.context, 'models.config.data.env')}
        />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(LocalDetailContent);
root.getPaths = LocalDetailContent.getPaths;
root.getRootPath = LocalDetailContent.getRootPath;
root.getPrefetchPaths = LocalDetailContent.getPrefetchPaths;
root.afterPrefetch = LocalDetailContent.afterPrefetch;
export default root;
