import React from 'react';
import { wrapDisplayName } from 'recompose';

const LayoutContext = React.createContext();
export default LayoutContext;
export const withLayoutContext = (Component) => {
  const withLayoutContext = React.forwardRef((props, ref) => {
    return (
      <LayoutContext.Consumer>
        {(context) => React.createElement(Component, { ref, ...props, ...context })}
      </LayoutContext.Consumer>
    );
  });
  withLayoutContext.displayName = wrapDisplayName(Component, 'withLayoutContext');
  return withLayoutContext;
};
