import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import HeadlineContent from './HeadlineContent';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import { getCardResizeImage } from '../../../../common/preloadImages';
import TaboolaHomeContent from './TaboolaHomeContent';
const PERPAGE = 10;

class CompanyHomeContent extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
  };

  static getRootPath = function(models, options, props) {
    // _.get(props, 'companyCode')で今見ている放送局IDが取得できる
    // console.log("provider_id: ", _.get(props, 'companyCode'));
    const companyCode =  _.get(props, 'companyCode')
    return ['article', 'headline', companyCode, 'eachCompany']; // 正規版
  };

  static getPaths = function(models, options, props) {
    let paths = [];

    const indexes = function(models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum
            : options && options.numTitles
            ? options.numTitles + (props.fromNum || 0)
            : PERPAGE - 1,
      };
    };

    const rootPath = this.getRootPath(models, options, props);

    paths = paths.concat([
      [
        indexes(models, options, props),
        ['id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
      ],
    ]);

    return _.map(paths, path => rootPath.concat(path));
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  constructor(props, context) {
    super(props, context);

    const rootPath = this.constructor.getRootPath(context.models, {}, props);

    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.items = this.model.getSync(rootPath);

    this.state = {
      dispose: null,
      fetchDataError: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.items) {
      this.fetchData(this.model, this.props);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
  }

  fetchData(model, props) {
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      this.state.dispose();
    }
    this.state[JSON.stringify(paths)] = paths;
    const evaluator = model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then(res => {
        this.items = _.get(res, ['json'].concat(rootPath));

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    let items = this.items;
    if (items) {
      if (items && _.size(items)) {
        const displayCnt = this.context.spMode ? PERPAGE - 2 : PERPAGE;
        items = _.slice(_.values(items), 0, displayCnt);

        // preload imageの設定
        const artcileImages = _.compact(
          _.map(
            _.filter(items, e => e && e.source_systems),
            (itemData, i) => getCardResizeImage({ itemData, maincard: i === 0 }, this.context),
          ),
        );
        if (!_.isEmpty(artcileImages)) this.props.setPreloadImages(artcileImages);
      }
    }

    const canonical = `/`;
    const domain = _.get(this.context, 'models.hosts.data.domain_url');
    const pageUrl = _.get(this.context, 'models.hosts.data.domain_url') + _.get(this.props, 'routeHandler.url');

    const bloadcaster = getBroadcasterData(_.get(this.context.routeHandler.params, 'companyCode', 'n24'));
    const img_src = bloadcaster.img_src.e_m;

    const titleTag = `${bloadcaster.label}のニュース・${bloadcaster.area}のニュース｜${bloadcaster.nnn}`;
    const description = `${bloadcaster.label}のニュースサイト「${bloadcaster.nnn}」は${bloadcaster.area}の最新ニュースや話題のトピックを配信しています。`;
    return (
      <React.Fragment key="home-content">
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: titleTag});
            metas.push({ property: 'og:type', content: 'website' });
            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={titleTag} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        <div className="headlineModule">
          {this.context.routeHandler.isEachCompany && (
            <div className="headlineModule-header">
              <div className="headlineModule-header-left">
                <h1 className="headlineModule-header-img">
                  <img src={img_src} className="card-logo" alt={`${bloadcaster.nnn}｜${bloadcaster.label}のニュース・${bloadcaster.area}のニュース`} />
                </h1>
              </div>
            </div>
          )}
          <HeadlineContent items={items}/>
          {/* Taboola */}
          <TaboolaHomeContent
            isSpMode={this.context.spMode}
            uiView={_.get(this.context, 'routeHandler.uiView')}
          />
          <div className="headlineModule-readmore">
            <MainViewLink
              to={routes.newEachCompany}
              params={{companyCode: _.get(this.context.routeHandler, 'params.companyCode') ,categoryCode: 'all'}}
              >
              新着記事へ
              <i className="fa-article-title-arrow" />
            </MainViewLink>
          </div>
        </div>
        <Analytics pageTitle={'トップページ'} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(CompanyHomeContent);
root.getPaths = CompanyHomeContent.getPaths;
root.getRootPath = CompanyHomeContent.getRootPath;
root.getPrefetchPaths = CompanyHomeContent.getPrefetchPaths;
export default root;
