import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import MainViewLink from './MainViewLink';

const iconNameMap = {
  "ヘッドライン": "fa-hot",
  "新着": "fa-latest",
  "地域": "fa-pin",
  "ライブ": "fa-live"
};

class HeaderMainMenu extends Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    history: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  /**
  * リンクオブジェクト生成
  */
  createMenuLinks = (items) => {
    return _compact(_map(items, (item, i) => {
      const url = _get(item, 'link_url');
      const name = _get(item, 'title');
      const iconCode = _get(iconNameMap, name);
      const currentPath = _get(this.context.routeHandler, 'path');

      if (url && name) {
        let currentFlag = false;
        if (currentPath.startsWith('/new')) {
          currentFlag = url.startsWith('/new');
        } else {
          currentFlag = url === currentPath;
        }
        return (
          <MainViewLink key={`global-nav-menu-${currentPath}-${i}`} className={classnames("global-navigation-item", { current: currentFlag })} href={url}>
            {iconCode && (<i className={iconCode} />)}
            <span>{name}</span>
          </MainViewLink>
        );
      }
    }));
  }

  render() {
    const trentaHeaderMenu = _get(this.props, 'menuItem', []);

    if (_isEmpty(trentaHeaderMenu)) return null;
    return (
      <div className="global-navigation" >
        <div className="global-navigation-inner">
          {this.createMenuLinks(trentaHeaderMenu)}
        </div>
      </div>
    )
  }
}

export default HeaderMainMenu;