import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import ScrollMenu from './ScrollMenu';
import categoryCodeToName from '../../../../common/categoryCode2Name';
import * as ERROR from '../../../../constants/error';
import removeTag from '../../../../common/react-removeTag';
import { getCardResizeImage } from '../../../../common/preloadImages';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import TaboolaWidget from '../../../common/components/TaboolaWidget';

class RankingContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    topText: PropTypes.string
  };

  static getPaths = function (models, options, props) {
    let paths = [];
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv')

    // 総合ランキングのみ20位まで出す
    let range = {
      from: 0,
      to: 9,
    };
    if (_.get(props, 'categoryCode') === 'all' && !_.get(props, 'companyCode')) {
      range.to = 19
    }

    paths = paths.concat([
      ['link', companyCode, 'nnn_ranking_menu'],
      [
        'article',
        'ranking',
        companyCode,
        _.get(props, 'categoryCode', 'all'),
        range,
        ['article_id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
      ],
    ]);

    return paths;
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function (models, options, props) {
    return (prefetchResult) => {
      const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');
      const rootPath = ['article', 'ranking', companyCode, _.get(props, 'categoryCode', 'all')];
      let list = _.get(prefetchResult, ['json'].concat(rootPath));
      list = _.compact(
        _.map(_.values(_.omit(list), ['$__path', 'length']), (item) => {
          if (_.isEmpty(_.get(item, 'source_systems.id'))) {
            return;
          }
          return item;
        }),
      );
      const categoryCodes = _.keys(_.get(models, 'config.data.categoryTags.code2name', {}));
      const categoryCode = _.get(props, 'categoryCode');
      if ((!list || !list.length) && (!_.includes(categoryCodes, categoryCode) || categoryCode === 'all'))
        return { error: ERROR.NOTFOUND };
      return null;
    };
  };

  constructor(props, context) {
    super(props, context);
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv')

    this.categoryQuery =
      categoryCodeToName(_.get(props, 'categoryCode'), _.get(context, 'models.config.data')) || '総合';
    this.categoryCode = _.get(props, 'categoryCode', 'all');

    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.items = this.model.getSync(`article.ranking.${companyCode}.${this.categoryCode}`);


    const link = this.model.getSync(['link', 'ntv']);
    this.rankingMenu = _.get(link, 'nnn_ranking_menu');

    this.state = {
      dispose: null,
      fetchDataError: null,
      loading: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.items) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
  }

  fetchData(props = this.props, context = this.context) {
    const paths = this.constructor.getPaths(context.models, {}, props);
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv')

    if (this.state.dispose) {
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;
    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    this.setState({ loading: true });

    const rootPath = `article.ranking.${companyCode}.${this.categoryCode}`;

    evaluator
      .then((res) => {
        this.items = _.get(res, `json.${rootPath}`, {});
        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          loading: false,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch((e) => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    if (!this.items) return null;
    let items = [];
    let articleImages = [];
    items = _.compact(
      _.map(this.items || [], (itemData, index) => {
        if (!itemData || !itemData.source_systems) return;
        articleImages.push(getCardResizeImage({ itemData, ranking: true }, this.context));
        return (
          <TitleCard
            key={`ranking_card_${index}_${itemData.source_systems.id}`}
            itemData={itemData}
            ranking={true}
            rankingPage={true}
            rankingNumber={parseInt(index) + 1}
          />
        );
      }),
    );
    if (!_.isEmpty(articleImages)) this.props.setPreloadImages(articleImages);

    let spBlock;
    let pcBlock;

    const domain = _.get(this.context, 'models.hosts.data.domain_url');
    const pageUrl = _.get(this.context, 'models.hosts.data.domain_url') + _.get(this.context, 'routeHandler.url');
    const isEachCompany = _.get(this.context.routeHandler, 'isEachCompany');
    const spMode = _.get(this.context, 'spMode');
    const limitTime = _.get(this.context, `models.config.data.categoryTags.rankingLimit.${this.categoryCode}`);
    const limitTimeText = `※過去${!isEachCompany ? limitTime : 48}時間以内に配信した記事のアクセス数を集計したランキングです。`

    spBlock = (
      <div className="rankingContent">
        <h2 className="contents-title">ランキング</h2>
        {
          _.get(this.context.routeHandler, 'path').startsWith('/ranking') &&
          <ScrollMenu rankingMenu={true} list={this.rankingMenu} />
        }
        <div className="rankingContent-body">
          {items}
          {_.isEmpty(items) && (
            <p className='ranking-noresult'>該当コンテンツが存在しません。</p>
          )}
          <div key={`ranking_taboola_${Date.now()}`}>
            <TaboolaWidget target="below1" />
          </div>
          <p className='gray-comment'>{limitTimeText}</p>
        </div>
      </div>
    );

    pcBlock = (
      <div className="rankingContent">
        <h2 className="contents-title">ランキング</h2>
        {
          _.get(this.context.routeHandler, 'path').startsWith('/ranking') &&
          <ScrollMenu rankingMenu={true} list={this.rankingMenu} />
        }
        <div className="rankingContent-body">
          {items}
          {_.isEmpty(items) && (
            <p className='ranking-noresult'>該当コンテンツが存在しません。</p>
          )}
          <div key={`ranking_taboola_${Date.now()}`}>
            <TaboolaWidget target="below1" />
          </div>
          <p className='gray-comment'>{limitTimeText}</p>
        </div>
      </div>
    );

    const canonical = `/ranking/${_.get(this.props, 'categoryCode')}`;

    const topText = _.get(this.context, 'topText');
    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const bloadcaster = getBroadcasterData(company)
    const titleTag = company === 'n24'
                    ? `ニュースランキング｜総合｜${topText}`
                    : `ニュースランキング｜${bloadcaster.label}のニュース・${bloadcaster.area}のニュース｜${bloadcaster.nnn}`;
    let description;
    if (this.categoryQuery === '総合') {
      if (company === 'n24') {
        description = '日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」の総合アクセスランキングです。政治、経済、国際、社会、スポーツ、カルチャー・芸能・エンタメ、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。';
      } else {
        description = `${bloadcaster.label}のニュースサイト「${bloadcaster.nnn}」のアクセスランキングです。`;
      }
    } else {
      const category = _.find(this.rankingMenu, { title: this.categoryQuery });
      description = removeTag(category.content);
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];
            const linkTags = this.props.getPreloadImages();

            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });
            if (this.categoryQuery === '総合') {
              metas.push({ property: 'og:title', content: shortTitle(titleTag) });
              return <Helmet title={titleTag} meta={metas} link={linkTags} />;
            }
            metas.push({
              property: 'og:title',
              content: shortTitle(`ニュースランキング｜${this.categoryQuery}｜${topText}`),
            });
            metas.push({ property: 'og:type', content: 'article' });
            return <Helmet title={`ニュースランキング｜${this.categoryQuery}｜${topText}`} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        {this.context.spMode ? spBlock : pcBlock}
        <Analytics
          pageTitle={`${this.categoryQuery}ランキングトップ`}
          path={canonical}
          env={_.get(this.context, 'models.config.data.env')}
        />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(RankingContent);
root.getPaths = RankingContent.getPaths;
root.getPrefetchPaths = RankingContent.getPrefetchPaths;
root.afterPrefetch = RankingContent.afterPrefetch;
export default root;
