import { pathToRegexp, compile } from 'path-to-regexp';
import URLGenerator from './URLGenerator';
import _ from 'lodash';

// URLエンコード処理済文字列 判定
const isEncodedStr = (str) => {
  const urlSymbolsRegExp = /[\/#\?]+?/; // URL影響の記号（#, /, ?）は、エンコード必須対象
  try {
    if (str.match(urlSymbolsRegExp)) return false; // デコード前の文字列にURL影響の記号を含む場合は、エンコード対象

    // デコード後、URL影響の記号を含んでいた場合、エンコード済として返す（多重エンコードとなるため条件分岐）
    const decodedStr = decodeURIComponent(str);
    if (decodedStr.match(urlSymbolsRegExp)) return true;

    // デコードした文字列と異なる場合、エンコード済 判定
    return str !== decodedStr;
  } catch (e) {
    // デコードエラーとなった場合、エンコード対象でない（decodeできない）または、特殊な状態のためエンコード対象外とする
    console.log('[Route] isEncodedStr ERROR: ', e);
    return false;
  }
};

export default {
  create: function(options) {
    const hostname = options.hostname;
    const protocol = options.protocol;
    const exact = options.exact || false;
    const path = options.path;
    const pathDecode = options.pathDecode || false;
    const _keys = [];
    const _regex = pathToRegexp(path, _keys);
    const _pathMaker = compile(path);

    return {
      _keys,
      _regex,
      _pathMaker,
      match: (route) => {
        const regex = _regex.exec(route);
        if (!regex) return null;
        const results = {};
        const keys = _keys;
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          const pattern = regex[index + 1];
          if (!!pattern && !results[key.name]) {
            results[key.name] = decodeURIComponent(pattern);
          }
        }
        return results;
      },
      makePath:(params, query, hash) => {
        if (hostname) {
          return makeAbsolutePath({
            query,
            hash,
            params,
          });
        }

        let path;
        try {
          // 特集・ハッシュタグリンクあり＆URIエンコード済みでない場合もエンコード対象
          if (_.get(params, 'tagName') && !isEncodedStr(_.get(params, 'tagName', ''))) {
            params.tagName = encodeURIComponent(params.tagName);
          }

          path = _pathMaker(params);
          if (pathDecode) {
            path = decodeURIComponent(path);
          }
        } catch (e) {
          e.message += ` for ${path}`;
          throw e;
        }
        const createdUrl = URLGenerator.createRelative({
          path,
          query,
          hash,
        });
        return createdUrl;
      },

      makeAbsolutePath: (route = {}) => {
        let path;
        try {
          path = _pathMaker(route.params);
        } catch (e) {
          e.message += ` for ${path}`;
          throw e;
        }

        const createdUrl = URLGenerator.create({
          protocol: route.protocol || protocol,
          hostname: route.hostname || hostname,
          path,
          query: route.query,
          hash: route.hash,
        });

        return createdUrl;
      }
    };
  }
};
