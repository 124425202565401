import React from 'react';
import PropTypes from 'prop-types';
import TitleCard from './TitleCard';
import _isEmpty from 'lodash/isEmpty';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _includes from 'lodash/includes';

/**
 * 同じカテゴリーの新着記事先頭6件
 */
class SameCategoryNewer extends React.Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.items = props.items ?? [];
    this.generateArticleCards = this.generateArticleCards.bind(this);
    this.getCategoryName = this.getCategoryName.bind(this);
  }

  // 記事カードの生成
  generateArticleCards(items = []) {
    if (_isEmpty(items)) {
      return null;
    }

    return _compact(
      _map(items, (itemData, index) => {
        if (!itemData || !itemData.source_systems) return;
        if (index < 6) {
          return (
            <TitleCard
              key={`same_category_new_article_card_${index}`}
              itemData={itemData}
              default={true}
              isNtvHeadline={true}
            />
          )
        }
      })
    );
  }

  /**
   *  カテゴリ名取得。
   * YTV,CTVは芸能カテゴリが存在するためentertainmentをcultureに変換する
   */
  getCategoryName(code2name = {}, categiryCode = '') {
    if (_isEmpty(code2name) || !categiryCode) {
      console.error('Failed to retrieve information required to display category names.');
      return '';
    }
    const companyCode = _get(this.context, 'routeHandler.params.companyCode');
    if (categiryCode === 'entertainment' && _get(this.context, 'routeHandler.isEachCompany') && _includes(['ytv', 'ctv'], companyCode)) {
      return companyCode === 'ctv' ? 'エンタメ' : '芸能';
    }
    return _get(code2name, categiryCode, '');
  }

  render() {
    const code2name = _get(this.context, 'models.config.data.categoryTags.code2name', {});
    const propsCategoryCode = _get(this.props, 'categoryCode', '');
    const categoryName = this.getCategoryName(code2name, propsCategoryCode);
    const items = this.items;

    // 記事広告の場合、記事がない場合、カテゴリー名が取得できない場合は表示しない
    if (propsCategoryCode === "sponsored" || _isEmpty(items) || !categoryName) {
      return null;
    }

    return (
      <div className="featureArticles">
        <div className="featureArticles-title">「{categoryName}」の新着記事</div>
        <div className="featureArticles-content">
          <div className="news-list-items grid">{this.generateArticleCards(items)}</div>
        </div>
      </div>
    );
  }
}

export default SameCategoryNewer;
