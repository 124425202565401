'use strict';

import React from 'react';

const urlRegex = /<a(?: .+?)?>.*?<\/a>/g;

export default function str2a(str) {
  if (!str) {
    return str;
  }
  if (!str.length) {
    return str;
  }
  if (!str.map) {
    return str;
  }

  const result = str.map((line, index) => {
    if (typeof line === 'string' && line.match(urlRegex)) {
      return <span key={`str2a-${index}`} dangerouslySetInnerHTML={{ __html: line }} />
    } else {
      return line;
    }
  });

  return result;
};
