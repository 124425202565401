import axios from 'axios';
import _ from 'lodash';
import qs from 'query-string';
// import * as http from 'http';
// import * as https from 'https';

class Axios {
  constructor(options = {}) {
    this.options = options;

    this.api = axios.create(_.assign({responseType: 'json'}, options));

    // タイムアウトしていないのにタイムアウトエラーがでる対応ととして
    // retryオプションを使えるようにする
    // https://github.com/axios/axios/issues/164#issuecomment-327837467
    if (options.retry) {
      const _axios = this.api;

      // retry時はconfigのfilterが困難だったので、
      // urlとmethodだけ出力する
      const paramsFilter = function(config) {
        return JSON.stringify({
          url: _.get(config, 'url'),
          method: _.get(config, 'method'),
        });
      }

      _axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        var config = err.config;
        // If config does not exist or the retry option is not set, reject
        if(!config || !config.retry) return Promise.reject(err);

        // Set the variable for keeping track of the retry count
        config.__retryCount = config.__retryCount || 0;

        // Check if we've maxed out the total number of retries
        if(config.__retryCount >= config.retry) {
            // Reject with the error
            return Promise.reject(err);
        }

        // Increase the retry count
        config.__retryCount += 1;
        // Create new promise to handle exponential backoff
        var backoff = new Promise(function(resolve) {
            setTimeout(function() {
                resolve();
            }, config.retryDelay || 1);
        });

        // Return the promise in which recalls axios to retry the request
        return backoff.then(function() {
          return _axios(config);
        });
      });
    }
  }

  fetch(config, cancel=false) {
    // nodejsでreq.xhr == true で判断するためにはヘッダを付与する必要があるため
    // optionsでxhr:trueを渡すと自動でヘッダが付与されるようにしている
    if (this.options.xhr) {
      _.set(config, 'headers["X-Requested-With"]', 'XMLHttpRequest');
    }

    const CancelToken = axios.CancelToken;
    if (cancel) {
      let cancelSource;
      const req = this.api(Object.assign({}, {
        cancelToken: new CancelToken(function (c) {
          cancelSource = c;
        })
      }, config));
      return [req, cancelSource];
    } else {
      return new Promise((resolve, reject) => {
        this.api(config).then(function() {
          resolve.apply(this, arguments);
        }).catch((e) => {
          // エラー時はリクエスト内容をログ出力する
          const statusCode = _.get(e, 'response.status');
          // 429: too many request
          if (!statusCode || 500 <= parseInt(statusCode) || 429 === parseInt(statusCode)) {
            const params = this.paramsFilter(config);
            console.error("AxiosRequestError::", params);
            console.log(e);
          }

          reject(e);
        })
      });
    }
  }

  get(url, params = {}, options={}, cancel=false) {
    const queryString = qs.stringify(params);
    if (queryString && queryString.length > 0) {
      if (url.indexOf('?') >= 0) {
        url += '&' + queryString;
      } else {
        url += '?' + queryString;
      }
    }
    return this.fetch(Object.assign({}, {method: 'get', url: url}, options), cancel).then((res) => {
      return {data: res.data};
    });
  }

  post(url, data, options={}, cancel=false) {
    return this.fetch(Object.assign({}, {method: 'post', url: url, data: data}, options), cancel).then((res) => { return {data: res.data} });
  }

  patch(url, data, options={}, cancel=false) {
    return this.fetch(Object.assign({}, {method: 'patch', url: url, data: data}, options), cancel).then((res) => { return {data: res.data} });
  }

  del(url, options={}, cancel=false) {
    return this.fetch(Object.assign({}, {method: 'delete', url: url}, options), cancel).then((res) => { return {data: res.data} });
  }

  put(url, data, options={}, cancel=false) {
    return this.fetch(Object.assign({}, {method: 'put', url: url, data: data}, options), cancel).then((res) => { return {data: res.data}});
  }

  formPost(url, data, options={}, cancel=false) {
    return this.post(url, data, options, cancel).then((res) => { return {data: res.data} });
  }

  multi(requests) {
    return axios.all(requests).then(axios.spread((...response) => {
      return response;
    }));
  }

  paramsFilter(params) {
    let _params = {};
    if (typeof _.get(this, 'options.paramsFilter') === 'function') {
      _params = this.options.paramsFilter(params);
    } else {
      _params = params;
    }
    return JSON.stringify(_params);
  }
}

export default Axios;
