import React, { Component } from 'react';
import NotFound from '../../generic/components/errors/NotFound';
import Congestion from '../../generic/components/errors/Congestion';
import InternalServerError from '../../generic/components/errors/InternalServerError';
import {withLayoutContext} from '../context/LayoutContext';

export const NotFoundError = function (message) {
  this.message = message;
  var last_part = new Error().stack.match(/[^\s]+$/);
  this.stack = `${this.name} at ${last_part}`;
};
Object.setPrototypeOf(NotFoundError, Error);
NotFoundError.prototype = Object.create(Error.prototype);
NotFoundError.prototype.name = 'NotFoundError';
NotFoundError.prototype.message = '';
NotFoundError.prototype.constructor = NotFoundError;

export const ErrorBoundaryNotFound = NotFoundError;

export const CongestionError = function (message) {
  this.message = message;
  var last_part = new Error().stack.match(/[^\s]+$/);
  this.stack = `${this.name} at ${last_part}`;
};
Object.setPrototypeOf(CongestionError, Error);
CongestionError.prototype = Object.create(Error.prototype);
CongestionError.prototype.name = 'CongestionError';
CongestionError.prototype.message = '';
CongestionError.prototype.constructor = CongestionError;

export const ErrorBoundaryCongestionError = CongestionError;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeHandler &&
      prevProps.routeHandler &&
      this.props.routeHandler.path != prevProps.routeHandler.path) {
      this.setState({ hasError: false });
    }
  }
  componentDidCatch(error, info) {
    let isOutBrainError = false;
    if (error && error.toString().includes('removeChild')) {
      isOutBrainError = true;
      console.log("outbrainError");
      console.log(error);
      console.log(_.get(info, 'componentStack'));
    }
    if (this.props.overlayHeaderNavigation) this.props.overlayHeaderNavigation(false);
    if (!isOutBrainError) {
      this.setState({ hasError: true, error: error });
    } else {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.state.error instanceof NotFoundError) {
        return <NotFound />;
      } else if (this.state.error instanceof CongestionError) {
        return <Congestion />;
      } else {
        return <InternalServerError />;
      }
    }
    return this.props.children;
  }
}

export default withLayoutContext(ErrorBoundary);
