import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import _ from 'lodash';
import * as ERROR from '../../../../constants/error';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import { MAX_WIDTH_IMAGE } from '../../../../constants/config';

class SemiStaticPages extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    routeHandler: PropTypes.object,
    topText: PropTypes.string,
  };

  static getPaths = function (models, options, props) {
    const rootPath = this.getRootPath(models, options, props);

    return [rootPath];
  };

  static getRootPath = function (models, options, props) {
    const jsonFileName = _.get(props, 'jsonFileName');
    return ['static', 'pages', jsonFileName];
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function (models, options, props) {
    const self = this;
    return (prefetchResult) => {
      const rootPath = self.getRootPath(models, null, props);
      const item = _.get(prefetchResult, ['json'].concat(rootPath));
      if (!item || !_.get(item, 'html')) return { error: ERROR.NOTFOUND };
      return null;
    };
  };

  constructor(props, context) {
    super(props, context);

    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.item = this.model.getSync(rootPath);
    this.page = _.get(this.props, 'jsonFileName');

    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getOgImage() {
    const defaultOgImage = `${_.get(this.context, 'models.hosts.data.host')}/images/og_images.png`;
    return _.get(this.item, 'ogimage')? _.get(this.item, 'ogimage') : defaultOgImage;
  }

  render() {
    const topText = _.get(this.context, 'topText');
    const title = `${_.get(this.item, 'title')}｜${topText}`;
    const description = _.get(this.item, 'description');
    const html = _.get(this.item, 'html');
    const ogImage = this.getOgImage();

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];
            metas.push({ property: 'og:title', content: shortTitle(title) });
            metas.push({ property: 'og:image', content: ogImage });
            metas.push({ property: 'og:image:width', content: MAX_WIDTH_IMAGE });
            metas.push({ property: 'og:image:height', content: (MAX_WIDTH_IMAGE / 16) * 9 });
            if (description) {
              metas.push({ name: 'description', content: description });
              metas.push({ property: 'og:description', content: description });
            }
            return <Helmet title={title} meta={metas} />;
          }}
        </HtmlContext.Consumer>
        <div className="staticPage" dangerouslySetInnerHTML={{ __html: html }} />
        <Analytics pageTitle={title} path={`/pages/${this.page}`} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(SemiStaticPages);
root.getPaths = SemiStaticPages.getPaths;
root.getRootPath = SemiStaticPages.getRootPath;
root.getPrefetchPaths = SemiStaticPages.getPrefetchPaths;
root.afterPrefetch = SemiStaticPages.afterPrefetch;
export default root;
