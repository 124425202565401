'use strict';
import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import _findKey from 'lodash/findKey';
import getBroadcasterData from './getBroadcasterData';

/**
 * 各局ジャンル（カテゴリー）管理JSONファイル利用
 * genresList => /static/genres_config.json <Object> JSON
 * {
 *  provider_code(ntv) : {
 *    genreId: {
 *      "name": <string>,         e.g. "politics"
 *      "label": <string>,        e.g. "政治"
 *      "created_at": <string>,   e.g. "2023-07-31T20:41:51+09:00"
 *      "timestamp": <float>,     e.g. 1690803711.161264
 *    }
 *  },
 *  ...
 * }
 */

// model: env/enviroments/*.js -> genres <Object>
/**
 * genresキー配列から、カテゴリー名の取得
 * @param { Array|String } genres Genre Keys
 * @param { Object } config Model
 * @returns { String } Genre(Category) name
 */
export const getGenres2Name = (genres, config, providerCode = 'ntv') => {
  if (!genres || !config) return;
  if (typeof genres === 'string') genres = [genres];
  const genresConfig = _get(config, `genresList.${providerCode}`);
  return _get(genresConfig, [_get(genres, [0]), 'label']);
};

/**
 * genresキー配列から、カテゴリー名の配列取得
 * @param { Array|String } genres Genre Keys
 * @param { Object } config Model
 * @param { String } providerCode e.g. ntv, stv, ...
 * @returns { Array } Genre(Category) [code]
 */
export const getGenres2Names = (genres, config, providerCode = 'ntv') => {
  if (!genres || !config) return null;
  if (typeof genres === 'string') genres = [genres];
  const genresConfig = _get(config, `genresList.${providerCode}`);
  return _compact(_map(genres, genre => _get(genresConfig, [genre, 'label'], '')));
};

/**
 * genresキー配列から、カテゴリーコード（はじめの一つ）の取得
 * @param { Array|String } genres Genre Keys
 * @param { Object } config Model
 * @returns { String } Genre(Category) code
 */
export const getGenres2Code = (genres, config, providerCode = 'ntv') => {
  if (!genres || !config) return null;
  if (typeof genres === 'string') genres = [genres];
  const genresConfig = _get(config, `genresList.${providerCode}`);
  return _get(genresConfig, [_get(genres, [0]), 'name'], '');
};

/**
 * genresキー配列から、カテゴリーコードの配列取得
 * @param { Array|String } genres Genre Keys
 * @param { Object } config Model
 * @returns { Array } Genre(Category) [code]
 */
export const getGenres2Codes = (genres, config, providerCode = 'ntv') => {
  if (!genres || !config) return null;
  if (typeof genres === 'string') genres = [genres];
  const genresConfig = _get(config, `genresList.${providerCode}`);
  return _compact(_map(genres, genre => _get(genresConfig, [genre, 'name'], '')));
};

/**
 * genresキー配列から、カテゴリー名・コードの取得
 * @param { Array|String } genres Genre Keys
 * @param { Object } config Model
 * @returns { Object } {name, code}
 */
export const getGenre2NameCode = (genres, config, providerCode = 'ntv') => {
  if (!genres || !config) return null;
  if (typeof genres === 'string') genres = [genres];
  const genresConfig = _get(config, `genresList.${providerCode}`);
  return _get(genresConfig, [_get(genres, [0])], '');
};

/**
 * タグ名・コードから、genresのkeyを取得
 * @param { Object } config Model
 * @param { String } category name|code
 * @returns { String|undefined } Genre Key
 */
export const getCategory2Genre = (config, category, providerCode = 'ntv') => {
  if (!category) return;
  const genres = _get(config, 'categoryTags.genres');
  const genresConfig = _get(config, `genresList.${providerCode}`);
  return _findKey(genres, { name: category }) || _findKey(genres, { code: category });
};

/**
 * タグ名・コードから、genresのkeyを取得
 * @param { Object } genres genres list
 * @param { String } category name|code
 * @returns { String|undefined } Genre Key
 */
export const getCategory2GenresList = (genresList, category, providerCode = 'ntv') => {
  if (!category) return;
  const genresConfig = _get(genresList, providerCode);
  return _findKey(genresConfig, { name: category }) || _findKey(genresConfig, { code: category });
};

/**
 * 記事データ（source_systems）から配信局コードを取得する
 * @param { Object } article name|code
 * @returns { String } providerCode  e.g. ntv, stv, ...
 */
export const getArticle2ProviderCode = (article) => {
  if (!article) return 'ntv';
  const { title } = getBroadcasterData(_get(article, 'source_systems.project', 'n24'));
  const providerCode = title === 'n24' ? 'ntv' : title;
  return providerCode.toLowerCase();
};

export default {
  getGenres2Name,
  getGenres2Names,
  getGenres2Code,
  getGenres2Codes,
  getGenre2NameCode,
  getCategory2Genre,
  getCategory2GenresList,
  getArticle2ProviderCode,
};
