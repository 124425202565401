'use strict';

import React from 'react';

const urlRegex = /<\/?[a-z][^>]*>/gm;

export default function removeTag(str) {
  if (typeof str === 'number') {
    return str;
  } else if (typeof str !== 'string') {
    return '';
  }

  return str.replace(urlRegex, '').trimEnd();
};
