import warning from 'warning';
import invariant from 'invariant';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';

export default function routing() {
  return function provideRoutingContext(WrappedComponent) {
    class Router extends Component {
      constructor(props, context) {
        super(props, context);
        this.state = {
          match: this.computeMatch(props.history.location.pathname),
        };

        const { children, history } = props;

        invariant(
          children == null || React.Children.count(children) === 1,
          'A <Router> may have only one child element',
        );

        this.unlisten = history.listen(() => {
          this.setState({
            match: this.computeMatch(history.location.pathname),
          });
        });
      }

      getChildContext() {
        return {
          routeHandler: this.props.routeHandler,
          history: this.props.history,
        };
      }

      computeMatch(pathname) {
        return {
          path: '/',
          url: '/',
          params: {},
          isExact: pathname === '/',
        };
      }


      componentWillUnmount() {
        this.unlisten();
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    Router.displayName = 'Router';

    Router.childContextTypes = {
      routeHandler: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
    };

    Router.propTypes = {
      routeHandler: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
    };

    return hoistStatics(Router, WrappedComponent);
  };
}
