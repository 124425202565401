import { compose, defaultProps, lifecycle, pure } from 'recompose';
import _ from "lodash";

const CxenseManager = compose(
  pure,
  defaultProps({ pageTitle: '' }),
  lifecycle({
    componentDidMount() {
      const siteId = _.get(this.props, 'siteId');
      const sendPageViewEvent = () => {
        const cX = window.cX || {};
        cX.callQueue = cX.callQueue || [];
        cX.callQueue.push(['setSiteId', siteId]);
        cX.callQueue.push(['sendPageViewEvent']);
      };

      let scripts = document.getElementsByTagName("script");
      if (_.findIndex(scripts, { src: 'https://cdn.cxense.com/cx.js' }) < 0) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://cdn.cxense.com/cx.js';
        document.body.appendChild(script);
        script.onload = () => {
          sendPageViewEvent();
        };
      } else {
        sendPageViewEvent();
      }
    },
  }),
)(() => null);
export default CxenseManager;
