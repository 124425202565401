import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import PagerBlock from './PagerBlock';
import routes from '../../../common/routes';
import { featurePreloadImages } from '../../../../common/preloadImages';
import getImage from '../../../../common/getImage';
import BackgroundImage from '../../../common/components/BackgroundImage';
import MainViewLink from '../../../common/components/MainViewLink';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import TaboolaWidget from '../../../common/components/TaboolaWidget';

const PERPAGE = 5;

/**
 * 特集一覧(TRENTA UI, 各社UI)
 */
class SpecialContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    topText: PropTypes.string,
  };

  static getPaths = function (models, options, props) {
    let paths = [];
    const indexes = function (models, options, props) {
      const page = _.get(props, 'routeHandler.query.p');
      let from, to;
      if (page) {
        from = page * PERPAGE - PERPAGE;
        to = page * PERPAGE - 1;
      }
      return {
        from: from || (props && props.fromNum) || 0,
        to: to
          ? to
          : props && props.toNum
          ? props.toNum
          : options && options.numTitles
          ? options.numTitles + (props.fromNum || 0)
          : PERPAGE - 1,
      };
    };

    const rootPath = this.getRootPath(models, options, props);

    paths = paths.concat([
      [indexes(models, options, props), ['tag_id', 'content', 'thumbnails', 'id', 'custom_data', 'images']],
      ['count'],
      ['current_page'],
    ]);

    return _.map(paths, (path) => rootPath.concat(path));
  };

  static getRootPath = function (models, options, props) {
    const $companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');
    return ['tag', 'feature', $companyCode, 'list'];
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  constructor(props, context) {
    super(props, context);
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');

    this.onClickPager = this.onClickPager.bind(this);

    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.totalCount = this.model.getSync(rootPath.concat('count'));
    this.lastPage = this.totalCount && Math.ceil(this.totalCount / (PERPAGE));
    this.items = this.model.getSync(rootPath);
    if (this.items && Object.keys(this.items) && Object.keys(this.items).length) {
      const firstIndex = Object.keys(this.items)[0];
      this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
    }
    if (this.totalCount % (PERPAGE - 1) === 1) {
      this.lastPage -= 1;
    }
    if (_.get(props.routeHandler, 'params.companyCode') && _.get(props.routeHandler, 'isEachCompany'))
    {
      this.to = routes.featureEachCompany;
      this.params = {companyCode: companyCode};
    } else {
      this.to = routes.featureTrenta;
      this.params = {};
    }

    this.state = {
      dispose: null,
      fetchDataError: null,
      loading: false,
      currentPage: 1,
    };

    this.getFeatureHeaderTitle = this.getFeatureHeaderTitle.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (_.get(this.props, 'routeHandler.query.p') && this.currentPage !== this.props.routeHandler.query.p) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    if (_.get(props, 'routeHandler.query.p') != state.currentPage) {
      return {
        currentPage: _.get(props, 'routeHandler.query.p', 1),
      };
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.state.loading && this.currentPage != this.state.currentPage) {
      this.fetchData(this.props, this.context, { scrollToTop: true });
    }
  }

  onClickPager(page) {
    if (page) {
      const props = Object.assign({}, this.props, {
        fromNum: page * PERPAGE - PERPAGE,
        toNum: page * PERPAGE - 1,
      });
      this.fetchData(props, this.context, { scrollToTop: true });
    }
  }

  fetchData(props = this.props, context = this.context, options = {}) {
    const paths = this.constructor.getPaths(context.models, {}, props);
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');

    if (this.state.dispose) {
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;
    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    this.setState({ loading: true });

    evaluator
      .then((res) => {
        this.items = _.get(res, `json.tag.feature.${companyCode}.list`, {});
        if (this.items) {
          const firstIndex = Object.keys(this.items)[0];
          this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
        }
        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          loading: false,
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);

        if (_.get(options, 'scrollToTop')) {
          window.scrollTo({ top: 0 });
        }
      })
      .catch((e) => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  /**
   * 特集ヘッダータイトル
   */
  getFeatureHeaderTitle() {
    const companyCode = _.get(this.props.routeHandler, 'params.companyCode');
    const isEachCompany = _.get(this.props.routeHandler, 'isEachCompany');
    if (isEachCompany && companyCode) {
      return _.get(getBroadcasterData(companyCode), 'label') + ' 特集';
    } else {
      return '特集';
    }
  }

  render() {
    let items = this.items;
    const currentPage = this.currentPage;
    if (items && _.size(items)) {
      items = _.slice(_.values(items), 0, PERPAGE);
      // preload imageの設定：特集棚出し
      const preloadImages = featurePreloadImages(items, this.context)
      this.props.setPreloadImages(preloadImages);
    }

    const img_src = this.context.models.config.data.logoPath;
    const companyCode = _.get(this.props.routeHandler, 'params.companyCode');
    const isEachCompany = _.get(this.props.routeHandler, 'isEachCompany');
    const detailTo = isEachCompany ? routes.featureDetailEachCompany : routes.featureDetailTrenta;
    const specialData = _.concat(_.map(items, (itemData, index) => {
      if (!itemData || !itemData.feature_id) return;
      if (!itemData.id && itemData.tag_id) {
        itemData.id = itemData.tag_id;
      }
      if (_.get(itemData, 'title')) {
        _.set(itemData, 'value', itemData.title);
      }

      const itemCard = _.compact(
        _.map(itemData.article, (articleData, $index) => {
          if (!articleData || _.isEmpty(articleData)) return null;
          if (this.context.spMode) {
            if ($index < 2) {
              return <TitleCard itemData={articleData} default={true} key={`titlecard-${$index}`} />;
            }
            return null;
          } else {
            return <TitleCard itemData={articleData} default={true} key={`titlecard-${$index}`} />;
          }
        }),
      );

      let allViewLinkParams;
      if (isEachCompany && companyCode) {
        allViewLinkParams = {companyCode: companyCode, tagName: itemData.value};
      } else {
        allViewLinkParams = {tagName: itemData.value};
      }

      return (
        <div className="specialContentItem" key={`feature_notification_${index}`}>
          <div className="specialContentItem-header">
            <div className="specialContentItem-header-left">
              <BackgroundImage
                className="specialContentItem-header-img"
                backgroundImageSize="300x150"
                url={getImage({ itemData, special: true }, this.context)}
                altText={_.get(itemData, 'value')}
              />
              <h2 className="specialContentItem-header-title">{itemData.value}</h2>
            </div>
            <div className="specialContentItem-header-right">
              <MainViewLink to={detailTo} params={allViewLinkParams}>
                すべての記事
                <i className="fa-article-title-arrow"></i>
              </MainViewLink>
            </div>
          </div>
          <div className="specialContentItem-body">
            <div className="news-list-items">{itemCard}</div>
          </div>
        </div>
      );
    }));

    const topText = _.get(this.context, 'topText');
    const domain = _.get(this.context, 'models.hosts.data.domain_url');
    const pageUrl = _.get(this.context, 'models.hosts.data.domain_url') + _.get(this.context, 'routeHandler.url');
    const spMode = _.get(this.context, 'spMode');

    const canonical = `/feature${this.currentPage > 1 ? `?p=${this.currentPage}` : ''}`;
    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const bloadcaster = getBroadcasterData(company)
    const pageIndex = this.currentPage > 1 ? `${this.currentPage}ページ目 ` : '' ;
    const titleTag = company === 'n24'
      ? `${pageIndex}特集｜${topText}`
      : `${pageIndex}特集｜${bloadcaster.nnn}｜${bloadcaster.label}のニュース・${bloadcaster.area}のニュース`;
    const description = company === 'n24'
      ? '日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」の特集一覧です。政治、社会問題、経済、国際、スポーツ、芸能エンタメ・カルチャーなどさまざまなテーマのニュース特集を配信しています。'
      : `${bloadcaster.label}のニュースサイト「${bloadcaster.nnn}」の特集一覧です。${bloadcaster.area}に関するニュースやさまざまなテーマのニュース特集を配信しています。`;

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: shortTitle(titleTag) });
            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });
            metas.push({ property: 'og:type', content: 'article' });

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={titleTag} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        <div className="specialContent">
          <div className="specialContent-header">
            <div className="specialContent-title">
              {!_.get(this.context.routeHandler, 'isEachCompany') && (
                <span className="specialContent-title-logo">
                  <img src={img_src} />
                </span>
              )}
              <h2 className="specialContent-title-text">{this.getFeatureHeaderTitle()}</h2>
            </div>
          </div>
          <div className="specialContent-body">
            {specialData}
            <div key={`feature_taboola_${Date.now()}`}>
              <TaboolaWidget target="below1" />
            </div>
          </div>
          {this.lastPage > 1 && (
            <div className="btn-block">
              {this.context.spMode ? (
                <PagerBlock currentPage={this.currentPage} lastPage={this.lastPage} to={this.to} params={this.params}/>
              ) : (
                <PagerBlock currentPage={this.currentPage} lastPage={this.lastPage} to={this.to} params={this.params} showBtn={4} />
              )}
            </div>
          )}
        </div>
        <Analytics pageTitle={titleTag} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(SpecialContent);
root.getPaths = SpecialContent.getPaths;
root.getRootPath = SpecialContent.getRootPath;
root.getPrefetchPaths = SpecialContent.getPrefetchPaths;
export default root;
