import _ from 'lodash';

export default function getArticleImage(article) {
  let url = _.get(article, 'thumbnail.url');
  let caption = _.get(article, 'thumbnail.caption');
  let copyRight = _.get(article, 'thumbnail.copy_right');
  if (!url) {
    url = _.get(article, 'media.poster.src');
  }
  if (!url) {
    url = _.get(article, 'media.thumbnail.src');
  }
  if (!caption) {
    caption = _.get(article, 'media.caption');
  }
  if (!copyRight) {
    copyRight = _.get(article, 'media.copy_right');
  }
  let image;
  if (url) {
    image = {
      url,
      caption,
      copy_right: copyRight,
    };
  }

  return image;
};
