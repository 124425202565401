import { createRoot } from 'react-dom/client';
import { hydrateRoot } from 'react-dom/client';
import { createPath } from 'history';
import _ from 'lodash';
import url from 'url';
import dayjs from 'dayjs';

import safeTimeout from '../../common/safeTimeout';

class ReactClientRenderer {
  constructor(mountNode, context, options = {}) {
    const logger = context.getLogger();
    this.mountNode = mountNode;
    this.appContext = context;
    this.options = options;
    this.cl = logger;
    this.isPerfAPIAvailable = typeof window !== 'undefined' && window.performance && window.performance.mark;
    this.mounted = false;
    this.mountRoot = null;
    this.retrieveScrollPosition = false;

    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    this.isListeningToHistory = false;

    // Switch off the native scroll restoration behavior and handle it manually
    // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
    this.scrollPositionsHistory = {};
    if (window.history && 'scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    this.currentLocation = context.getHistory().location;

    // inappの場合は定期実行をしない
    const inapp = this.appContext.getModelData('inapp', 'inapp');
    if (!inapp) {
      this.tokenConfig = this.appContext.getModelData('tokenConfig');
    }

    this.handleTokenCheck = this.handleTokenCheck.bind(this);
    this.handleTokenRefresh = this.handleTokenRefresh.bind(this);

    this.isTokenCheckInterval = false;
    this.isTokenRefreshInterval = false;
    this.isFirstTokenRefreshTime = false;

    this.handleTokenCheckStart = this.handleTokenCheckStart.bind(this);
    this.handleTokenRefreshStart = this.handleTokenRefreshStart.bind(this);

    this.isOnbeforeunload = false;
    window.addEventListener('beforeunload', e => {
      this.isOnbeforeunload = true;
    });
  }

  handleHistoryChange({ location, action }) {
    if (this.scrollTimeoutId) {
      clearTimeout(this.scrollTimeoutId);
      delete this.scrollTimeoutId;
    }

    if (this.options.handleHistoryChange && typeof this.options.handleHistoryChange === 'function') {
      this.options.handleHistoryChange(location, action);
    }
    const routeHandler = this.appContext.getState().routeHandler;
    // Remember the latest scroll position for the previous location
    this.scrollPositionsHistory[this.currentLocation.key] = {
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset,
      scrollHeight: document.documentElement.scrollHeight,
      uiView: _.get(routeHandler, 'uiView'),
      index: _.keys(this.scrollPositionsHistory).length + 1,
    };

    // Delete stored scroll position for next page if any
    if (action === 'PUSH') {
      delete this.scrollPositionsHistory[location.key];
    }

    this.currentLocation = location;

    if (action !== 'POP' && _.get(location, 'state.norender')) return;

    this.render(function(err, html) {
      if (err) {
        console.error(err.stack);
      }
    });
  }

  handleTokenCheckStart() {
    if (this.tokenConfig && this.tokenConfig.tokenCheckInterval) {
      safeTimeout(() => {
        this.handleTokenCheck(true);
      }, this.tokenConfig.tokenCheckInterval);
    }
  }

  handleTokenRefreshStart() {
    if (this.tokenConfig && this.tokenConfig.tokenRefreshInterval) {
      safeTimeout(() => {
        this.handleTokenRefresh(true);
      }, this.tokenConfig.tokenRefreshInterval);
    }
  }

  handleTokenCheck(interval = false) {
    if (this.isOnbeforeunload) return;
    try {
      let tokenData = this.appContext.getTokenData();
      if (tokenData && tokenData.token) {
        let service = Object.assign({}, this.appContext.getModelData('services', 'tokenManager'));
        if (!service) return;

        if (tokenData.uuid != this.appContext.uuid || tokenData.isRefresh) {
          if (interval) this.handleTokenCheckStart();
          return;
        }

        service.pathname = _.join(_.concat(service.path, 'token/check'), '/');

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url.format(service), !this.__unloading);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.setRequestHeader('Authorization', `Bearer ${tokenData.token}`);
        xhr.setRequestHeader('X-Token-Id', tokenData.id);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            // console.log(`reactClientRenderer handleTokenCheck response: ${JSON.stringify(xhr.response)}`);
            if (xhr.status === 0) {
              if (interval) this.handleTokenCheckStart();
            }
            if (xhr.status === 200) {
              if (interval) this.handleTokenCheckStart();
            }
            if (xhr.status === 401) {
              if (interval) this.handleTokenCheckStart();
              this.handleTokenRefresh();
            }
          }
        };
        xhr.send();
      }
    } catch (e) {
      console.error(`ReactClientRenderer::handleTokenCheckError: status: ${_.get(e, 'response.status')} data:${JSON.stringify(_.get(e, 'response.data'))}` );
    }
  }

  handleTokenRefresh(interval = false) {
    if (this.isOnbeforeunload) return;
    try {
      let tokenData = this.appContext.getTokenData();
      if (tokenData && tokenData.token) {
        let timecheck = false;

        if (tokenData.time) {
          timecheck = dayjs().diff(dayjs(tokenData.time, 'x'), 'second') < 60;
        }
        if (tokenData.uuid != this.appContext.uuid || tokenData.isRefresh || timecheck) {
          if (interval) this.handleTokenRefreshStart();
          return;
        }

        tokenData.isRefresh = true;
        this.appContext.setTokenData(tokenData);

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/user/token/refresh', !this.__unloading);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 0) {
              tokenData.isRefresh = false;
              this.appContext.setTokenData(tokenData);
              if (interval) this.handleTokenRefreshStart();
            }
            if (xhr.status === 200) {
              let response = JSON.parse(xhr.responseText);
              if (response.result) {
                tokenData.isRefresh = false;
                this.appContext.setTokenData(tokenData);
                if (response.authContext) {
                  // tokenDataStore.setAuthContextData(response.authContext);

                  // this.accessToken = response.authContext.token;
                  // this.retrieveScrollPosition = false;
                  this.render(function(err, html) {
                    if (err) {
                      console.error(err.stack);
                    }
                  });
                }

                if (interval) this.handleTokenRefreshStart();
              } else {
                this.appContext.state.authApp.doLogout();
              }
            }
          }
        };
        xhr.send();
      }
    } catch (e) {
      console.error('ReactClientRenderer::handleTokenRefreshError', e);
    }
  }

  async render(cb) {
    this.currentUrl = window.location.pathname + window.location.search + window.location.hash;
    const currentUrl = this.currentUrl;
    await this.appContext.resolveState(this.currentUrl, (state, routeHandler) => {
      if (this.accessToken) {
        _.set(state, 'model.models.authContext.data.token', this.accessToken);
        delete this.accessToken;
      }
      return state;
    });

    if (currentUrl !== this.currentUrl) return;

    if (!this.isListeningToHistory) {
      this.appContext.getHistory().listen(this.handleHistoryChange);
      this.isListeningToHistory = true;
    }

    if (!this.isTokenCheckInterval) {
      this.isTokenCheckInterval = true;
      this.handleTokenCheckStart();
    }

    if (!this.isTokenRefreshInterval) {
      this.isTokenRefreshInterval = true;
      this.handleTokenRefreshStart();
    }

    if (!this.isFirstTokenRefreshTime) {
      this.isFirstTokenRefreshTime = true;
      if (this.tokenConfig && this.tokenConfig.firstTokenRefreshTime) {
        safeTimeout(() => {
          this.handleTokenRefresh();
        }, this.tokenConfig.firstTokenRefreshTime);
      }
    }

    await this.appContext.resolveElement(async (err, elements) => {
      if (currentUrl !== this.currentUrl) return;
      if (err) {
        return cb(err);
      }

      let paths = [];
      let secondPaths = [];
      const getPrefetchedPaths = [];
      const routeHandler = this.appContext.getState().routeHandler;
      const models = this.appContext.getModels();
      routeHandler.components.forEach((component, i) => {
        const props = Object.assign({}, routeHandler.params, elements.props);
        if (typeof component.getPrefetchPaths === 'function') {
          paths = paths.concat(component.getPrefetchPaths(models, {}, props));
        }
        if (typeof component.getPrefetchedPaths === 'function') {
          getPrefetchedPaths.push(component.getPrefetchedPaths(models, {prefetcheSource: 'client'}, props));
        }
      });
      paths = _.compact(paths);

      if (paths.length > 0) {
        const pathEvaluator = this.appContext.getFalcorModel();
        try {
          let prefetchResult = await pathEvaluator.get.apply(pathEvaluator, paths);
          // 1回目のfetchデータを利用して2回目のfetch
          _.forEach(getPrefetchedPaths, func => {
            secondPaths = secondPaths.concat(func(prefetchResult));
          });
          if (secondPaths.length > 0) {
            await pathEvaluator.get.apply(pathEvaluator, secondPaths);
          }
        } catch (err) {
          console.error(err);
        }
      }

      if (currentUrl !== this.currentUrl) return;
      const self = this;
      if (!this.mounted) {
        if (this.mountRoot == null) {
          this.mountRoot = hydrateRoot(
            this.mountNode,
            this.appContext.provideAppContextToElement(elements)
          );
        }
      } else {
        if (this.mountRoot == null) {
          this.mountRoot = createRoot(this.mountNode);
        }
        this.mountRoot.render(this.appContext.provideAppContextToElement(elements));
      }

      setTimeout(function () {
        self.mounted = true;

        const uiView = _.get(routeHandler, 'uiView');

        let scrollX = 0;
        let scrollY = 0;
        let pos = self.scrollPositionsHistory[self.currentLocation.key];
        if (!pos && ['ArticleDetailContentImages', 'ArticlesContentImages'].includes(uiView)) {
          const histories = _.sortBy(Object.assign({}, self.scrollPositionsHistory), 'index');
          const historyKeys = _.keys(histories);
          const lastHistory = histories[historyKeys[historyKeys.length - 1]];
          if (['ArticleDetailContentImages', 'ArticlesContentImages'].includes(_.get(lastHistory, 'uiView'))) {
            pos = lastHistory;
          }
        }
        if (pos) {
          scrollX = pos.scrollX;
          scrollY = pos.scrollY;
        } else {
          const targetHash = self.currentLocation.hash.substr(1);
          if (targetHash) {
            const target = document.getElementById(targetHash);
            if (target) {
              scrollY = window.pageYOffset + target.getBoundingClientRect().top;
            }
          }
        }

        // 初回SSRで描画される時は処理をさせない
        if (self.retrieveScrollPosition) {
          // Restore the scroll position if it was saved into the state
          // or scroll to the given #hash anchor
          // or scroll to top of the page
          if (document.documentElement.scrollHeight > scrollY) {
            if (window.scrollTo) {
              this.scrollTimeoutId = setTimeout(function () {
                delete this.scrollTimeoutId;
                window.scrollTo(scrollX, scrollY);
              }, 0);
            }
          } else {
            // スクロールをすでにしている場合はこの処理は実行しないほうがいい
            this.scrollTimeoutId = setTimeout(function () {
              delete this.scrollTimeoutId;
              if (window.scrollTo) {
                window.scrollTo(scrollX, scrollY);
              }
            }, 0);
          }
        }
        self.retrieveScrollPosition = true;

        // Google Analytics tracking. Don't send 'pageview' event after
        // the initial rendering, as it was already sent
        try {
          if (window.ga) {
            window.ga('send', 'pageview', createPath(location));
          }
          /*
            // Google Tag Manager
            if (window.dataLayer) {
              window.dataLayer  = window.dataLayer || [];
              window.dataLayer.push({
              });
            }
            */
          if (window.FB && window.FB.XFBML && typeof window.FB.XFBML.parse === 'function') {
            window.FB.XFBML.parse();
          }
        } catch (e) {
          try {
            if (!document.getElementById('fb-root')) {
              const fbRoot = document.createElement('div');
              fbRoot.id = 'fb-root';
              document.body.appendChild(fbRoot);
              if (window.FB && window.FB.XFBML && typeof window.FB.XFBML.parse === 'function') {
                window.FB.XFBML.parse();
              }
            }
          } catch (e) {
          }
        }
        try {
          if (window.twttr && window.twttr.widgets && typeof window.twttr.widgets.load === 'function') {
            // 正常にURLが設定されないので明示的に現在のURLを指定する
            const el = document.getElementById('twitter-share');
            if (el) el.setAttribute('data-url', window.location.href);
            window.twttr.widgets.load();
          }
        } catch (e) {
        }
        cb(null, this);
      }, 0);
    });
  }

  unMount() {
    if (this.mountRoot) {
      // TODO history remove
      this.isListeningToHistory = false;
      this.mounted = false;
      this.mountRoot.unmount();
    }
  }
}

export default ReactClientRenderer;
