import React from 'react';
import { wrapDisplayName } from 'recompose';

const HtmlContext = React.createContext();
export default HtmlContext;
export const withHtmlContext = (Component) => {
  const withHtmlContext = React.forwardRef((props, ref) => {
    return (
      <HtmlContext.Consumer>
        {(context) => React.createElement(Component, { ref, ...props, ...context })}
      </HtmlContext.Consumer>
    );
  });
  withHtmlContext.displayName = wrapDisplayName(Component, 'withHtmlContext');
  return withHtmlContext;
};
