import React from 'react';
import GoogleTagManager from './GoogleTagManager';
import ChartbeatManager from "./ChartbeatManager";

const Analytics = (props) => {
  return (
    <React.Fragment>
      <GoogleTagManager pageTitle={props.pageTitle} />
      <ChartbeatManager
        pageTitle={props.pageTitle}
        categories={props.categories}
        path={props.path}
        canonical={props.customCanonical}
        unviewCanonical={props.unviewCanonical}
        env={props.env}
        abbr={props.abbr}
      />
    </React.Fragment>
  );
};
export default Analytics;
