import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { provideRoutingContext as routing } from '../../../sketch-platform/ui/routing';
import inNode from '../../../sketch-platform/ui/utils/inNode';
import ErrorBoundary from '../../common/components/ErrorBoundary';
import layoutContext from '../../common/hocs/layoutContext';
import connectToApps from '../components/connectToApps';

class BaseRouteHandler extends React.PureComponent {
  static contextTypes = {
    getModelData: PropTypes.func.isRequired,
    history: PropTypes.object,
    models: PropTypes.object,
    routeHandler: PropTypes.object.isRequired,
  };

  static childContextTypes = {
    isRtl: PropTypes.bool,
    playerApp: PropTypes.object,
    staticJsonApp: PropTypes.object,
    postPlayApp: PropTypes.object,
    gtmApp: PropTypes.object,
    postPlayDomain: PropTypes.object,
    profileManager: PropTypes.object,
    discoveryApp: PropTypes.object,
    discoveryStore: PropTypes.object,
    service: PropTypes.string,
    isStore: PropTypes.bool,
    isIframe: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.mainRef = React.createRef();
    this._handleTouchStart = this._handleTouchStart.bind(this);

    this.state = {
      profileGateAppState: {
        activeView: _.get(this.context.models, 'profileGateState.data', ''),
      },
      isAllowedToRender: true,
      isInitialRender: false,
      initializedCL: false,
      is4KSupported: false,
      isHDRSupported: false,
      inLowPowerMode: false,
    };
  }

  /**
   * サーバサイドで取得できない情報を設定
   */
  setBrowserInfo() {
    if (_.get(this.context.models, 'browserInfo.data')) {
      // iPhone iPad にて デスクトップ用Webサイトを表示 を利用した場合
      this.context.models.browserInfo.data.isRequestDesktopWebsite =
        navigator.userAgent.includes('Macintosh') && 'ontouchend' in document;
    }
  }

  getChildContext() {
    // const service = this.getService();
    return {
      isRtl: false,
      playerApp: this.props.playerApp,
      staticJsonApp: this.props.staticJsonApp,
      postPlayApp: this.props.postPlayApp,
      gtmApp: this.props.gtmApp,
      postPlayDomain: this.props.postPlayDomain,
      profileManager: this.profileManager,
      discoveryApp: this.props.discoveryApp,
      discoveryStore: this.props.discoveryStore,
    };
  }

  componentDidMount() {
    this.setBrowserInfo();
  }

  componentWillUnmount() {
    if (!inNode && window.PointerEvent) {
      var main = this.mainRef.current;
      if (main) {
        main.removeEventListener('pointerenter', this._handleTouchStart);
      }
    }
  }

  isSharedPage() {
    return false;
  }

  getCurrentUIView() {
    return '';
  }

  _handleTouchStart() {}

  render() {
    if (!this.state.isAllowedToRender) {
      return null;
    }

    return (
      <div ref={this.mainRef} lang="ja-JP" dir="ltr" onTouchStart={this._handleTouchStart}>
        <ErrorBoundary>
          {React.cloneElement(this.props.children, {
            isRefreshingApp: this.props.isRefreshingApp,
            isInitialRender: this.state.isInitialRender,
            is4KSupported: this.state.is4KSupported,
            isHDRSupported: this.state.isHDRSupported,
            model: this.props.model,
            profileGateAppState: this.state.profileGateAppState,
            uiView: this.getCurrentUIView(),
          })}
        </ErrorBoundary>
      </div>
    );
  }
}

let layout = connectToApps()(layoutContext(BaseRouteHandler));
if (process.env.BROWSER) {
  layout = routing()(layout);
}

export default layout;
