import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import dayjs from 'dayjs';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import { withModalContext } from '../../../common/context/ModalContext';
import getBroadcasterData from '../../../../common/getBroadcasterData';

class LiveCard extends Component {
  static propTypes = {
    routeHandler: PropTypes.object
  };

  static defaultProps = {
  };

  static contextTypes = {
    models: PropTypes.object,
    spMode: PropTypes.bool,
    articleDetail: PropTypes.bool,
  };


  constructor(props, context) {
    super(props, context);
    this.state = {
    }
    this.item = _.get(props.item, '0');
    this.item1 = _.get(props.item, '1');
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (_.isEmpty(this.item)) return;
    const isEachCompany = _.get(this.props, 'routeHandler.isEachCompany', false);
    const companyCode = _.get(this.props, 'routeHandler.params.companyCode', false);
    let livePosterSrc;
    if (!isEachCompany) {
      livePosterSrc = _.get(this.item, 'poster_url', '/images/news24_live.jpg');
    } else {
      livePosterSrc = _.get(this.item, 'image_url') || `/images/thumbnails/${companyCode}-thumbnail.png`;
    }
    const href = _.get(this.item, 'link_url');
    let liveLogo;
    if (isEachCompany && companyCode) {
      liveLogo = _.get(this.item1, 'image_url', _.get(getBroadcasterData(companyCode), 'img_src.l'));
    } else {
      liveLogo = "/images/live-logo.svg";
    }

    if(this.context.spMode) livePosterSrc = livePosterSrc.replace('live_thumbnail_pc', 'live_thumbnail_sp');

    let parts=(
        <div className="live-label">
          {_.get(this.item, 'title', "24時間ライブ配信中")}
        </div>
    );
  
    return (
      <MainViewLink href={href} className="live-card-block" key="live-card">
        <div className="live-card">
          <p className="live-logo"><img src={liveLogo} alt="logo" width="155" /></p>
          {parts}
          <div className="live-img">
            <img width="300" height="200" src={livePosterSrc} alt="日テレNEWS24 24時間ライブ配信中"/>
            <img src={`/images/play_icon.svg`} className="live-playicon" alt=""/>
          </div>
          <div className="live-title">
            <img src={liveLogo} alt="logo" width="104" height="16" />
            <p className="title">{_.get(this.item, 'title')}</p>
          </div>
        </div>
      </MainViewLink>
    );
  }
}

export default withModalContext(LiveCard);
