import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withModalContext } from '../../../common/context/ModalContext';
import TitleCard from './TitleCard';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';

class KeyWord extends Component {
  static propTypes = {};

  static contextTypes = {
    routeHandler: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let items;
    const to = routes.tag;
    items = _.compact(
      _.map(_.get(this.props, 'keywords') || [], (title, index) => {
        if (!title) return;
        const params = { tagName: title };
        return (
          <li className="keyword-list" key={`keyword_item_${index}`}>
            <MainViewLink to={to} params={params}>
              <strong>{title}</strong>
            </MainViewLink>
          </li>
        );
      }),
    );

    return (
      <div className="keyword-contents">
        <ul className="keyword-block">
          {_.get(this.props, 'featureTag') && !this.context.routeHandler.listJson && (
            <li className="keyword-list" key={`keyword_item_feature-tag`}>
              <MainViewLink
                to={
                  _.get(this.context.routeHandler, 'params.companyCode')
                    ? routes.featureDetailEachCompany
                    : routes.featureDetailTrenta
                }
                params={{
                  tagName: _.get(this.props, 'featureTag'),
                  companyCode: _.get(this.context.routeHandler, 'params.companyCode'),
                }}
                className="featureTag"
              >
                特集：{_.get(this.props, 'featureTag')}
              </MainViewLink>
            </li>
          )}
          {items}
        </ul>
      </div>
    );
  }
}

export default withModalContext(KeyWord);
