import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import MainViewLink from './MainViewLink';
import routes from '../../common/routes';
import _ from 'lodash';
import getBroadcasterData from '../../../common/getBroadcasterData';

export default class Footer extends Component {
  static propTypes = {
    footerAd: PropTypes.bool,
    spMode: PropTypes.bool,
    eachCompanyFooter: PropTypes.object
  };

  static contextTypes = {
    getModelData: PropTypes.func,
    models: PropTypes.object,
    cookies: PropTypes.object,
    isStore: PropTypes.bool,
    service: PropTypes.string,
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.footerLink = _.get(this.props, 'footerLink');
    this.footerHtml = _.get(this.props, 'eachCompanyFooter.footer.html');
    this.isEachCompany = _.get(this.props.routeHandler, 'isEachCompany', _.get(this.props, 'isEachCompany'));
  }

  render() {
    let sideFooterLinks = [];
    let footerLinks = []
    const img_src = this.context.models.config.data.logoPath;

    sideFooterLinks = _.compact(
      _.map(this.footerLink, (itemData, index) =>{
        return (
          <span className="sideContent-footer-link" key={`sideFooterLink-${index}`}>
            <MainViewLink href={itemData.link_url}>
              {itemData.title}
            </MainViewLink>
          </span>
        )
      })
    )
    footerLinks = _.compact(
      _.map(this.footerLink, (itemData, index) =>{
        return (
          <MainViewLink href={itemData.link_url} className="text-link" key={`footerLink-${index}`}>
            {itemData.title}
          </MainViewLink>
        )
      })
    )
    const defaultEachCompanyFooter = (
      <footer className='footer publicity-area default-footer main-footer'>
        <MainViewLink to={routes.companyHome} params={{companyCode: _.get(this.props.routeHandler, 'params.companyCode', _.get(this.props, 'companyCode'))}}>
          <img
            className='footer-logo'
            src={getBroadcasterData(_.get(this.props.routeHandler, 'params.companyCode', _.get(this.props, 'companyCode'))).img_src.e_m}
            alt={getBroadcasterData(_.get(this.props.routeHandler, 'params.companyCode', _.get(this.props, 'companyCode'))).label}
          />
        </MainViewLink>
        <div className="footer-link">
          {footerLinks}
        </div>
        <div className="footer-text">
          <p>
            見出し、記事、写真、動画、図表などの無断転載を禁じます。
          </p>
        </div>
      </footer>
    )
    // フッターリンク
    if (this.props.trentaSideContent) {
      return (
        <footer>
          <div className={classnames('sideContent-footer', { 'publicity-area': this.props.footerAd })}>
            {this.props.onlyLink ? null : (
              <div
                className={classnames('sideContent-footer-sns', {
                  'spMenu-sns': this.props.spMenu,
                })}
              >
                <a href="https://x.com/news24ntv" target="_blank" rel="noopener noreferrer nofollow" className="sideContent-footer-sns-item twitter">
                  <img src="/images/x.png" alt="twitter" width="30" height="30" />
                </a>
                <a href="https://www.youtube.com/@ntv_news" target="_blank" rel="noopener noreferrer nofollow" className="sideContent-footer-sns-item youtube">
                  <img src="/images/svg/youtube.svg" alt="youtube" width="30" height="30" />
                </a>
                <a
                  href="https://www.tiktok.com/@ntv.news/"
                  target="_blank" rel="noopener noreferrer nofollow"
                  className="sideContent-footer-sns-item tiktok"
                >
                  <img src="/images/svg/tiktok.svg" alt="tiktok" width="30" height="30" />
                </a>
                <a
                  href="https://line.me/R/ti/p/@oa-ntvnews24"
                  target="_blank" rel="noopener noreferrer nofollow"
                  className="sideContent-footer-sns-item line"
                >
                  <img src="/images/svg/line.svg" alt="line" width="30" height="30" />
                </a>
                <a
                  href="https://www.facebook.com/ntvnews24"
                  target="_blank" rel="noopener noreferrer nofollow"
                  className="sideContent-footer-sns-item facebook"
                >
                  <img src="/images/svg/facebook.svg" alt="facebook" width="30" height="30" />
                </a>
                <a
                  href="https://www.instagram.com/ntv_news24/"
                  target="_blank" rel="noopener noreferrer nofollow"
                  className="sideContent-footer-sns-item instagram"
                >
                  <img src="/images/svg/instagram.svg" alt="instagram" width="30" height="30" />
                </a>
              </div>
            )}
            {this.props.onlyIcon ? null : (
              <>
                <div
                  className={classnames('sideContent-footer-links', {
                    'spMenu-footer-links': this.props.spMenu,
                  })}
                >
                  {sideFooterLinks}
                </div>
                <div className="sideContent-footer-text">
                  <p
                    className={classnames('sideContent-footer-note', {
                      'spMenu-sideContent-footer-text': this.props.spMenu,
                    })}
                  >
                    見出し、記事、写真、動画、図表などの無断転載を禁じます。
                  </p>
                  <p className="sideContent-footer-copyright">
                    © Nippon Television Network Corporation
                    <br />
                    映像協力 NNN(Nippon News Network)
                  </p>
                </div>
              </>
            )}
          </div>
        </footer>
      );
    }
    // 各社UIの場合は各社UIのjsonの内容を描画する
    if (this.isEachCompany) {
      if (this.props.sideFooterContent) {
        return (
          _.isEmpty(this.footerHtml) ? defaultEachCompanyFooter : <div className='side-footer-content' dangerouslySetInnerHTML={{__html: this.footerHtml}} />
        )
      } else {
        return (
          _.isEmpty(this.footerHtml) ? defaultEachCompanyFooter : <div className='main-footer' dangerouslySetInnerHTML={{__html: this.footerHtml}} />
        )
      }
    }
    return (
      <footer className='main-footer'>
        <div className={classnames('footer', { 'publicity-area': this.props.footerAd })}>
          <div className="footer-logo">
            <MainViewLink to={routes.home}>
              <img src={img_src} alt="日テレNEWS NNN" />
            </MainViewLink>
          </div>
          <div className="footer-link">
            {footerLinks}
          </div>
          <div className="footer-sns">
            <a href="https://x.com/news24ntv" target="_blank" rel="noopener noreferrer nofollow" className="twitter">
              <img src="/images/x.png" alt="twitter" width="30" height="30" />
            </a>
            <a href="https://www.youtube.com/@ntv_news" target="_blank" rel="noopener noreferrer nofollow" className="youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="30" height="30" />
            </a>
            <a
              href="https://www.tiktok.com/@ntv.news/"
              target="_blank" rel="noopener noreferrer nofollow"
              className="tiktok"
            >
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="30" height="30" />
            </a>
            <a
              href="https://line.me/R/ti/p/@oa-ntvnews24"
              target="_blank" rel="noopener noreferrer nofollow"
              className="line"
            >
              <img src="/images/svg/line.svg" alt="line" width="30" height="30" />
            </a>
            <a
              href="https://www.facebook.com/ntvnews24"
              target="_blank" rel="noopener noreferrer nofollow"
              className="facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="30" height="30" />
            </a>
            <a
              href="https://www.instagram.com/ntv_news24/"
              target="_blank" rel="noopener noreferrer nofollow"
              className="instagram"
            >
              <img src="/images/svg/instagram.svg" alt="instagram" width="30" height="30" />
            </a>
          </div>

          <div className="footer-text">
            <p>見出し、記事、写真、動画、図表などの無断転載を禁じます。</p>
            <p className="copyright">
              © Nippon Television Network Corporation
              <br />
              映像協力 NNN(Nippon News Network)
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
