'use strict';

export default function lineClamp(str, cnt = 68) {
  if (!str || !str.length) return;
  if (str.length > cnt) {
    str = str.slice(0, cnt);
    str += '...';
  }
  return str;
};
