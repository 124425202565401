import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class ModalContent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.string,
  };
  constructor(props, context) {
    super(props, context);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.onScreenClick = this.onScreenClick.bind(this);
    this.screenRef = React.createRef();
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    disableBodyScroll(this.screenRef.current);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  closeOverlay(e) {
    if (this.props.onClose) {
      this.props.onClose(e);
    }
    enableBodyScroll(this.screenRef.current);
  }

  onScreenClick(e) {
    const rect = this.contentRef.current.getBoundingClientRect();
    if (rect.left > e.clientX || rect.top > e.clientY || rect.right < e.clientX || rect.bottom < e.clientY) {
      this.closeOverlay(e);
    }
  }

  render() {
    return (
      <div className="modalContent" ref={this.screenRef} onClick={this.onScreenClick}>
        <div className={classnames('modalTable', this.props.classes)} ref={this.contentRef}>
          {this.props.closeBtn ? <button className="modal-content-close" tabIndex="0" role="button" onClick={this.closeOverlay} /> : null}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ModalContent;
