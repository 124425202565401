import _get from 'lodash/get';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import _isEmpty from 'lodash/isEmpty';
import _concat from 'lodash/concat';
import _filter from 'lodash/filter';
import _size from 'lodash/size'
import url from 'url';
import getImage from './getImage';
import getArticleImage from './getArticleImage';
import { MAX_WIDTH_IMAGE } from '../constants/config';

const smallerSizes = { pc: '350x150', sp: '200x100' };
const largerSizes = { pc: '700x400', sp: '400x200' };
const featureTopSize = { pc: '300x150', sp: '300x150' };
const featureMainImageSize = { pc: '630x422', sp: null };

const getDeviceType = spMode => (spMode ? 'sp' : 'pc');
const resizeTitleCard = (props, spMode) => {
  if (!props) return null;
  const type = getDeviceType(spMode);
  return props.maincard || props.specialMain ? largerSizes[type] : smallerSizes[type];
};
export const urlSizeFormat = (image, size) => {
  const urlParse = url.parse(image, true);
  if (!_isEmpty(_get(urlParse, 'query.size'))) {
    urlParse.query.size = size;
  } else if (size && typeof size === 'number') {
    urlParse.query.w = size; // width（数値）のみの場合
  } else {
    const [w] = size.split('x');
    urlParse.query.w = w;
  }
  delete urlParse.search;
  return url.format(urlParse);
};
const resizeFeatureTopImage = (image, spMode) => {
  if (!image) return null;
  const type = getDeviceType(spMode);
  const size = featureTopSize[type];
  return urlSizeFormat(image, size);
}

export const resizeLargeImages = (image, spMode) => {
  const type = getDeviceType(spMode);
  const size = largerSizes[type];
  return urlSizeFormat(image, size);
};
// TitleCard 画像リサイズURL取得処理
export const getCardResizeImage = (item, context) => {
  const image = getImage(item, context);
  const size = resizeTitleCard(item, _get(context, 'spMode'));
  return isResizable(image) ? urlSizeFormat(image, size) : image;
};

// Article 画像リサイズURL取得処理
export const getArticleResizeImage = (article) => {
  const image = getArticleImage(article);
  if (!image) return;
  // デフォルトサムネの場合はリサイズクエリを付与しない
  const size = MAX_WIDTH_IMAGE;
  return urlSizeFormat(image.url, size);
};

// 記事画像サイズ指定取得処理
export const getAritcleTargetSizedImage = (itemData, context, size) => {
  const image = getImage(itemData, context);
  // デフォルトサムネの場合はリサイズクエリを付与しない
  return isResizable(image) ? urlSizeFormat(image, size) : image;
};

// Thumbnail 画像リサイズURL取得処理
export const getThumbResizeImage = (item, context) => {
  const image = getImage(item, context);
  const size = smallerSizes[getDeviceType(_get(context, 'spMode'))];
  // デフォルトサムネの場合はリサイズクエリを付与しない
  return isResizable(image) ? urlSizeFormat(image, size) : image;
};

// 画像配列をlink rel="preload"に対応する形に整形
export const getPreloadLinkTags = images => {
  const links = Array.from(new Set(images)); // 重複除去
  return _compact(
    _map(links, link => {
      return {
        rel: 'preload',
        href: link,
        as: 'image',
      };
    }),
  );
};

// 特集一覧・特集棚出しの画像のURLの配列を作成
export const featurePreloadImages = (items, context) => {
  if (!items || !_size(items)) return null;
  const images = [];
  _compact(_map(items, (itemData) => {
    // 特集のサムネイルを取得
    const featureThumbnail = resizeFeatureTopImage(_get(itemData, "images.0.url"), context);
    !_isEmpty(featureThumbnail) ? images.push(featureThumbnail) : images.push(getImage(itemData, context));
    // 特集に紐づく記事のサムネイルを取得
    _map(itemData.article, (article, i) => {
      if (context.spMode && i > 1) return;
      const thumbnail = getCardResizeImage({ itemData: article }, context);
      images.push(thumbnail);
    })
  }))
  return images;
}

// 特集記事一覧メイン画像のURLにクエリを付与
export const featureMainImage = (image, context) => {
  if (!image) return null;
  const type = getDeviceType(_get(context, 'spMode'));
  const size = featureMainImageSize[type];

  if (!size || !isResizable(image)) return image;
  return urlSizeFormat(image, size);
}

// 渡された画像URLにリサイズクエリを使用可能かの判定
export const isResizable = (url) => {
  if (_isEmpty(url)) return false;
  return url.includes('gimage') || url.includes('timage') || url.includes('image.gallery.play.jp');
};

export default {
  urlSizeFormat,
  resizeLargeImages,
  getCardResizeImage,
  getArticleResizeImage,
  getPreloadLinkTags,
  getAritcleTargetSizedImage,
  featurePreloadImages,
  featureMainImage,
  isResizable
};
