import React from 'react';
import PropTypes from 'prop-types';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

/**
 * LiteYoutubeEmbedモジュールのラッパー
 * 
 * SP版では特殊な対応が必要なため、一括設定できるようにラッパー化した
 */
class LiteYoutubeEmbedWrapper extends React.Component {
  static contextTypes = {
    spMode: PropTypes.bool,
    getModelData: PropTypes.func,
  };

  static propTypes = {
    posterSize: PropTypes.string,
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    params: PropTypes.string
  }

  static defaultProps = {
    posterSize: 'hqdefault'
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this._isMount = true;
  }

  componentDidUpdate() {
    console.log("LiteYoutubeEmbedWrapper:componentDidUpdate")
    const browserInfo = this.context.getModelData('browserInfo');
    /**
     * Youtube Facade対応のうちSPはLiteEmbed -> Iframe時に自動再生ができない仕様
     * Youtube公式リファレンス: https://developers.google.com/youtube/iframe_api_reference?hl=ja#Mobile_considerations
     * SP時はページ読み込み時にプレイヤーを一度クリックした判定をしておき、Youtube Embedをはじめから読んでおく
     */
    // if (true) {
    //   const buttons = document.querySelectorAll(".lty-playbtn");
    //   if (!buttons || buttons.length === 0) return;
    //   function createObserver() {
    //     let observer;

    //     let options = {
    //       rootMargin: "-50%",
    //       threshold: 1,
    //     };

    //     _.forEach(buttons, (button) => {
    //       observer = new IntersectionObserver(() => button.click(), options);
    //       observer.observe(button);
    //     });
    //   }
    //   return createObserver();
    // }
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  getPosterSize = () => {
    if (this.props.posterSize) {
      return this.props.posterSize;
    } else {
      return this.context.spMode ? "hqdefault" : "maxresdefault";
    }
  }

  render() {
    return (
      <LiteYouTubeEmbed
        title={this.props.title}
        id={this.props.id}
        poster={this.getPosterSize()}
        params={this.props.params}
      />
    );
  }
}

export default LiteYoutubeEmbedWrapper;