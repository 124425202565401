import _ from 'lodash';
import getArticleImage from './getArticleImage';
import { getGenres2Code, getArticle2ProviderCode } from './getGenreCategories';

export default function getImage(props, context) {
  const companyCode = _.get(context, 'routeHandler.params.companyCode', false);

  if (!props || !context) {
    return null;
  }
  const providerCode = getArticle2ProviderCode(_.get(props, 'itemData'));
  const genres = _.get(props, 'itemData.genres') || _.get(props, 'itemData.article.genres');
  const project = _.get(props, 'itemData.source_systems.project') || _.get(props, 'itemData.article.source_systems.project') || ''; //出稿元の局を確認
  const categoryCode = getGenres2Code(genres, _.get(context, 'models.config.data'), providerCode);
  const projectIdList = _.get(context, 'models.config.data.projectIdList', {}); // projectIdと局idのマッピングリストを取得

  let layoutMode = 'pc';
  const imageFormat = 'jpg';
  if (_.get(context, 'spMode')) {
    layoutMode = 'sp';
  }
  let defaultImg = `/images/news-thumbnail.${imageFormat}`;
  if (_.get(context, 'routeHandler.isEachCompany') && project !== 'n24') {
    // 各局UIの場合、出稿元の各社デフォルトサムネイルを適用('n24'のみデフォルト画像パス構成が異なるため除外)
    // projectが取得出来ない場合、companyCodeを指定する：記事以外のコンポーネントへの対応
    let projectKey = _.findKey(projectIdList, (value) => {
      return value === project;
    });
    projectKey = projectKey ?? companyCode;
    defaultImg = `/images/thumbnails/${projectKey}-thumbnail.png`; 
  } else {
    // TRENTA UI(総合)、TRENTA UI(各局)の場合、NTVのデフォルトサムネイルを適用
    // 各局UIだが出稿元がn24(NTV)の場合も適用
    defaultImg = (categoryCode && categoryCode !== 'life')
      ? `/images/${categoryCode}-thumbnail.${imageFormat}` // genresを持っており、カテゴリがlife以外の記事: NTVのカテゴリ別サムネ
      : `/images/news-thumbnail.${imageFormat}`;  // お知らせ、もしくは、カテゴリがlifeの記事: NTVの汎用サムネ
  }

  defaultImg = _.get(context, 'models.hosts.data.ssr_host') + defaultImg;

  if (props.introduction) {
    let url = _.get(props, 'itemData.image_url');
    return url || defaultImg;
  }
  if (props.category) {
    const image = getArticleImage(_.get(props, 'itemData.article'));
    let url = _.get(image, 'url');
    return url || defaultImg;
  }
  if (props.local || props.information) {
    let url = _.get(props, 'itemData.thumbnail');
    return url || defaultImg;
  }
  if (props.special || props.specialMain) {
    let url = _.get(props, 'itemData.images.0.url');
    if (!url) {
      url = _.get(props, 'itemData.thumbnails.0.url');
    }
    if (!url) {
      url = _.get(props, 'itemData.filteredThumbnails.0.url');
    }
    return url || defaultImg;
  }
  const image = getArticleImage(_.get(props, 'itemData'));
  let url = _.get(image, 'url');
  return url || defaultImg;
};
