import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AppContextProvider extends Component {
  static propTypes = {
    appContext: PropTypes.object.isRequired,
    children: PropTypes.object,
  };

  static childContextTypes = {
    ab: PropTypes.object,
    cookies: PropTypes.object,
    falcorModel: PropTypes.object,
    getModelData: PropTypes.func,
    getCookieDough: PropTypes.func,
    shiftFlashMessage: PropTypes.func,
    history: PropTypes.object,
    logger: PropTypes.object,
    models: PropTypes.object,
    getICUString: PropTypes.func,
    provideAppContextToElement: PropTypes.func,
    routeHandler: PropTypes.object,
    renderTracker: PropTypes.object,
    discoveryApp: PropTypes.object,
  };

  getChildContext() {
    const context = this.props.appContext;
    return {
      ab: context.getAB(),
      cookies: context.getCookieDough(),
      falcorModel: context.getFalcorModel(),
      getModelData: context.getModelData.bind(context),
      getCookieDough: context.getCookieDough.bind(context),
      shiftFlashMessage: context.shiftFlashMessage.bind(context),
      history: context.getHistory(),
      logger: context.getLogger(),
      models: context.getModels(),
      getICUString: context.getICUString.bind(context),
      provideAppContextToElement: context.provideAppContextToElement.bind(context),
      routeHandler: context.getRouteHandler(),
      renderTracker: context.getRenderTracker(),
      discoveryApp: context.getDiscoveryApp(),
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return typeof nextProps.appContext.getState === 'function';
  }

  render() {
    if (!this.props.children) return null;

    return React.cloneElement(this.props.children, this.props.appContext.getState());
  }
}

AppContextProvider.displayName = function () {
  return 'AppContextProvider';
};

export default AppContextProvider;
