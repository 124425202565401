import React from "react";
import PropTypes from 'prop-types';
import TaboolaWidget from "../../../common/components/TaboolaWidget";
import _get from 'lodash/get';

// トップページのTaboolaをコンポーネントに切り出し
class TaboolaHomeContent extends React.Component {
  static propTypes = {
    isSpMode: PropTypes.bool,
    uiView: PropTypes.string,
  }

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const isSpMode = _get(this.props, 'spMode', false);
    const isEachCompanyHome = _get(this.props, 'uiView') === 'companyHome';

    return (
      <React.Fragment>
        {isSpMode ? (
          <div key={`home-ad-below1_${Date.now()}`}>
            <TaboolaWidget target="below1" />
          </div>
        ) : isEachCompanyHome ? (
          <div className='headline-recommend' key={`each-company-ad-taboola-${Date.now()}`}>
            <TaboolaWidget target="below1" />
            <TaboolaWidget target="below2" />
          </div>
        ) : (
          <div className='headline-recommend' key={`trenta-ad-taboola-${Date.now()}`}>
            <TaboolaWidget target="below1" />
            <TaboolaWidget target="below2" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TaboolaHomeContent;
