import _has from 'lodash/has';
import _find from 'lodash/find';
import _assignIn from 'lodash/assignIn';
import _omit from 'lodash/omit';
import _extend from 'lodash/extend';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/**
 * MainViewLink
 * リンク遷移を提供します。
 * 遷移はデフォルトでSSRです
 * @param to routeオブジェクトを渡すと内部でhrefに変換します
 * @param href 生のhrefを渡します
 * @param ssr SSRでの遷移をするかのフラグです。デフォルトtrue
 */
class MainViewLink extends PureComponent {
  static contextTypes = {
    isKidsPage: PropTypes.bool,
    models: PropTypes.object.isRequired,
    routeHandler: PropTypes.object.isRequired,
    routeHandlers: PropTypes.array,
    service: PropTypes.string,
  };

  static defaultProps = {
    ssr: true, // NTVではデフォルトでSSRモードとする 
  }

  constructor(props, context) {
    super(props, context);

    this.preventClick = false;
  }

  handleClick(e) {
    if (this.preventClick) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.preventClick = true;
    setTimeout(() => {
      this.preventClick = false;
    }, 483);

    // 同じpathならキャンセルする
    if (this._href == this.context.routeHandler.path) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    if (typeof this.props.onClick === 'function') {
      this.props.onClick(e, this.props);
    }
  }

  render() {

    let to;
    if (this.props.resolver) {
      to = this.props.resolver(this.context);
    } else {
      to = this.props.to;
    }

    try {
      const routeHandlers = this.context.routeHandlers;
      let href;
      let routesMatchFlag;

      // routeオブジェクトが渡された場合
      if (to) {
        let params = this.props.params || {};
        let query = this.props.query;
        if (!_has(params, 'service') && this.context.service) params.service = this.context.service;
        href = to.makePath(params, query);
        routesMatchFlag =
          routeHandlers &&
          _find(routeHandlers, function (routeHandler) {
            return to._regex === routeHandler.route._regex;
          });

        // hrefが渡された場合
      } else if (this.props.href) {
        href = this.props.href;

        routesMatchFlag =
          routeHandlers &&
          _find(routeHandlers, function (routeHandler) {
            return !!routeHandler.route.match(href);
          });
      }

      // 生成したpathを保持
      this._href = href;

      // SPAでの遷移
      if (!this.props.ssr && routesMatchFlag) {
        return React.createElement(
          Link,
          _assignIn(
            _omit(this.props, [
              'resolver',
              'to',
              'params',
              'query',
              'ssr',
              'startTime',
              'episode',
              'responsiveButton',
              'replaceHistory',
              'model',
              'notPrimaryButton',
              'flatButton',
              'videoId',
              'episodeId',
              'ariaLabel',
              'sliderItemId',
            ]),
            { href, onClick: this.handleClick.bind(this) },
          ),
          this.props.children,
        );

        // 通常のaタグ
      } else if (href) {
        return React.createElement(
          'a',
          _extend(_omit(this.props, [
            'resolver', 
            'to', 
            'params',
            'query', 
            'ssr', 
            'onAfterNavigation',
            'sliderItemId', 
            'itemTabbable', 
            'featured',
          ]),
          { href }),
          this.props.children,
        );
      }
    } catch (e) {
      const URL = this.context.routeHandler ? this.context.routeHandler.path : '';
      e.message += ' with class ' + (this.props.className || '') + '. Current URL: ' + URL;
      throw e;
    }

    return React.createElement(
      'span',
      _omit(this.props, ['resolver', 'to', 'params', 'query', 'ssr', 'onAfterNavigation']),
      this.props.children,
    );
  }
}

export default MainViewLink;
