import CookieDough from 'cookie-dough';
import _ from 'lodash';
import React from 'react';
import generateUuid from '../../common/generateUuid';
import match from '../ui/routing/match';
import resolveElement from '../ui/routing/resolveElement';
import AppContextProvider from './AppContextProvider';

import store from '../../common/redux/store';
import { Provider } from 'react-redux';

class AppContext {
  constructor(app, state, options) {
    this.app = app;
    this.state = state || {};
    this.options = options || {};
    this.ab = this.getModelData('ab') || {};
    this.cookieDough = new CookieDough(_.get(this.state, ['model', 'node', 'req']));
    this.uuid = generateUuid();
    this.isTokenSetup = false;
  }

  async resolveState(pathname, handleRouteChange) {
    const routeHandler = match(this.state.routeHandlers, pathname);
    if (routeHandler !== this.state.routeHandler) {
      var state = Object.assign({}, this.state, { routeHandler });
      if (this.app.handleRouteChange) {
        state = this.app.handleRouteChange(state, routeHandler);
      }
      if (this.options.handleRouteChange) {
        state = this.options.handleRouteChange(state, routeHandler);
      }
      if (handleRouteChange) {
        state = handleRouteChange(state, routeHandler);
      }
      this.state = state;
    }

    if (typeof window !== 'undefined') {
      let tokenData = this.getTokenData();
      if (!this.isTokenSetup) {
        this.isTokenSetup = true;
        if (tokenData) {
          tokenData.uuid = this.uuid;
          tokenData.isRefresh = false;
        } else {
          tokenData = {
            uuid: this.uuid,
            isRefresh: false,
          };
        }

        window.addEventListener('focus', (e) => {
          let tokenData = this.getTokenData();
          if (tokenData) {
            tokenData.uuid = this.uuid;
            this.setTokenData(tokenData);
          }
        });
      }
    }

    return this.state;
  }

  setTokenData(tokenData) {
  }

  getTokenData() {
  }

  async resolveElement(cb) {
    const routeHandler = this.state.routeHandler;
    if (!routeHandler) {
      return cb(null, null);
    }
    if (routeHandler.resolveComponents) {
      routeHandler.resolveComponents(routeHandler, this, (err, components) => {
        if (err) {
          return cb(err);
        }
        let elm = resolveElement(components, this.state);
        if (!React.isValidElement(elm)) {
          return cb(new Error('Route handler does not have a valid React component'));
        }
        return cb(null, elm);
      });
    } else {
      let components = routeHandler.components;
      let elm = resolveElement(components, this.state);

      if (!React.isValidElement(elm)) {
        return cb(new Error('Route handler does not have a valid React component'));
      }
      return cb(null, elm);
    }
  }

  getFalcorModel() {
    return _.get(this.state, ['model', 'pathEvaluator']);
  }

  getState() {
    return this.state;
  }

  getRouteHandler() {
    return this.state.routeHandler;
  }

  getHistory() {
    return this.state.history;
  }

  getModels() {
    return this.state.model.models;
  }

  getModelData(key) {
    let models = _.get(this.state, ['model', 'models', key, 'data']);

    if (arguments.length > 1) {
      let i;
      for (i = 1; i < arguments.length; i++) {
        models = models[arguments[i]];
      }
    }
    return models;
  }

  shiftFlashMessage() {
    return _.get(this.state, ['model', 'models', 'flashMessages', 'data']);
  }

  getICUString() {
    // TODO:
  }

  provideAppContextToElement(children) {
    return React.createElement(
      Provider,
      { store: store },
      React.createElement(AppContextProvider, { appContext: this }, children)
    );
  }

  getRenderTracker() {
    return this.state.renderTracker;
  }

  getLogger() {
    // TODO:
  }

  getDiscoveryApp() {
    // TODO:
  }

  getAB() {
    return this.ab;
  }

  getCookieDough() {
    return this.cookieDough;
  }

}

export default AppContext;
