import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import MainViewLink from './MainViewLink';
import getBroadcasterData from '../../../common/getBroadcasterData';
import routes from '../routes';
import categoryCode2Name from '../../../common/categoryCode2Name';

class HeaderBreadcrumb extends React.Component {
  static contextTypes = {
    models: PropTypes.object,
    routeHandler: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.isEachCompany = _get(context, 'routeHandler.isEachCompany');
    this.broadCastData = getBroadcasterData(_get(context, 'routeHandler.params.companyCode', 'n24'));
    this.categoryName = categoryCode2Name(_get(context, 'routeHandler.params.categoryCode'), _get(context, 'models.config.data'));
    this.articleData = _get(context, 'models.articleData', {})
  }

  render() {
    const { title } = this.articleData
    if (_get(this.context, 'routeHandler.params.categoryCode') === "sponsored") return null;

    return this.isEachCompany ? (
      <div className="main-header local-breadcrumb-wrapper">
        <ul className="header-breadcrumb">
          <li>
            <MainViewLink to={routes.companyHome} params={{ companyCode: this.broadCastData.title }}>
              {this.broadCastData.nnn}
            </MainViewLink>
          </li>
          <li>
            <span>{title}</span>
          </li>
        </ul>
      </div>
    ) : (
      <div className='main-header local-breadcrumb-wrapper'>
        <ul className="header-breadcrumb">
          <li>
            <MainViewLink to={routes.home}>{this.broadCastData.nnn}</MainViewLink>
          </li>
          <li>
            <MainViewLink
              to={routes.newTrenta}
              params={{ categoryCode: this.context.routeHandler.params.categoryCode }}
            >
              {this.categoryName}
            </MainViewLink>
          </li>
          <li>
              <span>{title}</span>
          </li>
        </ul>
      </div>
    );
  }
}

export default HeaderBreadcrumb;
