import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _concat from 'lodash/concat';
import _replace from 'lodash/replace';
import classnames from 'classnames';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Facebook } from 'reactjs-social-embed';
import LiteYoutubeEmbedWrapper from '../player/LiteYoutubeEmbedWrapper';

const YOUTUBE_REGEXP = /^.*(youtu.be\/|www.youtube.com\/watch\?v=)([^#&?]*)[?feature=shared]*([&?]+start=.+)?$/;
const FACEBOOK_REGEXP = /^.*(facebook.com\/(.*)\/(posts|videos)\/)([^#&?]*).*/;
const INSTAGRAM_REGEXP = /^.*(www.instagram.com\/p\/.*\/)([^#&?]*).*/;
const TIKTOK_REGEXP = /^.*(tiktok.com\/.*\/video\/)([^#&?]*).*/;
const TWITTER_REGEXP = /^.*([twitter.com|x.com]\/.*\/status\/)([^#&?]*).*/;

/**
 * 記事本文中のエンベッド生成コンポーネント
 * @prop {string} url エンベッドのURL
 */
class ArticleEmbed extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    spMode: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  /**
   * Youtubeエンベッド
   */
  buildYoutubeEmbed = (match) => {
    const id = _get(match, '2');
    let start = _get(match, '3');
    if (start) {
      start = start = _replace(start, /[&?]/, '');
    }
    const params = start; // パラメータが追加された場合はここに文字列連結で連結する
    return (
      <div className="youtube-player">
        <LiteYoutubeEmbedWrapper
          id={id}
          params={_isEmpty(params) ? undefined : params}
        />
      </div>
    );
  }

  /**
   * Facebookエンベッド
   */
  buildFacebookEmbed = (match, url) => {
    const embedType = _get(match, '3');
    const type = embedType === 'posts' ? 'post' : embedType === 'videos' ? 'video' : '';

    return (
      <div
        className={classnames(
          { 'fbPostFrameWrapper': type === 'post' },
          { 'fbVideoFrameWrapper': type === 'video' }
        )}
      >
        <Facebook
          type={type}
          width="100%"
          height="700px"
          show_text={true}
          url={url}
        />
      </div>
    );
  }

  /**
   * Instagramエンベッド
   */
  buildInstagramEmbed = (match) => {
    const url = _get(match, '1');
    return (
      <div className="instaIFrameWrapper">
        <iframe
          className="instaFrame"
          allowTransparency="true"
          frameBorder="0" // wip: CSSに置き換える
          src={`https://${url}embed/`}
        />
      </div>
    );
  }

  /**
   * TikTokエンベッド
   */
  buildTiktokEmbed = (match) => {
    const id = _get(match, '2');
    return (
      <div className="tiktokIFrameWrapper">
        <iframe
          className="tiktokFrame"
          frameBorder="0" // wip: CSSに置き換える
          src={`https://www.tiktok.com/embed/v2/${id}`}
        />
      </div>
    );
  }

  /**
   * X(Twitter)エンベッド
   */
  buildTwitterEmbed = (match) => {
    const id = _get(match, '2');
    return <TwitterTweetEmbed tweetId={id} />;
  }

  /**
   * エンベッドのURLからサービスを特定し、それぞれのDOMを返す
   */
  serviceEmbedGenerator = (url) => {
    if (url.match(YOUTUBE_REGEXP)) {
      return this.buildYoutubeEmbed(url.match(YOUTUBE_REGEXP));
    } else if (url.match(FACEBOOK_REGEXP)) {
      return this.buildFacebookEmbed(url.match(FACEBOOK_REGEXP), url);
    } else if (url.match(INSTAGRAM_REGEXP)) {
      return this.buildInstagramEmbed(url.match(INSTAGRAM_REGEXP));
    } else if (url.match(TIKTOK_REGEXP)) {
      return this.buildTiktokEmbed(url.match(TIKTOK_REGEXP));
    } else if (url.match(TWITTER_REGEXP)) {
      return this.buildTwitterEmbed(url.match(TWITTER_REGEXP));
    } else {
      return <div></div>;
    }
  }

  render() {
    const { url } = this.props;
    return this.serviceEmbedGenerator(url);
  }
}

export default ArticleEmbed;
