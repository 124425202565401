import _ from 'lodash';
import React, { Component } from 'react';
import { withModalContext } from '../../../common/context/ModalContext';
import BrightcovePlayer from '@brightcove/react-player-loader';
import LivePlayer from '../player/LivePlayer';
import StreaksPlayer from "../../../common/components/player/StreaksPlayer";
import PropTypes from "prop-types";
import Axios from '../../../../common/Axios';
import url from 'url';


class Player extends Component {
  static propTypes = {
    isEachCompany: PropTypes.bool,
    fetchData: PropTypes.func,
    catalogItem: PropTypes.object,
    handleLiveError: PropTypes.func,
  };

  static contextTypes = {
    models: PropTypes.object,
    spMode: PropTypes.bool,
    routeHandler: PropTypes.object,
    getModelData: PropTypes.func,
  };

  static defaultProps = {
    isEachCompany: false
  }

  constructor(props, context) {
    super(props, context);

    this.axios = new Axios({ xhr: true });
    this.handlePlayerReady = this.handlePlayerReady.bind(this);
    this.getPtaToken = this.getPtaToken.bind(this);
    this.getLiveStreamM3U8 = this.getLiveStreamM3U8.bind(this);

    this.state = {
      isStop: false,
      isInitialPlay: true,
      isMultipleFiring: false,
    };
  }

  /**
   * JOCDNのPTAトークンを取得
   */
  async getPtaToken() {
    // API URL生成
    const trentaService = this.context.getModelData('services', 'trentaService');
    trentaService.pathname = _.get(trentaService, 'path', '').join('/');
    const requestUrl = url.format(trentaService);

    return await this.axios.get(requestUrl).then((result) => {
      const token = _.get(result.data, 'pta', '');
      return token;
    }).catch((e) => {
      console.log(e.message);
    });
  }

  /**
   * ライブ用m3u8URLの取得
   * マニフェストは日テレのもののみで良い
   */
  async getLiveStreamM3U8() {

    // URL認証を行う
    // 認証に失敗した場合は視聴させない
    try {
      const targetFile = '/ch01/index.m3u8';
      const token = await this.getPtaToken();

      // トークンが空の場合は空オブジェクトを返す。
      if (!token) {
        const message = 'トークンが取得できませんでした。';
        console.error(message);
        return {
          errorMessage: message
        };
      }

      return {
        primarySource: `https://n24-cdn-live-x.ntv.co.jp${targetFile}` + `?pta=${token}`,
        secondarySource: `https://n24-cdn-live-y.ntv.co.jp${targetFile}` + `?pta=${token}`,
      }
    } catch (e) {
      // エラーしたら見せない
      const message = 'ライブ取得時に何らかのエラーが発生しました。';
      console.error(message);
      return {
        errorMessage: message
      };
    }
  }

  /**
   * ライブのm3u8を取得する
   * NTVライブの場合はJOCDNのURL認証を行う
   */
  async handlePlayerReady(player, props, isLive = false) {
    const companyCode = _.get(this.context, 'routeHandler.params.companyCode', 'ntv');
    const referrerUrl = encodeURIComponent(`${_.get(this.context, 'models.hosts.data.domain_url')}${window.location.pathname}`);
    const isAbledVideoAd = !_.get(props, 'article.custom_data.not_advert_flag') && !_.get(props,'article.custom_data.not_vast_flag');
    if (!isLive) {
      // console.log("通常ビデオ", props)
      let data = _.get(props, 'item');
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      const categoryCode = _.get(props, 'article.categoryCode');
      let rejectAd = true;
      let impRate = 100;
      if (categoryCode) {
        impRate = _.get(props, `article.impRate.${categoryCode}`, 100);
      }
      if (impRate > 0) {
        const min = 1;
        const max = 100;
        const rate = Math.floor(Math.random() * (max + 1 - min)) + min;
        if (impRate === 100 || impRate >= rate) {
          rejectAd = false;
        }
      } else {
        rejectAd = true;
      }
      if (isAbledVideoAd && !rejectAd) {
        const ads = _.compact(_.map(_.get(this.context, 'models.config.data.instreamAD.article', []), ad => {
          if (ad.src) {
            return ad;
          }
        }));
        if (ads && ads.length) {
          const newsVideoDurDef = _.get(props, 'article.newsVideoDurDef');
          let newsVideoDur;
          if (newsVideoDurDef && _.get(props, 'item.duration')) {
            const newsVideoDurDefKeys = Object.keys(newsVideoDurDef);
            if (newsVideoDurDefKeys.length) {
              const firstNewsVideoDurDef = newsVideoDurDefKeys[0];
              newsVideoDur =
                newsVideoDurDef[
                  newsVideoDurDefKeys
                    .sort((x, y) => y - x)
                    .find(x => x <= props.item.duration && firstNewsVideoDurDef < props.item.duration)
                ];
            }
          }
          if (newsVideoDur) {
            data.ads = ads;
            const hashTag = _.get(props, 'article.hashTag', []);
            data.ad_fields = {
              referrer_url: referrerUrl,
              news_category: _.get(props, 'article.categoryTag', ''),
              news_tag: hashTag.join(','),
              news_video_dur: newsVideoDur,
              news_channel: companyCode,
            };
          }
        }
      }
      if (typeof player.loadMedia === 'function') {
        player.loadMedia(data);
      } else {
        player.loadVideo(data);
      }
    } else if (isLive && !this.props.isEachCompany) {
      // NTVのライブの場合
      const ads = _.compact(_.map(_.get(this.context, 'models.config.data.instreamAD.live', []), ad => {
        if (ad.src) {
          return ad;
        }
      }));
      if (ads && ads.length) {
        let livePosterSrc = _.get(this.props, 'livePosterSrc', '');
        if(this.context.spMode) livePosterSrc = livePosterSrc.replace('live_thumbnail_pc', 'live_thumbnail_sp');

        // m3u8の取得(ptaトークンを連結させた状態で取得)
        const { primarySource, secondarySource, errorMessage } = await this.getLiveStreamM3U8();

        // m3u8が取得できなければ視聴させない
        if (!primarySource || !secondarySource) {
          console.error("m3u8ファイルの取得に失敗しました。");
          if (this.props.handleLiveError) {
            const message = errorMessage || '認証に何らかのエラーが発生しました。';
            this.props.handleLiveError(message);
          }
          return null;
        }

        const data = {
          poster: {
            src: livePosterSrc,
          },
          thumbnail: {
            src: null,
          },
          sources: [
            { src: primarySource },
            { src: secondarySource },
          ],
        };
        if (typeof player.loadMedia === 'function') {
          player.loadMedia(data);
        } else {
          player.loadVideo(data);
        }
      }
    } else if (isLive && this.props.isEachCompany) {
      // 各社ライブ
      // console.log("各社ライブ設定", props, isLive);
      const livePosterSrc = _.get(props, 'item.image_url')

      const data = _.get(props, 'item.media', {});
      if (!data || _.isEmpty(data)) {
        console.error('media data is empty...');
        return null;
      }
      data.poster = {src: livePosterSrc};

      /**
       * catalog_idについてはGALLERYの設定＞カタログ設定からブラウザのDevツールを開きネットワークのレスポンスから確認ができる
       * wip: 動的対応
       */
      const companyCode = _.get(this.props, 'companyCode');
      const catalogId = companyCode + '-news';
      const contentId = _.get(this.props, 'catalogItem.content_id');
      const title = _.get(this.props, 'item.title');
      const loadMediaOptions = {
        metrics: {
          'content.catalog': catalogId,
          'content.id': contentId,
          'content.title': title,
          'content.type': 'live_event',
          'app.name': 'TRENTA',
          // tags: this.props.genres,
          'device.type': 'pc',
        },
      };
      if (typeof player.loadMedia === 'function') {
        player.loadMedia(data, loadMediaOptions);
      } else {
        player.loadVideo(data);
      }
    }

    const self = this;
    this.interval = '';

    player.on('loadeddata', (data) => {
      let params;
      if (isLive) {
        params = {
          movie_id: 'live',
          event: 'content_loaded',
          unix_time: Math.floor(player.currentTime()),
        };
      } else {
        params = {
          article_id: _.get(self, 'props.article.source_systems.id'),
          movie_id: _.get(self, 'props.item.id'),
          event: 'content_loaded',
          view_time: player.currentTime(),
          article_title: _.get(self, 'props.article.title'),
        };
      }
    });
    player.on('firstplay', (data) => {
      this.setState({ firstplay: true });
      const currentTime = Math.floor(player.currentTime());
      if (currentTime == 0) {
        self.interval = setInterval(() => {
          let params;
          if (isLive) {
            params = {
              movie_id: 'live',
              event: 'loop',
              unix_time: Math.floor(player.currentTime()),
            };
          } else {
            params = {
              article_id: _.get(self, 'props.article.source_systems.id'),
              movie_id: _.get(self, 'props.item.id'),
              event: 'loop',
              view_time: player.currentTime(),
              article_title: _.get(self, 'props.article.title'),
            };
          }
        }, 60000);
      }
    });
    player.on('play', (data) => {
      const currentTime = Math.floor(player.currentTime());
      let params;
      if (isLive) {
        params = {
          movie_id: 'live',
          event: 'start',
          unix_time: Math.floor(player.currentTime()),
        };
      } else {
        params = {
          article_id: _.get(self, 'props.article.source_systems.id'),
          movie_id: _.get(self, 'props.item.id'),
          event: 'start',
          view_time: player.currentTime(),
          article_title: _.get(self, 'props.article.title'),
        };
      }
      // 多重発火した場合（モバイル）
      if (this.state.isMultipleFiring) {
        this.setState({
          isMultipleFiring: false,
          isStop: false,
        });
        return;
      }
    });
    player.on('pause', (data) => {
      let params;
      if (isLive) {
        params = {
          movie_id: 'live',
          event: 'stop',
          unix_time: Math.floor(player.currentTime()),
        };
      } else {
        params = {
          article_id: _.get(self, 'props.article.source_systems.id'),
          movie_id: _.get(self, 'props.item.id'),
          event: 'stop',
          view_time: player.currentTime(),
          article_title: _.get(self, 'props.article.title'),
        };
      }
    });
    player.on('seeked', (data) => {
      if (isLive) return;
    });
    player.on('volumechange', (data) => {
      let params;
      if (isLive) {
        params = {
          movie_id: 'live',
          event: 'button_volume',
          unix_time: Math.floor(player.currentTime()),
        };
      } else {
        params = {
          article_id: _.get(self, 'props.article.source_systems.id'),
          movie_id: _.get(self, 'props.item.id'),
          event: 'button_volume',
          view_time: player.currentTime(),
          article_title: _.get(self, 'props.article.title'),
        };
      }
    });
    player.on('fullscreenchange', (data) => {
      let params;
      if (player.isFullscreen()) {
        if (isLive) {
          params = {
            movie_id: 'live',
            event: 'button_start_fullscreen',
            unix_time: Math.floor(player.currentTime()),
          };
        } else {
          params = {
            article_id: _.get(self, 'props.article.source_systems.id'),
            movie_id: _.get(self, 'props.item.id'),
            event: 'button_start_fullscreen',
            view_time: player.currentTime(),
            article_title: _.get(self, 'props.article.title'),
          };
        }
      } else {
        if (isLive) {
          params = {
            movie_id: 'live',
            event: 'button_stop_fullscreen',
            unix_time: Math.floor(player.currentTime()),
          };
        } else {
          params = {
            article_id: _.get(self, 'props.article.source_systems.id'),
            movie_id: _.get(self, 'props.item.id'),
            event: 'button_stop_fullscreen',
            view_time: player.currentTime(),
            article_title: _.get(self, 'props.article.title'),
          };
        }
      }
    });
    player.on('ended', (data) => {
      let params;
      if (!isLive) {
        params = {
          article_id: _.get(self, 'props.article.source_systems.id'),
          movie_id: _.get(self, 'props.item.id'),
          event: 'complete',
          view_time: player.currentTime(),
          article_title: _.get(self, 'props.article.title'),
        };
      }
      clearInterval(self.interval);
    });
    player.on('error', (data) => {
      let params;
      if (isLive) {
        params = {
          movie_id: 'live',
          event: 'error',
          unix_time: Math.floor(player.currentTime()),
        };
      } else {
        params = {
          article_id: _.get(self, 'props.article.source_systems.id'),
          movie_id: _.get(self, 'props.item.id'),
          event: 'error',
          view_time: player.currentTime(),
          article_title: _.get(self, 'props.article.title'),
        };
      }
    })
  }

  componentDidMount() {
    this._isMounted = true;

    // 地方局の場合は画面描画時に動画情報取得
    if (this.props.fetchData && this.props.isEachCompany) {
      this.props.fetchData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    // // ライブがない場合
    // if (_.isEmpty(_.get(this.props, 'item.media', {}))) {
    //   return <div>配信中のライブはありません</div>
    // }

    if (_.get(this.props, 'type') === 'brightcove') {
      const settingMap = {
        ytv: {
          videoCloudAccount: '4072219199001',
          videoCloudPlayer: 'rkYsP33C',
        },
        other: {
          videoCloudAccount: '3974243805001',
          videoCloudPlayer: 'Hy0FLbFn',
        },
      };

      const setting = _.get(this.props, 'data.provider_id') === 'ytv' ? settingMap['ytv'] : settingMap['other'];
      const videoId = _.get(this.props, 'data.provider_id') === 'ytv' ? 'ref:' + _.get(this.props, 'data.video') :
        'ref:' + _.get(this.props, 'data.provider_id') + '_' + _.get(this.props, 'data.video');

      return (
        <BrightcovePlayer
          accountId={setting.videoCloudAccount}
          playerId={setting.videoCloudPlayer}
          videoId={videoId}
          embedId="default"
          embedOptions={{ responsive: true }}
          attrs={{id:'player'}}
          onSuccess={succ => {
            // streaksplayerのcssとバッティングするため特別対応
            if (typeof window !== 'undefined') {
              const videoJsWrapper = document.getElementById('player');
              if (videoJsWrapper) {
                const videoJsElem = _.get(videoJsWrapper, 'children.0.children.0.children.0');
                if (videoJsElem && videoJsElem.classList) {
                  videoJsElem.classList.add('strp-ready');
                  videoJsElem.classList.add('strp-initialized');
                }
              }
            }
          }}
        />
      );
    } else if (_.get(this.props, 'type') === 'live') {
      return (
        <LivePlayer
          handlePlayerReady={this.handlePlayerReady}
          item={_.get(this.props, 'item')}
        />
      );
    } else if (_.get(this.props, 'type') === 'streaks') {
      return (
        <div className="player-item">
          <StreaksPlayer
            item={_.get(this.props, 'item')}
            article={_.get(this.props, 'article')}
            image={_.get(this.props, 'image')}
            handlePlayerReady={this.handlePlayerReady}
          />
        </div>

      );
    } else {
      return null;
    }
  }
}

export default withModalContext(Player);
