import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withModalContext } from '../../../common/context/ModalContext';

class SnsBlock extends Component {
  static propTypes = {
    wrapperPublicity: PropTypes.bool,
    zero: PropTypes.bool,
    bankisha: PropTypes.bool,
    shinsou: PropTypes.bool,
    social: PropTypes.bool,
    sorajiro: PropTypes.bool,
    every: PropTypes.bool,
    oha4: PropTypes.bool,
  };

  static contextTypes = {
    spMode: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="sns-icon-block">
        {this.props.nnn ? (
          <React.Fragment>
            <a
              href="https://x.com/news24ntv"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            <a href="https://www.youtube.com/@ntv_news" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a>
            <a href="https://www.tiktok.com/@ntv.news/" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a>
            <a href="https://line.me/R/ti/p/@oa-ntvnews24" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a>
            <a
              href="https://www.facebook.com/ntvnews24"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a>
            <a href="https://www.instagram.com/ntv_news24/" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a>
          </React.Fragment>
        ) : this.props.zero ? (
          <React.Fragment>
            <a
              href="https://x.com/ntvnewszero"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a> */}
            <a href="https://www.tiktok.com/@ntvnewszero/" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a>
            <a href="https://line.me/R/ti/p/@977bvuqu" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a>
            <a
              href="https://www.facebook.com/newszero"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a>
            <a href="https://www.instagram.com/ntvnewszero/" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a>
          </React.Fragment>
        ) : this.props.bankisha ? (
          <React.Fragment>
            <a
              href="https://x.com/bankisha"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            <a href="https://www.youtube.com/playlist?list=PLagjDvQ583URbsZT2mrls3hHyXbYRVtVA" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a> */}
          </React.Fragment>
        ) : this.props.shinsou ? (
          <React.Fragment>
            <a
              href="https://x.com/sinso_news"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            <a href="https://www.youtube.com/playlist?list=PLagjDvQ583USTBHMh3cMqdPWr7N49_9HM" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a> */}
            <a
              href="https://www.facebook.com/shinsounews/"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a> */}
          </React.Fragment>
        // ) : this.props.social ? (
        //   <React.Fragment>
        //     {/*<a href="https://www.facebook.com/theSOCIAL24/" rel="noopener noreferrer nofollow" target="_blank"  className="sns-icon facebook"><img src="/images/svg/facebook.svg" alt="facebook" width="1" height="1" /></a>*/}
        //     <a
        //       href="https://twitter.com/thesocial24"
        //       rel="noopener noreferrer nofollow"
        //       target="_blank"
        //       className="sns-icon twitter"
        //     >
        //       <img src="/images/x.png" alt="twitter" width="28" height="28" />
        //     </a>
        //     <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
        //       <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
        //     </a>
        //     <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
        //       <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
        //     </a>
        //     <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
        //       <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
        //     </a>
        //     <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon facebook">
        //       <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
        //     </a>
        //     <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
        //       <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
        //     </a>
        //   </React.Fragment>
        ) : this.props.sorajiro ? (
          <React.Fragment>
            <a
              href="https://x.com/ntvsorajiro"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a> */}
            <a
              href="https://www.facebook.com/sorajiro"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a>
            <a href="https://www.instagram.com/sorajiro_ntv/" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a>
          </React.Fragment>
        ) : this.props.every ? (
          <React.Fragment>
            <a
              href="https://x.com/ntvnewsevery"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a> */}
            <a
              href="https://www.tiktok.com/@ntv_newsevery"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon tiktok"
            >
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a> */}
            <a
              href="https://www.facebook.com/newsevery"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a>
            <a
              href="https://www.instagram.com/ntv_newsevery/"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon instagram"
            >
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a>
          </React.Fragment>
        ) : this.props.oha4 ? (
          <React.Fragment>
            <a
              href="https://x.com/oha4newslive"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a> */}
            {/* <a
              href="https://www.facebook.com/oha4newslive"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a> */}
          </React.Fragment>
        ) : this.props.seijibu ? (
          <React.Fragment>
            <a
              href="https://x.com/ntvseijibu"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a> */}
            {/* <a
              href="https://www.facebook.com/oha4newslive"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a> */}
          </React.Fragment>
        ) : this.props.sports ? (
          <React.Fragment>
            <a
              href="https://x.com/ntv_sports_jp"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            <a href="https://www.youtube.com/@ntvsports" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a> */}
            {/* <a
              href="https://www.facebook.com/oha4newslive"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a> */}
            <a href="https://www.instagram.com/ntv_sports_/" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a>
          </React.Fragment>
        ) : this.props.culture ? (
          <React.Fragment>
            <a
              href="https://x.com/ntvnewsculture"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            <a href="https://www.youtube.com/@ntvnews-culture" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a> */}
            {/* <a
              href="https://www.facebook.com/oha4newslive"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a> */}
            {/* <a href="https://www.instagram.com/ntv_sports_/" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a> */}
          </React.Fragment>
        ) : this.props.english ? (
          <React.Fragment>
            <a
              href="https://x.com/N24English"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon twitter"
            >
              <img src="/images/x.png" alt="twitter" width="28" height="28" />
            </a>
            <a href="https://www.youtube.com/@NipponTVNews24Japan" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon youtube">
              <img src="/images/svg/youtube.svg" alt="youtube" width="28" height="28" />
            </a>
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon tiktok">
              <img src="/images/svg/tiktok.svg" alt="tiktok" width="28" height="28" />
            </a> */}
            {/* <a href="#" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon line">
              <img src="/images/svg/line.svg" alt="line" width="28" height="28" />
            </a> */}
            <a
              href="https://www.facebook.com/NipponTVNews24Japan"
              rel="noopener noreferrer nofollow"
              target="_blank"
              className="sns-icon facebook"
            >
              <img src="/images/svg/facebook.svg" alt="facebook" width="28" height="28" />
            </a>
            {/* <a href="https://www.instagram.com/ntv_sports_/" rel="noopener noreferrer nofollow" target="_blank" className="sns-icon instagram">
              <img src="/images/svg/instagram.svg" alt="instagram" width="28" height="28" />
            </a> */}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default withModalContext(SnsBlock);
